import FlashCardBreadCrumb from "~/components/FlashCard/FlashCardBreadCrumb";
import FlashCardReviewContent from "~/components/FlashCard/FlashCardReviewContent";

function FlashCardReview() {
    return (
        <div className="bg-hotlink-purple-f7f3ff pb-[82px]">
            <div className="flex flex-col gap-[18px] pt-20 lg:mx-20 mx-5 font-poppinsRegular">
                <FlashCardBreadCrumb />
                <FlashCardReviewContent/>
            </div>
        </div>
    );
}

export default FlashCardReview;
