import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './SkillTest.scss';

import ShortTest from '../ShortTest';
import Play from '../../../assets/svg/Play.svg';
import { listTopicUser } from '~/api/topicApi';

function SkillTest() {
    const { t } = useTranslation('common');
    const controller = new AbortController();
    const [typeTopic, setTypeTopic] = useState('Listening');

    const [data, setData] = useState([
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
    ]);

    const listTopicType = async () => {
        try {
            const response = await listTopicUser(
                { size: '3', type: typeTopic },
                {
                    signal: controller.signal,
                },
            );

            if (response?.status === 'success') {
                const newData = response.responseData.listTopic.map((item, i) => ({
                    key: item.topicId,
                    title: item.topicName,
                    time: item.workTime,
                    quantity: item.numberQuestion,
                }));

                setData(newData);
            }
        } catch (error) {}
    };

    useEffect(() => {
        listTopicType();

        return () => {
            controller.abort();
        };
    }, [typeTopic]);

    return (
        <div
            className="bg-[#ffeef0] py-10 w-full flex flex-col text-center justify-center"
            data-aos="fade-left"
            data-aos-duration="1000"
        >
            <h1 className="lg:text-[40px] text-3xl font-semibold text-[#309]">{t('skill_test')}</h1>
            <span className="text-base lg:mb-10 mb-5">{t('skill_select')}</span>
            <div className="flex justify-center">
                <button
                    className={`lg:px-20 lg:py-10 px-5 py-3 m-4 box-shadow rounded-3xl ${
                        typeTopic === 'Listening' ? 'bg-[#f6ae2d]' : 'bg-[#f3f7ff]'
                    }`}
                    onClick={() => setTypeTopic('Listening')}
                >
                    <h5 className="text-32px font-medium">{t('listening')}</h5>
                    <span className="flex justify-center items-center">
                        <img className="" src={Play} alt="play" />
                    </span>
                </button>
                <button
                    className={`lg:px-20 lg:py-10 px-5 py-3 m-4 box-shadow rounded-3xl ${
                        typeTopic === 'Reading' ? 'bg-[#f6ae2d]' : 'bg-[#f3f7ff]'
                    }`}
                    onClick={() => setTypeTopic('Reading')}
                >
                    <h5 className="text-32px font-medium">{t('reading')}</h5>
                    <span className="flex justify-center items-center">
                        <img className="w-[72px] h-[72px]" src={Play} alt="play" />
                    </span>
                </button>
            </div>
            <div className="lg:px-20 px-5  flex flex-wrap justify-between mt-8">
                {data.map((test, index) => (
                    <ShortTest key={index} test={test} t={t} />
                ))}
            </div>
        </div>
    );
}

export default SkillTest;
