import './Header.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import config from '~/config';
import images from '~/assets/images';
import { Popover } from 'antd';

import Navbar from '~/components/Header/Navbar';
import { SearchIcon, BellIcon, LogoutIcon, Bar3Icon } from '~/components/Icons';
import Avatar from '~/components/Avatar';
import Language from '~/components/Language';
import InformationUser from '~/components/ForumLayout/Navigation/InformationUser';

import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { useDispatch, useSelector } from 'react-redux';
import { clearUser, setUser } from '~/redux/userSlice';

import { logout } from '~/api/userApi';
import ButtonLogin from '~/components/Button/ButtonLogin';

function Header() {
    const [sticky, setSticky] = useState('xl:h-24 h-20');
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const role = localStorage.getItem('ROLE');

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);

        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken !== null) {
                try {
                    const response = await axiosPrivate.get('/information');
                    isMounted && dispatch(setUser(response?.data?.responseData));
                } catch (err) {
                    if (err.response && err.response?.data.violations === '401') {
                        if (window.location.pathname !== '/') {
                            navigate('/login', { state: { from: location }, replace: true });
                        }
                    }
                }
            }
        };

        if (!user) {
            getUsers();
        }

        return () => {
            window.removeEventListener('scroll', isSticky);
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, location, navigate, dispatch]);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 80 ? 'xl:h-20 h-16' : 'xl:h-24 h-20';
        setSticky(stickyClass);
    };

    const signOut = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            if (accessToken !== null && refreshToken !== null) {
                const response = await logout(
                    {
                        accessToken,
                        refreshToken,
                    },
                    {
                        withCredentials: true,
                    },
                );
                if (response.status === 'success') {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('ROLE');
                    dispatch(clearUser());
                    navigate('/login');
                }
            }
        } catch (err) {}
    };

    const handleClickOutside = (e) => {
        // Check if the click is outside the menu
        if (openMenu && e.target.closest('.bg-hotlink-white-f7f3ff') === null) {
            setOpenMenu(false);
        }
    };

    return (
        <header
            className={`flex flex-row justify-start items-center mb-4 px-10 pr-5 xl:px-20 bg-white shadow-shadow-header fixed z-[100] w-full ${sticky}`}
        >
            <div className="h-full w-full flex justify-end items-center">
                <ul className="hidden grow lg:flex lg:justify-end h-full">
                    <Navbar title="home" href="/" />
                    <div className="group relative inline-block ">
                        <Navbar title="news" href="/news" />
                        <div className="hidden group-hover:block absolute min-w-[160px] z-[1] py-3 bg-white shadow-[0px_8px_16px_0px_rgba(0,0,0,0.2)] hover:bg-hotlink-purple-f7f3ff">
                            <Navbar title="forum" href="/news/forum" />
                        </div>
                    </div>

                    <Navbar title="dictionary" href="/dictionary" />
                    <Navbar title="mock_test" href="/test" />
                    <Navbar title="exam_repository" href="/examRepository" />
                    <Navbar title="Flashcards" href="/flash-card" />
                </ul>

                <div className="h-12 flex items-center flex-row">
                    <button className="hidden lg:flex search-icon -ml-1">
                        <SearchIcon />
                    </button>
                    {user ? (
                        <>
                            {/* <button className="bell-icon">
                                <BellIcon color="#000000" />
                                <span className="bg-hotlink-red-ff4d4f absolute w-8 h-5 flex items-center justify-center px-1 -right-2.5 -top-3.5 rounded-[100px] text-xs font-semibold text-white">
                                    11
                                </span>
                            </button> */}

                            <Popover
                                content={
                                    <div className="flex flex-col gap-6 px-4 py-2">
                                        <InformationUser />

                                        <button
                                            className="pl-3 py-3 flex flex-row gap-4 justify-start items-center border-[1px] border-black rounded-md"
                                            onClick={() => {
                                                signOut();
                                                setOpen(false);
                                            }}
                                        >
                                            <LogoutIcon />
                                            <span className="text-lg">Đăng xuất</span>
                                        </button>
                                        {role === 'ADMIN' ? (
                                            <button
                                                className="pl-[58px] py-3 flex flex-row gap-4 justify-start items-center border-[1px] border-black rounded-md"
                                                onClick={() => {
                                                    navigate('/admin/manager/manager-exam');
                                                    setOpen(false);
                                                }}
                                            >
                                                <span className=" text-lg">Admin</span>
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                }
                                trigger="click"
                                open={open}
                                onOpenChange={handleOpenChange}
                            >
                                <button className="avatar-icon flex items-center -ml-2">
                                    <Avatar
                                        className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba h-7 w-7"
                                        src={user.User && user.User.avatar ? user.User.avatar : ''}
                                        alt="user"
                                    />
                                    {/* <span className="user-name text-sm text-black opacity-80 leading-[1.57]">
                                        {user?.User.userName.split(' ').pop()}
                                    </span> */}
                                </button>
                            </Popover>
                        </>
                    ) : (
                        <ButtonLogin link={'/login'} />
                    )}

                    <div className="hidden lg:flex choose-language text-sm text-black opacity-80 leading-[1.57] -ml-1">
                        <Language />
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setOpenMenu(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bar3Icon />
                        </button>
                    </div>

                    <div
                        className={`fixed top-0 left-0 ${
                            openMenu ? 'bg-black bg-opacity-70' : null
                        } h-screen w-full flex justify-end z-[11] transition-transform ease-in-out duration-300`}
                        onClick={handleClickOutside}
                        style={{ transform: openMenu ? 'translateX(0)' : 'translateX(100%)' }}
                    >
                        <div className="w-1/2 bg-hotlink-white-f7f3ff flex flex-col">
                            <div className="w-full flex flex-row px-4 py-2 justify-between items-center bg-hotlink-blue-4d27b3">
                                <img
                                    className="logo-link object-contain h-16 w-16 xl:h-32 xl:w-32"
                                    src={images.logo}
                                    alt="MeU"
                                />
                                <div className="choose-language text-sm text-black opacity-80 leading-[1.57]">
                                    <Language />
                                </div>
                            </div>

                            <Navbar title="home" href="/" closeMenu={() => setOpenMenu(false)} />
                            <Navbar title="news" href="/news" closeMenu={() => setOpenMenu(false)} />
                            <Navbar title="forum" href="/news/forum" closeMenu={() => setOpenMenu(false)} />
                            <Navbar title="dictionary" href="/dictionary" closeMenu={() => setOpenMenu(false)} />
                            <Navbar title="mock_test" href="/test" closeMenu={() => setOpenMenu(false)} />
                            <Navbar
                                title="exam_repository"
                                href="/examRepository"
                                closeMenu={() => setOpenMenu(false)}
                            />
                            <Navbar title="Flashcards" href="/flash-card" closeMenu={() => setOpenMenu(false)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`absolute h-28 w-28 xl:h-[240px] xl:w-[30%] z-10`}>
                <div className="relative h-28 w-28 xl:h-[238px] xl:w-[238px] rounded-1/2 ml-2 xl:ml-[26px] bg-hotlink-yellow-f6ae2d mb-0.5">
                    <div className="absolute flex items-center justify-center h-28 w-28 xl:h-[238px] xl:w-[238px] right-5 lg:right-[26px] bg-hotlink-blue-309 rounded-1/2 top-0.5">
                        <Link to={config.routes.home}>
                            <img
                                className="logo-link object-contain h-16 w-16 xl:h-32 xl:w-32"
                                src={images.logo}
                                alt="MeU"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
