const SingleNew = (props) => {
    return (
        <div className="lg:w-[410px] rounded-xl shadow-shadow-comment grow-[2] bg-white mb-2">
            <img src={props.picture} className="h-[66%] w-full self-stretch rounded-t-xl" alt=""></img>
            <div className="flex flex-col gap-2 p-6 ">
                <span className="text-xl font-poppinsMedium leading-tight text-left text-hotlink-blue-309 mx-6">
                    {props.title}
                </span>

                <span className="h-6 font-robotoRegular text-sm leading-[1.57] text-left text-hotlink-black-00000072 mx-6 flex justity-center items-center ">
                    {props.address}
                </span>
            </div>
        </div>
    );
};
export default SingleNew;
