import { InputNumber, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import AnswerQuestion from '../AnswerQuestion';
import { useDispatch } from 'react-redux';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';

import DeleteQuestion from '../../../DeleteQuestion';
import EditQuestion from '../../../EditQuestion';
import { addQuestionToTopic } from '../../../../../../api/topicApi';

const QuestionPart5 = (props) => {
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [requestEdit, setRequestEdit] = useState(false);
    const [addQuestionEdit, setAddQuestionEdit] = useState(props.addQuestionEdit);

    const [contentQuestion, setContentQuestion] = useState({
        questionId: props.questionEdit?.questionId || '',
        questionContent: props.questionEdit?.questionContent || '',
        questionScore: props.questionEdit?.questionScore || 1,
        partId: props.partId,
        listAnswer: props.questionEdit?.listAnswer
            ? props.questionEdit.listAnswer.map((answer) => ({
                  idAnswer: answer.answerId,
                  contentAnswer: answer.answerContent,
                  correctAnswer: answer.answerId === props.questionEdit.answerCorrect,
              }))
            : [
                  { contentAnswer: '', correctAnswer: true },
                  { contentAnswer: '', correctAnswer: false },
                  { contentAnswer: '', correctAnswer: false },
                  { contentAnswer: '', correctAnswer: false },
              ],
        err: {},
    });
    const addQuestionToTopicFunc = async () => {
        try {
            dispatch(startLoading());
            const formData = new FormData();

            formData.append(`partId`, contentQuestion.partId);
            formData.append(`questionContent`, contentQuestion.questionContent);
            formData.append(`questionScore`, contentQuestion.questionScore ? contentQuestion.questionScore : 1);

            if (contentQuestion.listAnswer) {
                contentQuestion.listAnswer.forEach((listAnswerItem, answerIndex) => {
                    formData.append(`listAnswer[${answerIndex}].contentAnswer`, listAnswerItem.contentAnswer);
                    formData.append(`listAnswer[${answerIndex}].correctAnswer`, listAnswerItem.correctAnswer);
                });
            }
            const response = await addQuestionToTopic(axiosPrivate, props.topicId, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 'success') {
                dispatch(setErrMessage(response.message));
                dispatch(setStatusMessage('Success'));
                setContentQuestion((prevState) => ({
                    ...prevState,
                    questionId: response.responseData.questionId,
                }));

                setAddQuestionEdit(false);
                props.removeQuestion(contentQuestion.questionId);
                props.addQuestion({
                    ...response.responseData,
                    questionId: response.responseData.questionId,
                });
            } else {
                dispatch(setErrMessage('Add question to topic fail'));
                dispatch(setStatusMessage('Fail'));
            }
        } catch (err) {
            dispatch(setErrMessage(err));
            dispatch(setStatusMessage('Fail'));
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (props.setListQuestion) {
            props.setListQuestion(contentQuestion);
        }
    }, [contentQuestion]);

    return (
        <div className="flex flex-col gap-4 px-6">
            {openDelete ? (
                <DeleteQuestion
                    questionId={contentQuestion.questionId}
                    removeQuestion={props.removeQuestion}
                    setOpenDelete={setOpenDelete}
                />
            ) : null}

            {openEdit ? (
                <EditQuestion
                    questionEdit={contentQuestion}
                    setOpenEdit={setOpenEdit}
                    setRequestEdit={setRequestEdit}
                />
            ) : null}
            <div className="flex flex-row items-center justify-between w-full ">
                <div className="flex flex-row items-center justify-start gap-2 w-2/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Câu hỏi {props.number + 1}:</span>
                    <Input
                        disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                        className="font-BeVietNamRegular text-sm w-full"
                        placeholder="Nội dung câu hỏi "
                        defaultValue={contentQuestion.questionContent}
                        onChange={(e) => {
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                questionContent: e.target.value,
                            }));
                        }}
                    ></Input>
                </div>
                <div className="flex flex-row items-center justify-end gap-2 w-1/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Số điểm</span>
                    <InputNumber
                        className="w-1/2 font-BeVietNamRegular text-sm"
                        disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                        placeholder="Số điểm"
                        defaultValue={contentQuestion.questionScore}
                        onChange={(value) => {
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                questionScore: value,
                            }));
                        }}
                    />
                </div>
            </div>
            <AnswerQuestion
                disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                answerEdit={props.questionEdit || null}
                setAnswerQuestion={(answerQuestion) => {
                    setContentQuestion((prevContentQuestion) => ({
                        ...prevContentQuestion,
                        listAnswer: answerQuestion,
                    }));
                }}
            />
            {props.questionEdit ? (
                <div className="w-full flex justify-end gap-4 pb-4">
                    {addQuestionEdit ? (
                        <button
                            className="w-24 text-sm bg-hotlink-purple-714da6 font-interSemi py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                            onClick={() => addQuestionToTopicFunc()}
                        >
                            Lưu
                        </button>
                    ) : !requestEdit ? (
                        <button
                            className="w-24 text-sm bg-hotlink-purple-714da6 font-interSemi py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                            onClick={() => setRequestEdit(true)}
                        >
                            Chỉnh sửa
                        </button>
                    ) : (
                        <button
                            className="w-24 text-sm bg-hotlink-purple-714da6 font-interSemi py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                            onClick={() => setOpenEdit(true)}
                        >
                            Lưu
                        </button>
                    )}
                    <button
                        className="w-24 text-sm bg-hotlink-red-d73d32 font-interSemi py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
             hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
             active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                        onClick={() =>
                            addQuestionEdit ? props.removeQuestion(props.questionEdit.questionId) : setOpenDelete(true)
                        }
                    >
                        Xóa
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default QuestionPart5;
