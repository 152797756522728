// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 4;
  position: relative;
  border: solid 2px #99f;
  border-radius: 999px;
  height: 14px;
  width: 14px;
  background-color: #ffffff;
}

.progress-bar::before {
  transform: scaleX(var(--progress-width));
  z-index: 3;
}

.progress-bar::after {
  transform: scaleX(var(--buffered-width));
  transform-origin: left;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/ToeicExamPage/AudioPlay/AudioProgress/AudioProgress.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,UAAA;EACA,kBAAA;EACA,sBAAA;EACA,oBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;AACJ;;AAEA;EACI,wCAAA;EACA,UAAA;AACJ;;AAEA;EACI,wCAAA;EACA,sBAAA;EACA,UAAA;AACJ","sourcesContent":[".progress-bar::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    z-index: 4;\n    position: relative;\n    border: solid 2px #99f;\n    border-radius: 999px;\n    height: 14px;\n    width: 14px;\n    background-color: #ffffff;\n}\n\n.progress-bar::before {\n    transform: scaleX(var(--progress-width));\n    z-index: 3;\n}\n\n.progress-bar::after {\n    transform: scaleX(var(--buffered-width));\n    transform-origin: left;\n    z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
