import { Select } from 'antd';
import { useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {  ChevronDown, XmarkIcon } from '../../../Icons';
import { setOptionAdminList } from '~/redux/adminSlice';

function SearchAccountUser() {
    const dispatch = useDispatch();

    const optionAdminList = useSelector((state) => state.admin.optionAdminList);

    const [enableFill, setEnableFill] = useState();

    const menuEnable = [
        { value: 'disable', label: 'Không hoạt động' },
        { value: 'enable', label: 'Hoạt động' },
    ];

    const choiceEnable = (value) => {
        dispatch(setOptionAdminList({ ...optionAdminList, enable: value }));
    };

    return (
        <div className="w-full flex flex-row gap-4 justify-start items-start pt-4 h-14 font-poppinsRegular text-sm text-hotlink-black-161616 ">
            <Select
                suffixIcon={<ChevronDown />}
                allowClear={{ clearIcon: <XmarkIcon /> }}
                className="filterPost-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                placeholder="Chọn chế độ"
                options={menuEnable}
                value={enableFill}
                onChange={(value) => {
                    setEnableFill(value);
                    choiceEnable(value);
                }}
            ></Select>
        </div>
    );
}

export default SearchAccountUser;
