import React, { useState, useEffect } from 'react';
import { Input, Upload, message, notification, Spin, Collapse } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { addWordToFlashCard } from '~/api/flashCardApi';
import { updateWord } from '~/api/flashCardWordApi';
import { CloseIcon } from '~/components/Icons';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

const { TextArea } = Input;

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const AddFlashCardWord = (props) => {
    const [api, contextHolder] = notification.useNotification();
    const axiosPrivate = useAxiosPrivate();

    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(props.word && props.word.image ? props.word.image : '');

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0].originFileObj;
            if (file) {
                setContentWord((prevContentWord) => ({
                    ...prevContentWord,
                    image: file,
                }));
                const reader = new FileReader();
                reader.onload = (info) => {
                    setImageUrl(info.target.result);
                };
                reader.readAsDataURL(file);
            }
        } else {
            setImageUrl(null);
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const [contentWord, setContentWord] = useState({
        word: props.word ? props.word.word : '',
        define: props.word ? props.word.define : '',
        image: props.word && props.word.image ? props.word.image : '',
        type: props.word ? props.word.type : '',
        spelling: props.word ? props.word.spelling : '',
        example: props.word ? props.word.example : '',
        note: props.word ? props.word.note : '',
        err: {},
    });

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    function validate() {
        if (!contentWord.word) {
            setErrMsg('Please enter word');
            setStatusMsg('Create word fail');
            setContentWord((prevContentWord) => ({
                ...prevContentWord,
                err: {
                    ...prevContentWord.err,
                    word: `Please enter word`,
                },
            }));
            return false;
        }

        if (!contentWord.define) {
            setErrMsg('Please enter define');
            setStatusMsg('Create word fail');
            setContentWord((prevContentWord) => ({
                ...prevContentWord,
                err: {
                    ...prevContentWord.err,
                    define: `Please enter define`,
                },
            }));
            return false;
        }

        return true;
    }

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [contentWord]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
        if (errMsg !== 'Successful') {
            setErrMsg('');
            setStatusMsg('');
        }
    }, [errMsg, statusMsg]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                setIsLoading(true);
                const formData = new FormData();
                formData.append('word', contentWord.word);
                formData.append('define', contentWord.define);
                if (contentWord.image !== '') {
                    formData.append('image', contentWord.image);
                }
                formData.append('type', contentWord.type);
                formData.append('spelling', contentWord.spelling);
                formData.append('example', contentWord.example);
                formData.append('note', contentWord.note);

                let response;
                if (props.word) {
                    response = await updateWord(axiosPrivate, props.word.wordId, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                } else {
                    response = await addWordToFlashCard(axiosPrivate, props.flashCardId, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                }

                if (response?.violations === 401) {
                    setErrMsg('Unauthorized');
                    props.word ? setStatusMsg('Update word fail') : setStatusMsg('Create word fail');
                } else if (response?.status === 'fail') {
                    setErrMsg(response?.message);
                    props.word ? setStatusMsg('Update word fail') : setStatusMsg('Create word fail');
                } else {
                    setErrMsg('Successful');
                    if (props.word) {
                        setStatusMsg('Update word successful');
                        props.updateWord(response.responseData);
                    } else {
                        setStatusMsg('Create word successful');
                        props.addWordList(response.responseData);
                    }

                    props.closeAddWord();
                }
            } catch (err) {
                console.log(err);
                if (props.word) {
                    setErrMsg('Update word Failed');
                    setStatusMsg('Update word fail');
                } else {
                    setErrMsg('Create word Failed');
                    setStatusMsg('Create word fail');
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="fixed z-[101] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center">
            {contextHolder}
            <div className="w-1/2 max-h-[80%] flex flex-col bg-white pb-8 rounded-xl">
                <header className="flex flex-row justify-between items-center bg-hotlink-blue-309 pt-8 pb-4 px-8 rounded-t-xl">
                    <span className="text-xl text-hotlink-black-161616 after:max-w-full after:w-1/2 after:h-[3px] after:block after:bg-hotlink-red-ff4d4f">
                        {props.word ? 'Cập nhật từ' : 'Thêm từ mới'}
                    </span>
                    <button
                        onClick={() => {
                            props.closeAddWord();
                        }}
                    >
                        <CloseIcon width="30" height="32" className="cursor-pointer" fill="#ffffff"/>
                    </button>
                </header>

                <form className="flex flex-col gap-6 px-8 overflow-y-auto pt-4" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-col items-start justify-start gap-4">
                        <span className="font-BeVietNamRegular text-lg font-bold">Từ mới</span>
                        <Input
                            className="font-BeVietNamRegular text-base"
                            placeholder="Nhập từ mới"
                            onChange={(e) => {
                                setContentWord((prevContentWord) => ({
                                    ...prevContentWord,
                                    word: e.target.value,
                                    err: {
                                        ...prevContentWord.err,
                                        word: e === '' ? 'Please enter word' : null,
                                    },
                                }));
                            }}
                            defaultValue={props.word ? props.word.word : null}
                        />
                        {contentWord.err.word && (
                            <div className="text-red-500 text-sm ml-8">{contentWord.err.word}</div>
                        )}
                    </div>

                    <div className="flex flex-col items-start justify-start gap-4">
                        <span className="font-BeVietNamRegular text-lg font-bold">Nhập định nghĩa</span>
                        <TextArea
                            className="font-BeVietNamRegular text-base"
                            placeholder="Nhập định nghĩa"
                            rows={4}
                            maxLength={6}
                            onChange={(e) => {
                                setContentWord((prevContentWord) => ({
                                    ...prevContentWord,
                                    define: e.target.value,
                                    err: {
                                        ...prevContentWord.err,
                                        define: e === '' ? 'Please enter your define' : null,
                                    },
                                }));
                            }}
                            defaultValue={props.word ? props.word.define : null}
                        />
                        {contentWord.err.define && (
                            <div className="text-red-500 text-sm ml-8">{contentWord.err.define}</div>
                        )}
                    </div>

                    <div className="flex justify-center items-center">
                        <Collapse
                            className="w-full"
                            items={[
                                {
                                    key: '1',
                                    showArrow: false,
                                    label: (
                                        <button
                                            className="h-12 w-full cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                                            type="button"
                                        >
                                            <span className="text-base font-BeVietNamBold leading-normal text-center font-medium text-white">
                                                Thêm phiên âm, ví dụ, ghi chú
                                            </span>
                                        </button>
                                    ),
                                    children: (
                                        <div className="flex flex-col gap-6">
                                            <div className="flex flex-row items-center justify-start gap-8">
                                                <span className="font-BeVietNamRegular text-lg font-bold w-1/3">
                                                    Ảnh đại diện
                                                </span>
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    showUploadList={false}
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleChange}
                                                >
                                                    {imageUrl ? (
                                                        <img
                                                            src={imageUrl}
                                                            alt="avatar"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </div>
                                            <div className="flex flex-row justify-between">
                                                <div className="w-2/5 flex flex-col items-start justify-start gap-4">
                                                    <span className="font-BeVietNamRegular text-base font-bold">
                                                        Loại từ (N, V, ADJ...)
                                                    </span>
                                                    <Input
                                                        className="font-BeVietNamRegular text-base"
                                                        placeholder="Nhập loại từ"
                                                        onChange={(e) => {
                                                            setContentWord((prevContentWord) => ({
                                                                ...prevContentWord,
                                                                type: e.target.value,
                                                            }));
                                                        }}
                                                        defaultValue={props.word ? props.word.type : null}
                                                    />
                                                </div>

                                                <div className="w-2/5 flex flex-col items-start justify-start gap-4">
                                                    <span className="font-BeVietNamRegular text-base font-bold">
                                                        Phiên âm
                                                    </span>
                                                    <Input
                                                        className="font-BeVietNamRegular text-base"
                                                        placeholder="Nhập loại từ"
                                                        onChange={(e) => {
                                                            setContentWord((prevContentWord) => ({
                                                                ...prevContentWord,
                                                                spelling: e.target.value,
                                                            }));
                                                        }}
                                                        defaultValue={props.word ? props.word.spelling : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col items-start justify-start gap-4">
                                                <span className="font-BeVietNamRegular text-lg font-bold">
                                                    Nhập ví dụ
                                                </span>
                                                <TextArea
                                                    className="font-BeVietNamRegular text-base"
                                                    placeholder="Nhập ví dụ"
                                                    rows={4}
                                                    maxLength={6}
                                                    onChange={(e) => {
                                                        setContentWord((prevContentWord) => ({
                                                            ...prevContentWord,
                                                            example: e.target.value,
                                                        }));
                                                    }}
                                                    defaultValue={props.word ? props.word.example : null}
                                                />
                                            </div>

                                            <div className="flex flex-col items-start justify-start gap-4">
                                                <span className="font-BeVietNamRegular text-lg font-bold">
                                                    Nhập chú thích
                                                </span>
                                                <TextArea
                                                    className="font-BeVietNamRegular text-base"
                                                    placeholder="Nhập định nghĩa"
                                                    rows={4}
                                                    maxLength={6}
                                                    onChange={(e) => {
                                                        setContentWord((prevContentWord) => ({
                                                            ...prevContentWord,
                                                            note: e.target.value,
                                                        }));
                                                    }}
                                                    defaultValue={props.word ? props.word.note : null}
                                                />
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        ></Collapse>
                    </div>

                    <div className="flex justify-end items-center">
                        <button
                            className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                            type="submit"
                        >
                            <span className="text-base font-BeVietnamBold leading-normal text-center font-medium text-white">
                                {props.word ? 'Cập nhật' : 'Thêm từ mới'}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
            {isLoading && (
                <div className="fixed z-[101] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading..." size="large"></Spin>
                </div>
            )}
        </div>
    );
};

export default AddFlashCardWord;
