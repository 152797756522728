const routes = {
    home: '/',
    login: '/login',
    register: '/register',
    confirmRegister: '/register/confirm',
    test: '/test',
    news: '/news',
    forum: '/news/forum',
    examRepository: '/examRepository',
    testInformation: '/test/test-guide',
    toeicFullTest: '/test/toeic-test',
    toeicFullTestItem: '/test/toeic-test/practice',
    dictionary: '/dictionary',
    flashCard: '/flash-card',
    flashCardList: '/flash-card/list',
    flashCardReview: '/flash-card/list/review',
    profileTest: '/profile',
    changeProfile: '/profile/change-profile',
    changePass: '/profile/change-pass',
    detailResult: '/test/detail',
    forgetPassword: '/forgetPassword',
    verifyOtp: '/verifyOtp',
    changePassword: '/changePassword',

    // admin_home: '/admin/*',
    admin_manage_exam: '/admin/manager/manager-exam',
    admin_manage_user: '/admin/manager-user',
    admin_manage_news: '/admin/manager-news',
    admin_manage_feedback: '/admin/manager-feedback',
    admin_manage_mocktest: '/admin/manager-mocktest',
    admin_manage_statistic: '/admin/manager-statistic',
};

export default routes;
