// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flash-card-slider .slick-list,
.flash-card-slider .slick-list .slick-track,
.flash-card-slider .slick-list .slick-slide div {

    height: 100%
}

.flash-card-slider .slick-list {

    margin-left: 0.75rem;

    margin-right: 0.75rem
}`, "",{"version":3,"sources":["webpack://./src/components/FlashCard/FlashCardReviewContent/FlashCardReviewContent.scss"],"names":[],"mappings":"AAGI;;;;IAAA;AAAA;;AAKA;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":[".flash-card-slider .slick-list,\n.flash-card-slider .slick-list .slick-track,\n.flash-card-slider .slick-list .slick-slide div {\n    @apply h-full;\n}\n\n\n.flash-card-slider .slick-list {\n    @apply mx-3;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
