import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Input } from 'antd';
import { UploadOutlined, CopyOutlined } from '@ant-design/icons';
import axiosPrivate from '../../../../../../api/axios';
import { uploadImageExam} from '../../../../../../api/fileApi/fileApi';

const UploadAudio = ({setAudioLink, resetUpload }) => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]); 
    const [copySuccess, setCopySuccess] = useState('');
    const [isAnotherUpload, setIsAnotherUpload] = useState(false); 

    const resetData = () => {
        setAudioUrl(null);
        setFileList([]);
        setCopySuccess('');
        if (setAudioLink) setAudioLink('');
        setIsAnotherUpload(false);
    };

    useEffect(() => {
        if (resetUpload) {
            resetData();
        }
    }, [resetUpload]);

    const beforeUpload = (file) => {
        const isAudio = file.type === 'audio/mpeg' || file.type === 'audio/mp3';

        if (!isAudio) {
            message.error('You can only upload MP3/MPEG file!');
            return false;
        }

        return true;
    };

    const handleChange = async ({ file, fileList }) => {
        setAudioUrl(null);
        setCopySuccess('');
        setFileList(fileList);

        if (file.status !== 'uploading') {
            setUploading(true);

            if (beforeUpload(file)) {
                try {
                    const formData = new FormData();
                    formData.append('file', file.originFileObj);

                    const uploadResponse = await uploadImageExam(axiosPrivate, formData);
                    
                    if (uploadResponse.status === 'success') {
                        const audioUrlGet = `https://gateway.dev.meu-solutions.com/englishmaster/api/file/showAudio/${uploadResponse.responseData}`;
                        setAudioUrl(audioUrlGet);
                        setAudioLink(audioUrlGet);
                    } else {
                        message.error('Upload failed');
                    }
                } catch (error) {
                    message.error('Upload failed');
                } finally {
                    setUploading(false);
                }
            }
        }
    };

    const handleRemove = () => {
        setAudioUrl(null);
        setFileList([]);
    };

    const copyToClipboard = () => {
        if (audioUrl) {
            navigator.clipboard.writeText(audioUrl)
                .then(() => setCopySuccess('Link copied!'))
                .catch(() => setCopySuccess('Copy link failed!'));
        }
    };

    const handleUploadAnother = () => {
        setIsAnotherUpload(true); 
        resetData(); 
    };

    return (
        <div className="flex flex-col items-center justify-start gap-4 w-full">
            <Upload
                className="!w-full"
                listType="text"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                fileList={fileList}
                onRemove={handleRemove}
            >
                {audioUrl ? (
                    <audio controls style={{ width: '300px' }}>
                        <source src={audioUrl} />
                    </audio>
                ) : (
                    <Button icon={<UploadOutlined />} loading={uploading}>
                        {uploading ? 'Uploading...' : 'Upload'}
                    </Button>
                )}
            </Upload>
            {audioUrl && (
                <div className="flex items-center gap-4">
                    <Input
                        value={audioUrl}
                        readOnly
                        style={{ width: '300px' }}
                    />
                    <Button 
                        icon={<CopyOutlined />} 
                        onClick={copyToClipboard}
                    >
                        Copy link
                    </Button>
                    {copySuccess && <span>{copySuccess}</span>}
                </div>
            )}
            {audioUrl && !isAnotherUpload && (
                <div className="flex items-center gap-4">
                    <Button onClick={handleUploadAnother} type="default" className="mt-4">
                        Upload Another Audio
                    </Button>
                </div>
            )}
        </div>
    );
};

export default UploadAudio;
