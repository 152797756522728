// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/test-banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-news {
  background-image: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}`, "",{"version":3,"sources":["webpack://./src/components/News/Banner/Banner.scss"],"names":[],"mappings":"AAAA;EACI,oHAAA;EAGA,sBAAA;EACA,4BAAA;EACA,kCAAA;AADJ","sourcesContent":[".banner-news {\n    background-image: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0) 100%),\n        url('../../../assets/images/test-banner.png');\n\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
