export const removeWord = (axiosPrivate, flashCardWordId) => {
    return axiosPrivate
        .delete(`/flashCardWord/${flashCardWordId}/removeWord`)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const updateWord = (axiosPrivate, flashCardWordId, payload, config) => {
    return axiosPrivate
        .put(`/flashCardWord/${flashCardWordId}/updateWord`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
