import { Radio, Input } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';

const AnswerQuestion = (props) => {
    const [answerQuestion, setAnswerQuestion] = useState(
        props.answerEdit?.listAnswer
            ? props.answerEdit.listAnswer.map((answer) => ({
                  idAnswer: answer.answerId,
                  contentAnswer: answer.answerContent,
                  correctAnswer: answer.answerId === props.answerEdit.answerCorrect,
              }))
            : [
                  { contentAnswer: '', correctAnswer: true },
                  { contentAnswer: '', correctAnswer: false },
                  { contentAnswer: '', correctAnswer: false },
                  { contentAnswer: '', correctAnswer: false },
              ],
    );
    const [value, setValue] = useState(answerQuestion.findIndex((answer) => answer.correctAnswer === true) + 1);

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;

        // Update the correctAnswer property in the answerQuestion array
        const updatedListAnswer = answerQuestion.map((answer, index) => ({
            ...answer,
            correctAnswer: index + 1 === selectedValue,
        }));

        setAnswerQuestion(updatedListAnswer);
        setValue(selectedValue);
    };

    const handleInputChange = (index, e) => {
        const updatedAnswerQuestion = [...answerQuestion];
        updatedAnswerQuestion[index].contentAnswer = e.target.value;
        setAnswerQuestion(updatedAnswerQuestion);
    };

    useEffect(() => {
        props.setAnswerQuestion(answerQuestion);
    }, [answerQuestion]);

    return (
        <div className="flex flex-row items-center gap-2 w-full">
            <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Câu trả lời đúng:</span>
            <Radio.Group
                disabled={props.disabled}
                className="flex flex-row gap-6 flex-wrap"
                onChange={handleRadioChange}
                value={value}
            >
                {answerQuestion.map((answer, index) => (
                    <Radio key={index + 1} className="w-[calc(50%-30px)]" value={index + 1}>
                        <Input
                            disabled={props.disabled}
                            className="font-BeVietNamRegular text-sm w-full"
                            placeholder={`Câu trả lời ${String.fromCharCode(65 + index)}`}
                            defaultValue={answer.contentAnswer}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );
};

export default AnswerQuestion;
