import React from 'react';
import { Breadcrumb } from 'antd';

const FlashCardBreadCrumb = () => {
    return (
        <Breadcrumb className='font-poppinsRegular text-sm'
            items={[
                {
                    title: 'Trang chủ',
                },
                {
                    title: <a href="dsadsad" className='!text-hotlink-blue-309 !cursor-pointer '>FlashCard</a>,
                },
            ]}
        />
    );
}

export default FlashCardBreadCrumb;
