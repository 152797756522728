export const listFlashCardWord = (axiosPrivate, flashCardId) => {
    return axiosPrivate
        .get(`/flashCard/${flashCardId}/listFlashCardWord`)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listFlashCardUser = (axiosPrivate) => {
    return axiosPrivate
        .get(`/flashCard/listFlashCardUser`)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addFlashCardUser = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .post('/flashCard/addFlashCardUser', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addWordToFlashCard = (axiosPrivate, flashCardId, payload, config) => {
    return axiosPrivate
        .post(`/flashCard/${flashCardId}/addWordToFlashCard`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const removeFlashCard = (axiosPrivate, flashCardId, payload, config) => {
    return axiosPrivate
        .delete(`/flashCard/${flashCardId}/removeFlashCard`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};


export const updateFlashCard = (axiosPrivate, flashCardId, payload, config) => {
    return axiosPrivate
        .put(`/flashCard/${flashCardId}/updateFlashCard`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};