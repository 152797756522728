import Search from '~/components/Dictionary/Search';
import Agreement from '~/components/Dictionary/Agreement/Agreement';
import Meanings from '~/components/Dictionary/Meanings/Meanings';

function Dictionary() {
    return (
        <div className="flex mt-[-30px] items-center flex-col gap-6 bg-[#f7f3ff]">
            <div className="lg:w-[58%] w-[90%] flex rounded-sm flex-col justify-start items-center gap-6 mt-[86px]">
                <Search defaultKeyword="beach" />
                <Agreement defaultKeyword="beach" />
                <Meanings defaultKeyword="beach" />
            </div>
        </div>
    );
}

export default Dictionary;
