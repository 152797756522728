// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-shadow {
  box-shadow: 7px 7px 24px 0 rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/ToeicTestItem/ToeicTestItem.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;AACJ","sourcesContent":[".box-shadow {\n    box-shadow: 7px 7px 24px 0 rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
