import Banner from '../../components/TestPage/Banner';
import TestGuide from '../../components/TestPage/Information/TestGuide/TestGuide';
import Fulltest from '../../components/TestPage/Fulltest/Fulltest';

function TestInformation() {
    return (
        <div>
            <Banner />
            <TestGuide />
            <Fulltest backgroundColor="#fff2ec" />
        </div>
    );
}

export default TestInformation;
