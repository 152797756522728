import ButtonCustom from '../../../Button/ButtonCustom';
import './TestGuide.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

function TestGuide() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();

    const topicId = new URLSearchParams(location.search).get('id');

    return (
        <div className="w-full h-full flex text-center flex-col py-10 bg-[#f7f3ff]">
            <h1 className="text-40px font-semibold text-[#309] mb-8">{t('test_guide')}</h1>
            <div className="guide-content text-left px-20 pb-10 mt-8">
                <h5> Để tham dự bài thi TOEIC Online, thí sinh vui lòng làm theo các hướng dẫn dưới đây:</h5>
                <ul className="list-disc mb-10">
                    Bước 1: Đăng nhập
                    <li>Thí sinh vui lòng đăng nhập vào hệ thống để bắt đầu quá trình thi thử.</li>
                </ul>

                <ul className="list-disc mb-10">
                    Bước 2: Nhập mã code thi
                    <li>Thí sinh nhập mã Code dự thi sau đó nhấn vào nút “Vào thi”.</li>
                    <li>Lưu ý: Mỗi mã code chỉ sử dụng 01 lần.</li>
                </ul>

                <ul className="list-disc mb-10">
                    Bước 3: Đọc hướng dẫn làm bài
                    <li>
                        Hệ thống giới thiệu thông tin bài thi và các công cụ làm bài. Thời gian tìm hiểu thông tin bài
                        thi và các công cụ làm bài không tính trong thời gian làm bài.
                    </li>
                    <li>
                        Nhấn nút “Hoàn thành” sau khi đã đọc xong thông tin giới thiệu và nhấn “Bắt đầu” để bắt đầu làm
                        bài thi.
                    </li>
                </ul>

                <ul className="list-disc mb-10">
                    Bước 4: Bắt đầu làm bài thi
                    <p>Thí sinh bắt đầu làm bài.</p>
                    <p>Lưu ý:</p>
                    <li>
                        Phần thi nghe được tự động chạy và chuyển câu khi bắt đầu làm bài thi. Phần thi này thí sinh
                        không được quay lại câu hỏi chưa hoàn thành.
                    </li>
                    <li>Thí sinh có thể đánh dấu các câu hỏi cần xem lại với phần thi Reading.</li>
                </ul>
                <span>Bước 5: Nộp bài</span>
                <p className="mb-10">
                    Sau khi làm bài thi xong, thí sinh nhấn nút “Nộp bài”. Màn hình sẽ hiển thị số điểm của thí sinh và
                    thông tin tham khảo.
                </p>
                <span>
                    Thông tin hỗ trợ khách hàng
                    <p>
                        Trong trường hợp gặp trục trặc về kỹ thuật hoặc cần được hỗ trợ, bạn vui lòng liên hệ hotline
                        1900636929 hoặc hòm mail info@meuenglish.edu.vn. Chúc các thí sinh hoàn thành bài thi với kết
                        quả cao!
                    </p>
                    <p>Trân trọng</p>
                    MeU English
                </span>
            </div>

            <div className="flex justify-center items-center">
                <ButtonCustom
                    onClick={() => navigate(`/test/toeic-test/practice?id=${topicId}`)}
                    content="Thực hiện đề thi"
                    className="w-1/5"
                />
            </div>
        </div>
    );
}

export default TestGuide;
