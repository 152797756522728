import React, { useEffect, useState } from 'react';

import PlayBtn from '~/assets/svg/Play.svg';
import StopBtn from '~/assets/svg/Pause.svg';
import UnMute from '~/assets/svg/UnMute.svg';
import Mute from '~/assets/svg/Mute.svg';
import Settings from '~/assets/svg/Settings.svg';
import AudioProgress from './AudioProgress';
import VolumeProgress from './VolumeProgress';

function formatDurationDisplay(duration) {
    const min = Math.floor(duration / 60);
    const sec = Math.floor(duration - min * 60);

    const formatted = [min, sec].map((n) => (n < 10 ? '0' + n : n)).join(':');

    return formatted;
}

function AudioPlay(props) {
    const [duration, setDuration] = useState(0);
    const [currrentProgress, setCurrrentProgress] = useState(0);
    const [buffered, setBuffered] = useState(0);

    const [volume, setVolume] = useState(0.5);

    const [unMute, setUnMute] = useState(false);

    const durationDisplay = formatDurationDisplay(duration - currrentProgress);

    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = React.useRef(null);

    const handleBufferProgress = (e) => {
        const audio = e.currentTarget;
        const dur = audio.duration;
        if (dur > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
                    const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i);
                    setBuffered(bufferedLength);
                    break;
                }
            }
        }
    };

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
        } else {
            audioRef.current?.play();
            setIsPlaying(true);
        }
    };

    const handleMuteUnmute = () => {
        if (!audioRef.current) return;
        if (!unMute) {
            audioRef.current.volume = 0;
            setUnMute(true);
        } else {
            setUnMute(false);
            audioRef.current.volume = volume;
        }
    };

    const handleVolumeChange = (volumeValue) => {
        if (!audioRef.current) return;
        audioRef.current.volume = volumeValue;
        setVolume(volumeValue);
    };

    useEffect(() => {
        setCurrrentProgress(0);
        setIsPlaying(false);
        setBuffered(0);
        audioRef.current?.load();
    }, [props.audioQuestion]);

    return (
        <div className="flex flex-col gap-1 w-full">
            <div className="flex flex-row justify-start items-center gap-2">
                <button className="w-[52px] h-[52px]" onClick={togglePlayPause}>
                    {!isPlaying ? (
                        <img src={PlayBtn} alt="Play Button"></img>
                    ) : (
                        <img src={StopBtn} alt="Stop Button"></img>
                    )}
                </button>
                <audio
                    ref={audioRef}
                    preload="metadata"
                    onTimeUpdate={(e) => {
                        setCurrrentProgress(e.currentTarget.currentTime);
                        handleBufferProgress(e);
                    }}
                    onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                    onProgress={handleBufferProgress}
                    onPlaying={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                >
                    <source type="audio/mpeg" src={props.audioQuestion}></source>
                </audio>
                <AudioProgress
                    duration={duration}
                    currentProgress={currrentProgress}
                    buffered={buffered}
                    onChange={(e) => {
                        if (!audioRef.current) return;

                        audioRef.current.currentTime = e.currentTarget.valueAsNumber;

                        setCurrrentProgress(e.currentTarget.valueAsNumber);
                    }}
                />
                <span className="font-BeVietnamMedium text-lg font-medium text-hotlink-black-085">
                    -{durationDisplay}
                </span>
            </div>

            <div className="flex flex-row justify-end gap-2 items-center">
                <button className="w-8 h-8" onClick={handleMuteUnmute}>
                    {unMute ? <img src={Mute} alt="Mute Button"></img> : <img src={UnMute} alt="UnMute Button"></img>}
                </button>
                <VolumeProgress volume={volume} onVolumeChange={handleVolumeChange} />
                <button>
                    <img src={Settings} alt="Settings"></img>
                </button>
            </div>
        </div>
    );
}

export default AudioPlay;
