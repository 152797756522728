import './News.scss';
import { useTranslation } from 'react-i18next';
import newsImage from '~/assets/images/news-image2.png';
import ItemImage from '~/assets/images/info-test.png';
import NewsItem from '../NewsItem/NewsItem';

function News(props) {
    const { t } = useTranslation('common');

    const newsItem = [
        {
            img: ItemImage,
            title: 'MEU English triển khai định kỳ kỳ thi tiếng anh Quốc tế TOEIC',
            time: '10/5/2023',
            content:
                'Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
        },
        {
            img: ItemImage,
            title: 'MEU English triển khai định kỳ kỳ thi tiếng anh Quốc tế TOEIC',
            time: '10/5/2023',
            content:
                'Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
        },
        {
            img: ItemImage,
            title: 'MEU English triển khai định kỳ kỳ thi tiếng anh Quốc tế TOEIC',
            time: '10/5/2023',
            content:
                'Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
        },
    ];

    return (
        <div className="w-full flex flex-col text-center justify-center bg-[#f7f3ff] py-5">
            <h1 className="lg:text-[40px] text-3xl font-semibold text-[#309]">{t(props.title)}</h1>
            <div className="flex lg:flex-row flex-col justify-between lg:px-20 px-5 mt-8">
                <div className="lg:w-[50%] w-full box-shadow rounded-xl mr-4 mb-4 bg-[#fff]">
                    <img className="rounded-t-xl w-full" src={newsImage} alt="news" />
                    <div className="p-6 flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="lg:text-xs text-[10px] font-medium">{t('contest')}</span>
                            <span className="lg:text-xs text-[10px] font-medium">10/05/2023</span>
                        </div>
                        <span className="mb-2 text-[#309] lg:text-xl text-lg text-left">
                            Thông báo kì thi TOEIC định kỳ tạo TP.HCM
                        </span>
                        <span className="text-[13px] text-justify leading-snug">
                            Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea....
                        </span>
                    </div>
                </div>
                <div className="lg:w-[50%] w-full lg:ml-4">
                    {newsItem.map((news, index) => (
                        <NewsItem key={index} news={news} t={t} />
                    ))}
                </div>
            </div>
            <div className="mt-8">
                <button className="px-20 py-3 rounded-xs shadow bg-[#4d27b3]">
                    <span className="flex justify-center text-base leading-normal text-center font-medium text-white ">
                        {t('see_all')}
                    </span>
                </button>
            </div>
        </div>
    );
}

export default News;
