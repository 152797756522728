import { SearchDictionaryIcon, ChevronDown, XmarkIcon } from '~/components/Icons';
import { Select } from 'antd';

import { listPack } from '~/api/packApi';
import { setOptionAdminList} from '~/redux/adminSlice';
import { useDispatch } from 'react-redux';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';

const FindExam = () => {
    const axiosPrivate = useAxiosPrivate();
    const [menuPacks, setMenuPacks] = useState([]);
    const [valueFill, setValueFill] = useState();
    const [searchValue, setSearchValue] = useState('');
    const controller = new AbortController();

    const dispatch = useDispatch();

    const getListPack = async () => {
        try {
            const response = await listPack(axiosPrivate);
            if (response?.status === 'success') {
                const items = response.responseData.map((item, i) => ({
                    value: item.packId,
                    label: item.packName,
                }));
                setMenuPacks(items);
            }
        } catch (error) {}
    };

    function search() {
        const query = {};
        dispatch(setOptionAdminList({ ...query, pack: valueFill }));
        if (searchValue !== '') {
            dispatch(setOptionAdminList({ ...query, search: searchValue }));
        }
    }

    useEffect(() => {
        getListPack();

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <div className="w-full flex flex-row gap-4 justify-between items-end pt-4 h-14 font-poppinsRegular text-sm text-hotlink-black-161616 ">
            <div className="w-[28%] h-full flex flex-row items-center justify-between rounded-sm border-solid border-[1px] border-hotlink-white-d9d9d9 bg-white">
                <input
                    className="gap-[10px] py-1 px-3 w-full"
                    placeholder="Nhập từ khóa tìm kiếm"
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                ></input>
                <button onClick={() => search()}>
                    <SearchDictionaryIcon className="cursor-pointer w-[32px] h-[32px]" />
                </button>
            </div>

            <Select
                suffixIcon={<ChevronDown />}
                allowClear={{ clearIcon: <XmarkIcon /> }}
                className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                options={menuPacks}
                placeholder="Chọn khóa học"
            ></Select>

            <Select
                suffixIcon={<ChevronDown />}
                allowClear={{ clearIcon: <XmarkIcon /> }}
                className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                options={menuPacks}
                placeholder="Chọn bộ đề thi"
                value={valueFill}
                onChange={(value) => setValueFill(value)}
            ></Select>

            <button
                className="w-[10%] h-full px-[15px] py-1 rounded-sm shadow-[0_2px_0px_0px_rgba(0,0,0,0.4)] border-solid border-[1px] border-hotlink-blue-309 bg-hotlink-blue-309"
                onClick={() => search()}
            >
                <span className="text-white">Tìm kiếm</span>
            </button>
        </div>
    );
};

export default FindExam;
