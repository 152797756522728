import { Link } from 'react-router-dom';

import './ShortTest.scss';
import LeftArrow from '~/assets/svg/left-arrow.svg';

function ShortTest(props) {
    const { t, test} = props;

    return (
        <div className={`lg:w-[32%] w-[46%] my-6 rounded-xl bg-white box-shadow flex flex-col justify-between`}>
            <div className="px-4 py-[22px] flex flex-col lg:gap-2 gap-3">
                <div className="flex justify-between">
                    <p className="lg:text-xs text-[10px]">{test.quantity} CÂU HỎI</p>
                    <span className="px-2 py-px lg:text-xs text-[10px] rounded-sm bg-[#f6ae2d]">{test.time} Phút</span>
                </div>
                <span className="flex text-left lg:text-lg text-sm font-medium">{test.title}</span>
                <Link to={`/test/test-guide?id=${test.key}`}>
                    <button className="flex justify-center items-center text-left lg:text-base text-xs text-[#da1e37]">
                        {t('make_a_test')}
                        <img className="ml-2.5" src={LeftArrow} alt="" />
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default ShortTest;
