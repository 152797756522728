import SearchExam from '~/components/ExamRepository/SearchExam';
import FilterExam from '~/components/ExamRepository/FilterExam';
import Fulltest from '~/components/TestPage/Fulltest';

function ExamRepository() {
    return (
        <div className="bg-hotlink-purple-f7f3ff pb-[82px]">
            <div className="flex flex-col gap-8">
                <SearchExam />
                <FilterExam />
                <Fulltest backgroundColor="#ffeef0" />
            </div>
        </div>
    );
}

export default ExamRepository;
