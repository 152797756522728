import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import LoginImage from '~/assets/svg/Group64.svg';
import Result from '../Result/Result';
import { notification, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from '~/api/axios';
import { validEmail } from '~/utils/regex.js';

const FORGOTPASSWORD_URL = '/forgetPassword';

function ForgotPassword() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [user, setUser] = useState({ email: '', err: {} });
    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [user]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
    }, [errMsg, statusMsg]);

    const validate = () => {
        if (!validEmail.test(user.email)) {
            setStatusMsg('fail');
            setUser((prevUser) => ({
                ...prevUser,
                err: {
                    ...prevUser.err,
                    email: `${t('forgetPassword.email_format_wrong')}`,
                },
            }));
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                setIsLoading(true);
                const response = await axios.post(
                    `${FORGOTPASSWORD_URL}?email=${encodeURIComponent(user.email)}`,
                    null, 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                    }
                );
                
                if (response?.data?.violations === 401) {
                    setErrMsg('Unauthorized');
                    setStatusMsg('Gửi mã OTP thất bại');
                } else if (response?.data?.status === 'fail') {
                    setErrMsg('Email không tồn tại');
                    setStatusMsg('Gửi mã OTP thất bại');
                } else {
                    navigate('/verifyOtp', { state: { email: user.email } });
                }
            } catch (err) {
                setErrMsg('Gửi mã OTP thất bại');
                setStatusMsg('Gửi mã OTP thất bại');
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="w-full h-screen bg-white flex relative">
            {contextHolder}
            <div className="w-[50%] tablet:w-full mobile:w-full flex flex-col justify-between">
                <h1 className="flex justify-center items-center tablet:items-start tablet:mt-20 mobile:items-start mobile:mt-10 text-center flex-[1] text-[50px] leading-[44px] font-semibold not-italic tracking-normal text-[#309]">
                    {t('forgetPassword.welcome')}
                </h1>
                <img src={LoginImage} alt="" className="shrink-0 object-contain" />
            </div>
            <div className="w-[50%] h-auto flex flex-col justify-center items-center tablet:w-full mobile:w-full tablet:absolute mobile:absolute tablet:top-0 mobile:top-0 tablet:bottom-0 mobile:bottom-0">
                <div className="w-[80%] flex flex-col items-center rounded-[40px] gap-6 bg-white shadow-2xl">
                    <h1 className="font-poppinsRegular pt-6 text-[30px] leading-[44px] font-semibold not-italic tracking-normal text-left text-[#309]">
                        {t('forgetPassword.sign_in')}
                    </h1>
                    <div className="w-[80%] flex items-center">
                        <p className="mr-2 grow-0 font-poppinsRegular text-[14px] font-normal leading-[1.57] text-left text-[#333]">
                            Chúng tôi sẽ gửi OTP khôi phục mật khẩu vào hòm thư của bạn, bạn vui lòng nhập email bên dưới:
                        </p>
                    </div>
                    <div className="w-[80%]">
                        <form className="flex flex-col font-poppinsRegular text-base" onSubmit={handleSubmit}>
                            <div className=" flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('forgetPassword.email')}</label>
                                <input
                                    className=" border-gray items-center"
                                    placeholder={t('forgetPassword.input_email')}
                                    onChange={(e) =>
                                        setUser((prevUser) => ({
                                            ...prevUser,
                                            email: e.target.value,
                                            err: {
                                                ...prevUser.err,
                                                email: e.target.value === '' ? `${t('forgetPassword.email_required')}` : '',
                                            },
                                        }))
                                    }
                                    value={user.email}
                                    type="text"
                                />

                                {user.err.email && <div className="text-red-500 text-sm mt-1">{user.err.email}</div>}
                            </div>
                            <button className="my-2 px-4 py-3 bg-[#309] text-base text-white mb-4">
                                {t('forgetPassword_now')}
                            </button>
                            <div className="my-2 text-center px-4 mb-4">
                                <span
                                    className="text-base text-sky-500 cursor-pointer"
                                    onClick={() => navigate('/login')}
                                >
                                    Hủy
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading" size="large" />
                </div>
            )}
        </div>
    );
}

export default ForgotPassword;
