import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { useState } from 'react';
import axios from 'axios';

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return false;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
        message.error('Image must be smaller than 2MB!');
        return false;
    }
    return true;
};

const UploadImage = ({ imageLink, setImageLink, disabled }) => {
    const [imageUrl, setImageUrl] = useState(imageLink);
    const [loading, setLoading] = useState(false);

    const handleChangeAvatar = async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            const file = info.file.originFileObj;
            if (file) {
                try {
                    const formData = new FormData();
                    formData.append('file', file);

                    const response = await axios.post('/api/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    if (response.status === 200) {
                        const uploadedImageUrl = response.data.url;
                        setImageUrl(uploadedImageUrl); 
                        setImageLink(uploadedImageUrl); 
                        message.success('Upload successfully');
                    } else {
                        message.error('Upload failed');
                    }
                } catch (error) {
                    message.error('Upload failed');
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Upload
            className="!w-1/3"
            name="avatar"
            listType="picture-card"
            accept=".png,.jpg,.jpeg,.gif,.svg"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChangeAvatar}
            disabled={disabled}
        >
            {imageUrl ? (
                <img
                    src={imageUrl}
                    alt="avatar"
                    style={{ width: '100%' }}
                />
            ) : (
                uploadButton
            )}
        </Upload>
    );
};

export default UploadImage;
