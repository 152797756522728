export const SearchIcon = ({ width = '18', height = '18', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#w3ua3exbra)">
            <path
                d="M13.212 12.354 9.154 8.296c.63-.814.97-1.81.97-2.857a4.66 4.66 0 0 0-1.373-3.314A4.653 4.653 0 0 0 5.437.752a4.66 4.66 0 0 0-3.314 1.373A4.65 4.65 0 0 0 .749 5.44c0 1.252.49 2.43 1.374 3.315a4.65 4.65 0 0 0 3.314 1.373 4.65 4.65 0 0 0 2.854-.969l4.058 4.056a.127.127 0 0 0 .181 0l.682-.68a.13.13 0 0 0 .037-.09.129.129 0 0 0-.037-.09zm-5.3-4.44A3.481 3.481 0 0 1 5.437 8.94a3.481 3.481 0 0 1-2.475-1.025A3.481 3.481 0 0 1 1.937 5.44c0-.934.364-1.814 1.025-2.475A3.481 3.481 0 0 1 5.437 1.94c.934 0 1.814.363 2.475 1.025A3.481 3.481 0 0 1 8.937 5.44c0 .935-.364 1.815-1.025 2.475z"
                fill="#000"
                fillOpacity=".85"
            />
        </g>
        <defs>
            <clipPath id="w3ua3exbra">
                <path fill="#fff" transform="translate(-.001)" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

export const BellIcon = ({ width = '18', height = '18', className, color }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.428 12.57h-.429V6.497A5 5 0 0 0 8.713 1.55V.855a.714.714 0 1 0-1.428 0v.695a5 5 0 0 0-4.286 4.948v6.071H2.57a.57.57 0 0 0-.571.572v.571c0 .079.064.143.143.143h3.857c0 1.104.896 2 2 2s2-.896 2-2h3.857a.143.143 0 0 0 .143-.143v-.571a.57.57 0 0 0-.571-.572zm-5.429 2.142a.857.857 0 0 1-.857-.857h1.714a.857.857 0 0 1-.857.857zM4.285 12.57V6.498c0-.993.385-1.925 1.087-2.627A3.689 3.689 0 0 1 8 2.783c.993 0 1.925.386 2.627 1.088a3.689 3.689 0 0 1 1.087 2.627v6.071H4.285z"
            fill={color}
            fillOpacity=".85"
        />
    </svg>
);

export const WebsiteIcon = ({ width = '16', height = '16', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.822 6.035a1.837 1.837 0 0 1-1.644 0l-1.873-.937a.496.496 0 1 0-.443.887l1.873.937a2.83 2.83 0 0 0 2.53 0l1.873-.937a.496.496 0 1 0-.443-.887l-1.873.937z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.254 5.25a2.83 2.83 0 0 1 2.83-2.83h5.833a2.83 2.83 0 0 1 2.829 2.83v3.208a2.83 2.83 0 0 1-2.83 2.83H4.084a2.83 2.83 0 0 1-2.829-2.83V5.25zm2.83-1.837A1.837 1.837 0 0 0 2.245 5.25v3.208c0 1.015.822 1.838 1.837 1.838h5.834a1.837 1.837 0 0 0 1.837-1.838V5.25a1.838 1.838 0 0 0-1.837-1.837H4.083z"
            fill="#fff"
        />
    </svg>
);

export const PhoneIcon = ({ width = '16', height = '16', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.337 9.917c0 .274.222.495.496.495h2.334a.496.496 0 0 0 0-.991H5.833a.496.496 0 0 0-.496.496z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.587 4.083a2.246 2.246 0 0 1 2.246-2.246h2.334a2.246 2.246 0 0 1 2.245 2.246v5.834a2.246 2.246 0 0 1-2.245 2.245H5.833a2.246 2.246 0 0 1-2.246-2.245V4.083zM5.833 2.83c-.692 0-1.254.562-1.254 1.254v5.834c0 .692.562 1.254 1.254 1.254h2.334c.692 0 1.254-.562 1.254-1.254V4.083c0-.692-.562-1.254-1.254-1.254H5.833z"
            fill="#fff"
        />
    </svg>
);

export const AddressIcon = ({ width = '16', height = '16', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 2.246a3.587 3.587 0 0 0-3.587 3.587c0 1.02.423 2.466 1.126 3.656.35.59.754 1.094 1.185 1.445.43.35.86.529 1.276.529.416 0 .846-.179 1.276-.529.43-.35.835-.854 1.185-1.445.704-1.19 1.127-2.635 1.127-3.656A3.588 3.588 0 0 0 7 2.246zM2.42 5.833a4.58 4.58 0 1 1 9.16 0c0 1.235-.492 2.852-1.266 4.16-.39.66-.865 1.264-1.411 1.71-.547.446-1.192.751-1.903.751-.711 0-1.355-.305-1.903-.751-.546-.446-1.022-1.05-1.411-1.71-.774-1.308-1.265-2.925-1.265-4.16z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 4.58a1.254 1.254 0 1 0 0 2.508 1.254 1.254 0 0 0 0-2.509zM4.754 5.832a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
            fill="#fff"
        />
    </svg>
);

export const LeftArrowIcon = ({ width = '18', height = '18', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m8.36 8.5 2.585-2.585c.39-.391.47-.945.176-1.238-.293-.293-.847-.214-1.237.177L7.056 7.682c-.244.244-.366.55-.353.818-.013.267.11.574.353.818l2.828 2.828c.39.39.944.47 1.237.177.293-.293.214-.847-.176-1.238L8.359 8.5z"
            fill="#000"
            fillOpacity=".85"
        />
    </svg>
);

export const RightArrowIcon = ({ width = '18', height = '18', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.64 8.5 7.056 5.915c-.39-.391-.47-.945-.176-1.238.293-.293.847-.214 1.237.177l2.829 2.828c.243.244.365.55.352.818.013.267-.11.574-.353.818l-2.828 2.828c-.39.39-.944.47-1.237.177-.293-.293-.214-.847.176-1.238L9.641 8.5z"
            fill="#000"
            fillOpacity=".85"
        />
    </svg>
);

export const CustomRectangle1 = ({ width = '344', height = '367', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 344 367"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity=".4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M149.225 29.16c63.188 1.738 133.679-39.297 182.399-3.251 49.538 36.651-65.549 71.855-66.799 129.36-1.214 55.833 107.849 155.135 59.84 190.818-46.778 34.767-114.634 3.032-175.44 3.706-62.316.692-134.212 38.212-179.846.236-45.757-38.079-10.878-105.045-13.237-160.918-2.537-60.097-50.205-132.694-.884-173.552C4.73-25.424 81.837 27.307 149.225 29.16z"
            fill="#F6AE2D"
        />
    </svg>
);

export const CustomRectangle2 = ({ width = '462', height = '590', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 462 590"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity=".4" filter="url(#3tdjyiru8a)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M361.97 72.454c94.09 2.41 199.055-54.501 271.601-4.509 73.765 50.833-97.606 99.658-99.467 179.413-1.807 77.436 160.593 215.16 89.105 264.649-69.654 48.219-170.696 4.205-261.239 5.14-92.792.959-199.848 52.997-267.8.327-68.134-52.812-16.198-145.689-19.71-223.18C70.682 210.943-.298 110.257 73.144 53.59 146.81-3.25 261.626 69.884 361.97 72.454z"
                fill="#F6AE2D"
            />
        </g>
        <defs>
            <filter
                id="3tdjyiru8a"
                x="0"
                y=".012"
                width="692"
                height="589"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="20" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_100686" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_2_100686" result="shape" />
            </filter>
        </defs>
    </svg>
);

export const CommentIcon = ({ width = '71', height = '74', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 71 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#5p600ozqea)">
            <path
                d="M39.826.012c.92 0 1.535.307 1.535.92 0 1.843-3.991 3.992-8.597 7.983-5.526 4.913-11.36 12.895-11.36 21.184 0 11.36 11.667 16.272 11.667 27.632 0 10.132-6.14 15.658-15.35 15.658-9.211 0-17.5-5.526-17.5-22.412C.22 22.424 31.842.012 39.825.012zm24.868 7.675c.921 0 1.535.614 1.535 1.535 0 3.377-9.21 7.983-9.21 18.114 0 10.439 13.509 12.895 13.509 23.334 0 6.14-4.606 12.894-12.895 12.894-9.825 0-15.965-7.675-15.965-18.114C41.36 23.038 59.782 7.687 64.694 7.687z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="5p600ozqea">
                <path fill="#fff" transform="translate(.22 .012)" d="M0 0h70v73.377H0z" />
            </clipPath>
        </defs>
    </svg>
);

export const SearchDictionaryIcon = ({ width = '24', height = '24', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.074 11.137a4.437 4.437 0 1 1-8.874 0 4.437 4.437 0 0 1 8.874 0zm-.734 4.894a6.137 6.137 0 1 1 1.19-1.19.86.86 0 0 1 .091.078l2.48 2.48a.85.85 0 1 1-1.202 1.202l-2.48-2.48a.86.86 0 0 1-.079-.09z"
            fill="#000"
            fillOpacity=".85"
        />
    </svg>
);

export const SoundIcon = ({ width = '20', height = '20', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.312 15.287c3.983-2.257 3.983-7.995 0-10.252a.6.6 0 0 1 .592-1.045c4.795 2.717 4.795 9.625 0 12.342a.6.6 0 0 1-.592-1.045z"
            fill="#000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.03 13.27c2.66-1.22 2.66-5 0-6.219a.6.6 0 0 1 .5-1.09c3.593 1.646 3.593 6.753 0 8.4a.6.6 0 1 1-.5-1.09zM9.723 13.95V6.584a.417.417 0 0 0-.714-.291l-.502.512a4.233 4.233 0 0 1-3.024 1.27c-.957 0-1.733.777-1.733 1.734v.918c0 .957.776 1.733 1.733 1.733 1.138 0 2.227.458 3.024 1.27l.502.513a.417.417 0 0 0 .714-.291zm-4.24-7.125A2.983 2.983 0 0 0 2.5 9.808v.918a2.983 2.983 0 0 0 2.983 2.983c.802 0 1.57.323 2.13.896l.503.512c1.045 1.067 2.857.327 2.857-1.166V6.583c0-1.493-1.812-2.233-2.857-1.166l-.502.512a2.983 2.983 0 0 1-2.131.896z"
            fill="#000"
        />
    </svg>
);

export const TimeLineCheckIcon = ({ width = '20', height = '20', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="10" r="8.5" fill="#fff" stroke="#1890FF" strokeWidth="3" />
        <path
            d="m6 9 3.107 2.663a.5.5 0 0 0 .716-.067L13.5 7"
            stroke="#1890FF"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);

export const ChevronRight = ({ width = '25', height = '24', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.399 6.899a.85.85 0 0 0 0 1.202l4.293 4.293a.15.15 0 0 1 0 .212l-4.293 4.293A.85.85 0 1 0 11.6 18.1l4.293-4.293a1.85 1.85 0 0 0 0-2.616L11.6 6.899a.85.85 0 0 0-1.202 0z"
            fill="#F3F7FF"
        />
    </svg>
);

export const ChevronDown = ({ width = '17', height = '16', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="m8.667 11-5-5 .7-.7 4.3 4.3 4.3-4.3.7.7-5 5z" fill="#161616" />
    </svg>
);

export const SwapIcon = ({ width = '32', height = '32', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.656 22.456a1.133 1.133 0 0 1-1.133-1.133V10.684a1.133 1.133 0 0 1 2.267 0v10.639c0 .625-.508 1.133-1.133 1.133z"
            fill="#4D4D4D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.865 14.143a1.133 1.133 0 0 1 0-1.603L8.93 9.475a2.467 2.467 0 0 1 3.489 0l3.049 3.05a1.133 1.133 0 1 1-1.603 1.602l-3.05-3.05a.2.2 0 0 0-.282 0l-3.065 3.066a1.133 1.133 0 0 1-1.603 0zM21.323 9.544c-.626 0-1.133.507-1.133 1.133v10.639a1.133 1.133 0 0 0 2.266 0V10.677c0-.626-.507-1.133-1.133-1.133z"
            fill="#4D4D4D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.532 17.857a1.133 1.133 0 0 0 0 1.603l3.065 3.065a2.467 2.467 0 0 0 3.488 0l3.05-3.05a1.133 1.133 0 1 0-1.603-1.602l-3.05 3.05a.2.2 0 0 1-.282 0l-3.065-3.066a1.133 1.133 0 0 0-1.603 0z"
            fill="#4D4D4D"
        />
    </svg>
);

export const ClockIcon = ({ width = '22', height = '22', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#nhst5s2s4a)">
            <path fill="#fff" fillOpacity=".01" d="M0 0h22v22H0z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 5.363a5.638 5.638 0 1 0 0 11.275 5.638 5.638 0 0 0 0-11.275zM3.804 11a7.196 7.196 0 1 1 14.392 0 7.196 7.196 0 0 1-14.392 0z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 6.783a.55.55 0 0 1 .55.55V11a.55.55 0 0 1-1.1 0V7.333a.55.55 0 0 1 .55-.55z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.3 11a.55.55 0 0 1-.55.55H11a.55.55 0 1 1 0-1.1h2.75a.55.55 0 0 1 .55.55z"
                fill="#000"
            />
        </g>
        <defs>
            <clipPath id="nhst5s2s4a">
                <path fill="#fff" d="M0 0h22v22H0z" />
            </clipPath>
        </defs>
    </svg>
);

export const PlusIcon = ({ width = '14', height = '14', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 3.004c.274 0 .496.222.496.496v7a.496.496 0 1 1-.992 0v-7c0-.274.222-.496.496-.496z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.004 7c0-.274.222-.496.496-.496h7a.496.496 0 1 1 0 .992h-7A.496.496 0 0 1 3.004 7z"
            fill="#fff"
        />
    </svg>
);

export const LeftIcon = ({ width = '12', height = '12', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.84 2.066V1.031c0-.09-.104-.14-.173-.084L2.629 5.662a.427.427 0 0 0 0 .674l6.038 4.716a.107.107 0 0 0 .173-.085V9.932a.217.217 0 0 0-.082-.169L3.936 6l4.822-3.765a.217.217 0 0 0 .082-.169z"
            fill="#000"
            fillOpacity=".85"
        />
    </svg>
);

export const RightIcon = ({ width = '12', height = '12', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.398 5.663 3.36.947a.107.107 0 0 0-.172.085v1.035c0 .065.03.128.081.169L8.091 6 3.269 9.765a.213.213 0 0 0-.082.169v1.035c0 .09.104.14.173.084l6.038-4.715a.428.428 0 0 0 0-.675z"
            fill="#000"
            fillOpacity=".85"
        />
    </svg>
);

export const StatisticIcon = ({ width = '14', height = '14', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m9.916 5.25-1.61 2.415A.757.757 0 0 1 7 7.583v0a.757.757 0 0 0-1.307-.081l-1.61 2.415"
            stroke="#309"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m9.916 5.25-1.61 2.415A.757.757 0 0 1 7 7.583v0a.757.757 0 0 0-1.307-.081l-1.61 2.415"
            stroke="#fff"
            strokeOpacity=".3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect x="1.75" y="1.75" width="10.5" height="10.5" rx="2" stroke="#309" />
        <rect x="1.75" y="1.75" width="10.5" height="10.5" rx="2" stroke="#fff" strokeOpacity=".3" />
    </svg>
);

export const HeaderIcon = ({ width = '14', height = '14', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.254 4.084A2.246 2.246 0 0 1 3.5 1.838h4.667a2.246 2.246 0 0 1 2.246 2.246v8.079H3.5a2.246 2.246 0 0 1-2.246-2.246V4.084zM3.5 2.83c-.692 0-1.254.561-1.254 1.254v5.833c0 .693.562 1.254 1.254 1.254h5.921V4.084c0-.693-.561-1.254-1.254-1.254H3.5z"
            fill="#309"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.254 4.084A2.246 2.246 0 0 1 3.5 1.838h4.667a2.246 2.246 0 0 1 2.246 2.246v8.079H3.5a2.246 2.246 0 0 1-2.246-2.246V4.084zM3.5 2.83c-.692 0-1.254.561-1.254 1.254v5.833c0 .693.562 1.254 1.254 1.254h5.921V4.084c0-.693-.561-1.254-1.254-1.254H3.5z"
            fill="#fff"
            fillOpacity=".3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.121 8.166c0-.21.17-.379.38-.379h2.916a.38.38 0 0 1 0 .758H3.5a.38.38 0 0 1-.379-.379z"
            fill="#309"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.121 8.166c0-.21.17-.379.38-.379h2.916a.38.38 0 0 1 0 .758H3.5a.38.38 0 0 1-.379-.379z"
            fill="#fff"
            fillOpacity=".3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.121 9.916c0-.21.17-.379.38-.379h4.666a.38.38 0 0 1 0 .758H3.5a.38.38 0 0 1-.379-.379z"
            fill="#309"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.121 9.916c0-.21.17-.379.38-.379h4.666a.38.38 0 0 1 0 .758H3.5a.38.38 0 0 1-.379-.379z"
            fill="#fff"
            fillOpacity=".3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.25 5.338c.274 0 .496.222.496.496v4.083a2.246 2.246 0 0 1-2.246 2.246H6.417a.496.496 0 1 1 0-.992H10.5c.693 0 1.254-.561 1.254-1.254V5.834c0-.274.222-.496.496-.496z"
            fill="#309"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.25 5.338c.274 0 .496.222.496.496v4.083a2.246 2.246 0 0 1-2.246 2.246H6.417a.496.496 0 1 1 0-.992H10.5c.693 0 1.254-.561 1.254-1.254V5.834c0-.274.222-.496.496-.496z"
            fill="#fff"
            fillOpacity=".3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.121 4.668c0-.532.431-.963.963-.963h3.5c.531 0 .962.431.962.963v1.166c0 .532-.43.963-.962.963h-3.5a.963.963 0 0 1-.963-.963V4.668zm.963-.205a.204.204 0 0 0-.205.205v1.166c0 .113.092.204.205.204h3.5a.204.204 0 0 0 .204-.204V4.668a.204.204 0 0 0-.204-.205h-3.5z"
            fill="#309"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.121 4.668c0-.532.431-.963.963-.963h3.5c.531 0 .962.431.962.963v1.166c0 .532-.43.963-.962.963h-3.5a.963.963 0 0 1-.963-.963V4.668zm.963-.205a.204.204 0 0 0-.205.205v1.166c0 .113.092.204.205.204h3.5a.204.204 0 0 0 .204-.204V4.668a.204.204 0 0 0-.204-.205h-3.5z"
            fill="#fff"
            fillOpacity=".3"
        />
    </svg>
);

export const ManagerIcon = ({ width = '14', height = '14', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.708 10.792H4.041a2 2 0 0 1-2-2V4.958m0 0v-.333a2 2 0 0 1 2-2h5.917a2 2 0 0 1 2 2v.183a.15.15 0 0 1-.15.15H2.041z"
            stroke="#309"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.708 10.792H4.041a2 2 0 0 1-2-2V4.958m0 0v-.333a2 2 0 0 1 2-2h5.917a2 2 0 0 1 2 2v.183a.15.15 0 0 1-.15.15H2.041z"
            stroke="#fff"
            strokeOpacity=".3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M2.042 7.875h3.5" stroke="#309" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.042 7.875h3.5" stroke="#fff" strokeOpacity=".3" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M8.15 9.77c-.156-.282-.235-.423-.28-.568a1.5 1.5 0 0 1 0-.902c.045-.146.124-.287.28-.569.167-.277.25-.416.352-.528a1.5 1.5 0 0 1 .782-.451c.148-.033.31-.036.633-.04.323.004.484.007.633.04.3.067.573.224.781.451.103.112.186.25.352.528.157.282.236.423.281.569.093.293.093.608 0 .902-.046.145-.124.286-.281.568-.166.277-.249.416-.352.528a1.5 1.5 0 0 1-.781.451c-.149.033-.31.036-.633.041-.323-.005-.485-.008-.633-.04a1.5 1.5 0 0 1-.782-.452c-.102-.112-.185-.25-.351-.528z"
            stroke="#309"
            strokeLinejoin="round"
        />
        <path
            d="M8.15 9.77c-.156-.282-.235-.423-.28-.568a1.5 1.5 0 0 1 0-.902c.045-.146.124-.287.28-.569.167-.277.25-.416.352-.528a1.5 1.5 0 0 1 .782-.451c.148-.033.31-.036.633-.04.323.004.484.007.633.04.3.067.573.224.781.451.103.112.186.25.352.528.157.282.236.423.281.569.093.293.093.608 0 .902-.046.145-.124.286-.281.568-.166.277-.249.416-.352.528a1.5 1.5 0 0 1-.781.451c-.149.033-.31.036-.633.041-.323-.005-.485-.008-.633-.04a1.5 1.5 0 0 1-.782-.452c-.102-.112-.185-.25-.351-.528z"
            stroke="#fff"
            strokeOpacity=".3"
            strokeLinejoin="round"
        />
        <circle cx="9.917" cy="8.749" fill="#309" r=".583" />
        <circle cx="9.917" cy="8.749" fill="#fff" fillOpacity=".3" r=".583" />
    </svg>
);

export const BankQuestionIcon = ({ width = '14', height = '14', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.722 4.584h1.361a.5.5 0 0 0 0-1H9.17c.025.412.238.773.553 1zm-.677 1.917c.44.213.933.333 1.455.333.339 0 .666-.05.974-.145a.5.5 0 0 1-.391.811H2.917a.5.5 0 0 1 0-1h6.128zM7.346 4.584a3.325 3.325 0 0 1-.178-1H2.916a.5.5 0 0 0 0 1h4.43zm-4.43 4.833a.5.5 0 0 0 0 1h8.167a.5.5 0 1 0 0-1H2.917z"
            fill="#309"
        />
        <circle cx="10.5" cy="3.5" r="1.75" fill="#309" />
    </svg>
);

export const EditIcon = ({ width = '25', height = '24', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.108 5.246A3.46 3.46 0 0 1 18 10.139l-7.986 7.987a2.85 2.85 0 0 1-1.975.834l-1.904.027A1.85 1.85 0 0 1 4.26 17.11l.027-1.904c.01-.742.31-1.45.834-1.975l7.987-7.986zm3.691 1.202a1.76 1.76 0 0 0-2.489 0l-7.986 7.986a1.15 1.15 0 0 0-.337.797l-.027 1.904a.15.15 0 0 0 .152.152l1.904-.027c.3-.004.585-.125.797-.337l7.986-7.986a1.76 1.76 0 0 0 0-2.49z"
            fill="#333"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m15.555 10.77-3.076-3.077 1.202-1.203 3.076 3.077-1.202 1.203zM12.554 18.817c0-.47.38-.85.85-.85h6.998a.85.85 0 1 1 0 1.7h-6.998a.85.85 0 0 1-.85-.85z"
            fill="#333"
        />
    </svg>
);

export const TrashIcon = ({ width = '25', height = '24', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.743 8.189a2.85 2.85 0 0 1 2.82-3.261h7.819a2.85 2.85 0 0 1 2.82 3.261l-1.49 10.222a2.85 2.85 0 0 1-2.821 2.439h-4.837a2.85 2.85 0 0 1-2.82-2.439L5.743 8.19zm2.82-1.561a1.15 1.15 0 0 0-1.138 1.316l1.49 10.222a1.15 1.15 0 0 0 1.139.984h4.837a1.15 1.15 0 0 0 1.138-.984l1.49-10.222a1.15 1.15 0 0 0-1.137-1.316H8.563zM9.845 3c0-.47.38-.85.85-.85h3.555a.85.85 0 0 1 0 1.7h-3.555a.85.85 0 0 1-.85-.85z"
            fill="#333"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.721 10.518a.6.6 0 0 1 .679.51l.889 6.222a.6.6 0 0 1-1.188.17l-.89-6.223a.6.6 0 0 1 .51-.679zM14.165 17.929a.6.6 0 0 1-.509-.68l.889-6.221a.6.6 0 0 1 1.188.17l-.889 6.221a.6.6 0 0 1-.679.51zM12.473 17.934a.6.6 0 0 1-.6-.6V11.11a.6.6 0 1 1 1.2 0v6.223a.6.6 0 0 1-.6.6z"
            fill="#333"
        />
    </svg>
);

export const CloseIcon = ({ width = '20', height = '21', className, fill = '#161616' }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path transform="translate(0 .5)" fill="#fff" style={{ mixBlendMode: 'multiply' }} d="M0 0h20v20H0z" />
        <path
            d="m15 6.375-.875-.875L10 9.625 5.875 5.5 5 6.375 9.125 10.5 5 14.625l.875.875L10 11.375l4.125 4.125.875-.875-4.125-4.125L15 6.375z"
            fill={fill}
        />
    </svg>
);

export const XmarkIcon = ({ width = '17', height = '16', className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 384 512">
        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </svg>
);

export const PostProfileIcon = ({ width = '22', height = '22', className }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <title />
        <g data-name="9-Computer-Communication" id="_9-Computer-Communication">
            <path d="M30,22H2V19H14V17H6V6H9V4H5A1,1,0,0,0,4,5V17H2V3A1,1,0,0,1,3,2h7V0H3A3,3,0,0,0,0,3V23a1,1,0,0,0,1,1H9.85l-.71,5H8a1,1,0,0,0-1,1H5v2H27V30H25a1,1,0,0,0-1-1H22.87l-.71-5H31a1,1,0,0,0,1-1V17H30ZM11.15,29l.71-5h8.27l.71,5Z" />
            <path d="M29,1H15a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h2v4a1,1,0,0,0,1.71.71L23.41,13H29a3,3,0,0,0,3-3V4A3,3,0,0,0,29,1Zm1,9a1,1,0,0,1-1,1H23a1,1,0,0,0-.71.29L19,14.59V12a1,1,0,0,0-1-1H15a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1H29a1,1,0,0,1,1,1Z" />
            <rect height="2" width="2" x="21" y="6" />
            <rect height="2" width="2" x="25" y="6" />
            <rect height="2" width="2" x="17" y="6" />
        </g>
    </svg>
);

export const EditProfileIcon = ({ width = '22', height = '22', className }) => (
    <svg
        enableBackground="new 0 0 32 32"
        className={className}
        width={width}
        height={height}
        id="svg2"
        version="1.1"
        viewBox="0 0 32 32"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnscc="http://creativecommons.org/ns#"
        xmlnsdc="http://purl.org/dc/elements/1.1/"
        xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlnssvg="http://www.w3.org/2000/svg"
    >
        <g id="background">
            <rect fill="none" height="32" width="32" />
        </g>
        <g id="user_x5F_profile_x5F_edit">
            <path d="M12.001,18c2.209,0,4-1.791,4-4s-1.791-4-4-4s-4,1.791-4,4S9.792,18,12.001,18z M24.001,14.059V5.584L18.415,0H0.001v32h24   v-0.06C28.5,31.442,32,27.633,32,23S28.5,14.557,24.001,14.059z M17.999,2.413L21.587,6h-3.588V2.413z M2.001,30V1.998h14v6.001h6   v6.06c-3.477,0.385-6.348,2.75-7.477,5.941c-3.562,0-8.523,0-8.523,0s-2,0-2,2c0,1,0,6,0,6h11.518c0.506,0.756,1.125,1.43,1.832,2   H2.001z M23.001,29.999c-3.865-0.008-6.994-3.135-7-6.999c0.006-3.865,3.135-6.994,7-7c3.864,0.006,6.991,3.135,6.999,7   C29.992,26.864,26.865,29.991,23.001,29.999z" />
            <polygon points="22,27 19,27 19,24  " />
            <rect
                height="4.243"
                transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 56.5269 20.5858)"
                width="7.071"
                x="20.464"
                y="19.879"
            />
        </g>
    </svg>
);

export const ChangePassProfileIcon = ({ width = '22', height = '22', className }) => (
    <svg viewBox="0 0 512 512" className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <g id="Change_password">
            <path d="M464.4326,147.54a9.8985,9.8985,0,0,0-17.56,9.1406,214.2638,214.2638,0,0,1-38.7686,251.42c-83.8564,83.8476-220.3154,83.874-304.207-.0088a9.8957,9.8957,0,0,0-16.8926,7.0049v56.9a9.8965,9.8965,0,0,0,19.793,0v-34.55A234.9509,234.9509,0,0,0,464.4326,147.54Z" />
            <path d="M103.8965,103.9022c83.8828-83.874,220.3418-83.8652,304.207-.0088a9.8906,9.8906,0,0,0,16.8926-6.9961v-56.9a9.8965,9.8965,0,0,0-19.793,0v34.55C313.0234-1.3556,176.0547,3.7509,89.9043,89.9012A233.9561,233.9561,0,0,0,47.5674,364.454a9.8985,9.8985,0,0,0,17.56-9.1406A214.2485,214.2485,0,0,1,103.8965,103.9022Z" />
            <path d="M126.4009,254.5555v109.44a27.08,27.08,0,0,0,27,27H358.5991a27.077,27.077,0,0,0,27-27v-109.44a27.0777,27.0777,0,0,0-27-27H153.4009A27.0805,27.0805,0,0,0,126.4009,254.5555ZM328,288.13a21.1465,21.1465,0,1,1-21.1465,21.1464A21.1667,21.1667,0,0,1,328,288.13Zm-72,0a21.1465,21.1465,0,1,1-21.1465,21.1464A21.1667,21.1667,0,0,1,256,288.13Zm-72,0a21.1465,21.1465,0,1,1-21.1465,21.1464A21.1667,21.1667,0,0,1,184,288.13Z" />
            <path d="M343.6533,207.756V171.7538a87.6533,87.6533,0,0,0-175.3066,0V207.756H188.14V171.7538a67.86,67.86,0,0,1,135.7208,0V207.756Z" />
        </g>
    </svg>
);

export const DetailIcon = ({ width = '24', height = '24', className }) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        className={className}
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 5H21" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="M11 9H16" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <rect
            height="4"
            rx="1"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            width="4"
            x="3"
            y="5"
        />
        <path d="M11 15H21" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="M11 19H16" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <rect
            height="4"
            rx="1"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            width="4"
            x="3"
            y="15"
        />
    </svg>
);

export const SendIcon = ({ width = '30', height = '30', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M7.39999 6.32003L15.89 3.49003C19.7 2.22003 21.77 4.30003 20.51 8.11003L17.68 16.6C15.78 22.31 12.66 22.31 10.76 16.6L9.91999 14.08L7.39999 13.24C1.68999 11.34 1.68999 8.23003 7.39999 6.32003Z"
        ></path>
        <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" d="M10.11 13.6501L13.69 10.0601"></path>
    </svg>
);

export const CommentPostIcon = ({ width = '20', height = '22', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssketch="http://www.bohemiancoding.com/sketch/ns"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icon-Set" transform="translate(-308.000000, -255.000000)" fill="#000000">
                <path
                    d="M327.494,279.633 L324,284 L320.506,279.633 C314.464,278.355 309.992,273.863 309.992,268.501 C309.992,262.146 316.264,256.994 324,256.994 C331.736,256.994 338.008,262.146 338.008,268.501 C338.008,273.863 333.536,278.355 327.494,279.633 L327.494,279.633 Z M324,255 C315.163,255 308,261.143 308,268.72 C308,274.969 312.877,280.232 319.542,281.889 L324,287.001 L328.459,281.889 C335.123,280.232 340,274.969 340,268.72 C340,261.143 332.837,255 324,255 L324,255 Z"
                    id="comment-4"
                ></path>
            </g>
        </g>
    </svg>
);

export const ThreeDots = ({ width = '20', height = '22', className }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
        <rect fill="none" height="256" width="256" />
        <path d="M140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm52-12a12,12,0,1,0,12,12A12,12,0,0,0,192,116ZM64,116a12,12,0,1,0,12,12A12,12,0,0,0,64,116Z" />
    </svg>
);

export const LogoutIcon = ({ width = '30', height = '30', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 4H19C20.1046 4 21 4.89543 21 6V7M16 20H19C20.1046 20 21 19.1046 21 18V17M4.4253 19.4276L10.4253 21.2276C11.7085 21.6126 13 20.6517 13 19.3119V4.68806C13 3.34834 11.7085 2.38744 10.4253 2.77241L4.4253 4.57241C3.57934 4.8262 3 5.60484 3 6.48806V17.5119C3 18.3952 3.57934 19.1738 4.4253 19.4276Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
        <path d="M9.001 12H9" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path
            d="M16 12H21M21 12L19 10M21 12L19 14"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);

export const HomeOutlined = ({ width = '30', height = '30', className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="home"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 00-44.4 0L77.5 505a63.9 63.9 0 00-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0018.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path>
    </svg>
);

export const Bar3Icon = ({ width = '25', height = '25', className }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.1 128H416C433.7 128 448 113.7 448 96S433.7 64 416 64H31.1C14.33 64 0 78.33 0 95.1S14.33 128 31.1 128zM480 224H95.1C78.33 224 64 238.3 64 256s14.33 32 31.1 32h384C497.7 288 512 273.7 512 256S497.7 224 480 224zM416 384H31.1C14.33 384 0 398.3 0 415.1S14.33 448 31.1 448H416C433.7 448 448 433.7 448 416S433.7 384 416 384z" />
    </svg>
);
