import axios from '../axios';

//Admin
export const listPack = (axiosPrivate) => {
    return axiosPrivate
        .get('/pack/listPack')
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
