// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-40px {
  font-size: 40px;
  font-family: var(--font-be-vietnam);
}

.slick-slider .slick-prev::before {
  position: absolute;
  left: 0.75rem;
}

@media (min-width: 1024px) {

  .slick-slider .slick-prev::before {
    left: -10px;
  }
}
.slick-slider .slick-next::before {
  position: absolute;
  right: 0.75rem;
}
@media (min-width: 1024px) {

  .slick-slider .slick-next::before {
    right: 0px;
  }
}
.slick-slider .slick-next::before,
.slick-slider .slick-prev::before {
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: #c3c3c3;
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/Fulltest/Fulltest.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mCAAA;AACJ;;AAIQ;EAAA,kBAAA;EAAA;AAAA;;AAAA;;EAAA;IAAA;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA;AAAA;AAAA;;EAAA;IAAA;EAAA;AAAA;AAKI;;EAAA,mBAAA;EAAA,oBAAA;EACA;AADA","sourcesContent":[".text-40px {\n    font-size: 40px;\n    font-family: var(--font-be-vietnam);\n}\n\n.slick-slider {\n    .slick-prev::before {\n        @apply lg:-left-[10px] left-3 absolute;\n    }\n    .slick-next::before {\n        @apply lg:right-0 right-3 absolute;\n    }\n    .slick-next,\n    .slick-prev {\n        &::before {\n            @apply text-3xl;\n            color: #c3c3c3;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
