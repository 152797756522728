import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '~/api/axios';

const Agreement = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [results, setResults] = useState([]);

    const searchWord = useCallback(() => {
        const currentWord = new URLSearchParams(location.search).get('search');
        return currentWord === null ? props.defaultKeyword : currentWord;
    }, [location.search, props.defaultKeyword]);

    useEffect(() => {
        const handleDictionaryResponse = async () => {
            try {
                const response = await axios.get(`/dictionary/search/${searchWord()}`);
                setResults(response.data.responseData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        handleDictionaryResponse();
    }, [searchWord]);

    const dataMeanings = results[0]?.meanings || [];

    const listData = () => {
        const data = [];
        for (let index = 0; index < dataMeanings.length; index++) {
            if (dataMeanings[index]?.synonyms && dataMeanings[index]?.synonyms.length > 0) {
                for (let i = 0; i < dataMeanings[index]?.synonyms.length; i++) {
                    data.push(dataMeanings[index]?.synonyms[i]);
                }
            }
            if (dataMeanings[index]?.antonyms && dataMeanings[index]?.antonyms.length > 0) {
                for (let i = 0; i < dataMeanings[index]?.antonyms.length; i++) {
                    data.push(dataMeanings[index]?.antonyms[i]);
                }
            }
        }

        const newData = [];
        for (var i = 0; i < data.length; i++) {
            if (newData.indexOf(data[i]) === -1) {
                newData.push(data[i]);
            }
        }
        return newData;
    };

    const listAgreement = listData().map((element, index) =>
        index < 5 ? (
            <button
                key={index}
                className="font-poppinsRegular lg:text-xl text-sm text-hotlink-gray-5b5b5b leading-[1.57] hover:text-hotlink-black-085 cursor-pointer"
                onClick={() => navigate('/dictionary?search=' + element)}
            >
                {element}
            </button>
        ) : null,
    );

    return <div className="flex flex-row justify-start items-start gap-6">{listAgreement}</div>;
};

export default Agreement;
