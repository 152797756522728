import { axiosPrivate } from '../api/axios';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import { useNavigate } from 'react-router-dom';

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const navigate = useNavigate();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            async (config) => {
                const newConfig = { ...config };

                const accessToken = localStorage.getItem('accessToken');

                if (!accessToken) {
                    const newAccessToken = await refresh();
                    newConfig.headers['Authorization'] = `Bearer ${newAccessToken}`;
                } else {
                    newConfig.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                return newConfig;
            },
            (error) => Promise.reject(error),
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                if (
                    error?.response?.data.violations === '401' &&
                    prevRequest &&
                    !prevRequest.sent &&
                    localStorage.getItem('refreshToken') !== null
                ) {
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    prevRequest.sent = true;
                    return axiosPrivate(prevRequest);
                }
                if (error?.response?.data.violations === '401') {
                    navigate('/login');
                }
                return Promise.reject(error);
            },
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [refresh, navigate]);

    return axiosPrivate;
};

export default useAxiosPrivate;
