// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow {
  box-shadow: 0 -1px 24px 0 rgba(0, 0, 0, 0.2) !important;
}

.header-dropdown .ant-dropdown-menu {
  border-radius: 0px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.search-icon {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 2.25rem;
  foo: bar;
}

.bell-icon {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  width: 48px;
  foo: bar;
}

.avatar-icon {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 88px;
  flex-direction: row;
  foo: bar;
}

.logo-link {
  display: flex;
}

@media (min-width: 1280px) {

  .logo-link {
    margin-top: 38%;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/components/Header/Header.scss"],"names":[],"mappings":"AAIA;EACI,uDAAA;AAHJ;;AAOI;EAAA,kBAAA;EAAA,oBAAA;EAAA;AAAA;;AARA;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,2BAAA;EAAA,qBAAA;EAAA,sBAAA;EAcA,cAAA;EACA;AAfA;;AAAA;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,2BAAA;EAAA,qBAAA;EAAA,sBAAA;EAoBA,kBAAA;EAAA,WAAA;EACA;AArBA;;AAAA;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,2BAAA;EAAA,qBAAA;EAAA,sBAAA;EA0BA,WAAA;EAAA,mBAAA;EACA;AA3BA;;AAgCA;EAAA;AAAA;;AAAA;;EAAA;IAAA;EAAA;AAAA","sourcesContent":["@mixin icon_mixin {\n    @apply flex flex-col justify-center items-center px-3 cursor-pointer bg-hotlink-white-ffffff00;\n}\n\n.shadow {\n    box-shadow: 0 -1px 24px 0 rgba(0, 0, 0, 0.2) !important;\n}\n\n.header-dropdown .ant-dropdown-menu {\n    @apply rounded-none py-3;\n}\n\n// CSS toolbar\n.search-icon {\n    @include icon_mixin();\n    @apply w-9;\n    foo: bar;\n}\n\n.bell-icon {\n    @include icon_mixin();\n    @apply w-[48px] relative;\n    foo: bar;\n}\n\n.avatar-icon {\n    @include icon_mixin();\n    @apply w-[88px] flex-row;\n    foo: bar;\n}\n\n.logo-link {\n    // margin: 35% 25.2% 40px 25.2%;\n    @apply xl:mt-[38%] flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
