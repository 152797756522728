// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-shadow {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
}

.text-12px {
  font-size: 12px;
  font-family: var(--font-be-vietnam);
}

.text-13px {
  font-size: 13px;
  font-family: var(--font-be-vietnam);
}

.text-20px {
  font-size: 20px;
  font-family: var(--font-be-vietnam);
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/News/News.scss"],"names":[],"mappings":"AAAA;EACI,4CAAA;AACJ;;AAEA;EACI,eAAA;EACA,mCAAA;AACJ;;AAEA;EACI,eAAA;EACA,mCAAA;AACJ;;AAEA;EACI,eAAA;EACA,mCAAA;AACJ","sourcesContent":[".box-shadow {\n    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);\n}\n\n.text-12px {\n    font-size: 12px;\n    font-family: var(--font-be-vietnam);\n}\n\n.text-13px {\n    font-size: 13px;\n    font-family: var(--font-be-vietnam);\n}\n\n.text-20px {\n    font-size: 20px;\n    font-family: var(--font-be-vietnam);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
