import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';
import { useLocation, useParams } from 'react-router-dom';

function DefaultLayout({ children }) {
    const { pathname } = useLocation();
    const { id } = useParams();

    return (
        <div className="overflow-hidden">
            {pathname !== `/test/toeic-test/${id}` && <Header />}
            <div className="lg:mt-28 mt-24">
                <div className="content">{children}</div>
            </div>
            {pathname !== `/test/toeic-test/${id}` && <Footer />}
        </div>
    );
}

export default DefaultLayout;
