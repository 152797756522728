import './NewsItem.scss';

function NewsItem(props) {
    const { t, news } = props;

    return (
        <div className="lg:h-[30%] rounded-lg flex box-shadow bg-[#fff] mb-4">
            <div className="lg:w-[30%] w-2/5">
                <img className="rounded-l-lg h-full" src={news.img} alt="" />
            </div>
            <div className="w-[70%] px-4 py-4">
                <div className="flex justify-between mb-2">
                    <span className="lg:text-xs text-[10px] font-medium">{t('contest')}</span>
                    <span className="lg:text-xs text-[10px] font-medium">{news.time}</span>
                </div>
                <h5 className="lg:text-[15px] text-xs font-medium mb-2 text-left">{news.title}</h5>
                <p className="text-justify text-[10px]">{news.content}</p>
            </div>
        </div>
    );
}

export default NewsItem;
