import ModalChangePass from './ModalChangePass';

import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'antd';

import { validPassword } from '../../../utils/regex.js';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';

function ChangePass(props) {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    const [resetPass, setResetPass] = useState({
        oldPass: '',
        newPass: '',
        confirmPass: '',
        err: {},
    });

    const user = useSelector((state) => state.user.user);

    const validate = () => {
        if (resetPass.oldPass === '') {
            dispatch(setStatusMessage('Reset pass fail'));
            dispatch(setErrMessage('Please enter old pass'));
            return false;
        }
        if (!validPassword.test(resetPass.newPass)) {
            dispatch(setStatusMessage('Reset pass fail'));
            dispatch(
                setErrMessage(
                    'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character and no spaces',
                ),
            );
            return false;
        }
        if (resetPass.newPass !== resetPass.confirmPass) {
            dispatch(setStatusMessage('Reset pass fail'));
            dispatch(setErrMessage('Confirm password and password are not the same'));
            return false;
        }
        return true;
    };

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [resetPass]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            setOpenModal(true);
        }
    };

    return (
        <div className="flex flex-col gap-3 lg:mt-16 mt-4 h-[650px]">
            {openModal ? (
                <ModalChangePass
                    resetPass={resetPass}
                    closeModal={() => setOpenModal(false)}
                    setResetPass={setResetPass}
                />
            ) : null}
            {user ? (
                <form
                    className=" w-full bg-white lg:p-12 py-12 px-4 flex flex-col gap-6 font-BeVietNamRegular text-base rounded-xl shadow-[0_4px_10px_0_rgba(0, 0, 0, 0.1)] mb-4"
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <div className="flex flex-col gap-2">
                        <span>
                            Mật khẩu cũ <span className="text-hotlink-red-d73d32">(*)</span>:
                        </span>
                        <Input.Password
                            placeholder="Nhập mật khẩu cũ"
                            className="input-pass w-full h-full pl-4 py-3 font-interSemi text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            value={resetPass.oldPass}
                            onChange={(e) => {
                                setResetPass((prevPass) => ({
                                    ...prevPass,
                                    oldPass: e.target.value,
                                }));
                            }}
                        ></Input.Password>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span>
                            Mật khẩu mới <span className="text-hotlink-red-d73d32">(*)</span>:
                        </span>
                        <Input.Password
                            placeholder="Nhập mật khẩu mới"
                            className="input-pass w-full h-full pl-4 py-3 font-interSemi text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            value={resetPass.newPass}
                            onChange={(e) => {
                                setResetPass((prevPass) => ({
                                    ...prevPass,
                                    newPass: e.target.value,
                                }));
                            }}
                        ></Input.Password>
                    </div>

                    <div className="flex flex-col gap-2">
                        <span>
                            Nhập lại mật khẩu mới <span className="text-hotlink-red-d73d32">(*)</span>:
                        </span>
                        <Input.Password
                            placeholder="Nhập lại mật khẩu mới"
                            className="input-pass w-full h-full pl-4 py-3 font-interSemi text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            value={resetPass.confirmPass}
                            onChange={(e) => {
                                setResetPass((prevPass) => ({
                                    ...prevPass,
                                    confirmPass: e.target.value,
                                }));
                            }}
                        ></Input.Password>
                    </div>

                    <button
                        className={`mt-4 text-lg bg-hotlink-yellow-FBCA1F font-interSemi py-[0.6em] px-[1.3em] font-black border-[3px] border-solid border-black rounded-[0.4em] shadow-[0.1em_0.1em]
                         hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                         active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]`}
                        type="submit"
                    >
                        Cập nhật mật khẩu
                    </button>
                </form>
            ) : null}
        </div>
    );
}
export default ChangePass;
