import { useState, useEffect } from 'react';

import ShowPost from './ShowPost';
import CreatePost from './CreatePost';
import PostModal from './PostModal';


function Post() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [listPostUser, setListPostUser] = useState([]);

    useEffect(() => {
        if (isOpenModal) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isOpenModal]);

    const closeModal = () => {
        setIsOpenModal(false);
    };

    const openModal = () => {
        setIsOpenModal(true);
    };

    return (
        <div className="lg:w-[90%] w-full flex flex-col gap-5 mt-3">
            <CreatePost isOpenModal={isOpenModal} openModal={openModal} />
            <ShowPost listPostUser={listPostUser} setListPostUser={setListPostUser}/>
            {isOpenModal ? <PostModal closeModal={closeModal} setListPostUser={setListPostUser} /> : null}
        </div>
    );
}
export default Post;
