import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    errMsg: '',
    statusMsg: '',
};

const errMessageSlice = createSlice({
    name: 'errMessage',
    initialState,
    reducers: {
        setErrMessage: (state, action) => {
            state.errMsg = action.payload;
        },
        setStatusMessage: (state, action) => {
            state.statusMsg = action.payload;
        },
    },
});

export const { setErrMessage, setStatusMessage } = errMessageSlice.actions;

export default errMessageSlice.reducer;