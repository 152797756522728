import React from 'react';
import './AudioProgress.scss';

function AudioProgress(props) {
    const { duration, currentProgress, buffered, ...rest } = props;

    const progressBarWidth = isNaN(currentProgress / duration) ? 0 : currentProgress / duration;
    const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;

    const progressStyles = {
        '--progress-width': progressBarWidth,
        '--buffered-width': bufferedWidth,
    };

    return (
        <div className="relative h-2 w-[350px] group rounded-[100px]">
            <input
                type="range"
                name="progress"
                className={`progress-bar absolute appearance-none  inset-0 w-full m-0 h-full cursor-pointer group-hover:h-2 
                    transition-all accent-hotlink-purple-9999ff hover:accent-hotlink-purple-9999ff before:absolute 
                    before:rounded-[100px] before:inset-0 before:h-full before:w-full before:bg-hotlink-purple-9999ff 
                    before:origin-left after:absolute after:h-full after:rounded-[100px] after:w-full 
                    after:bg-hotlink-purple-9999ff/50 bg-hotlink-white-f5f5f5`}
                style={progressStyles}
                min={0}
                max={duration}
                value={currentProgress}
                {...rest}
            />
        </div>
    );
}

export default AudioProgress;
