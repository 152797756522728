import axios from '../axios';

export const addCommentToComment = (axiosPrivate, commentId, payload, config) => {
    return axiosPrivate
        .post(`/comment/${commentId}/addCommentToComment`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addCommentToTopic = (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .post(`/comment/${topicId}/addCommentToTopic`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addCommentToPost = (axiosPrivate, postId, payload, config) => {
    return axiosPrivate
        .post(`/comment/${postId}/addCommentToPost`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const updateComment = (axiosPrivate, commentId, payload, config) => {
    return axiosPrivate
        .patch(`/comment/${commentId}/updateComment`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getAllCommentChildToComment = async (commentId, payload, config) => {
    try {
        const response = await axios.get(`/comment/${commentId}/getAllComment`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteComment = (axiosPrivate, commentId, payload, config) => {
    return axiosPrivate
        .delete(`/comment/${commentId}/deleteComment`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
