import { CommentIcon } from '~/components/Icons';
import Avatar from '~/components/Avatar';

const SingleComment = (props) => {
    return (
        <div
            className={`lg:w-[510px] w-[90%] lg:h-[497px] h-[550px] pt-[37px] pr-[3.8px] pb-[33.6px] pl-[29.2px] rounded-[20px] shadow-shadow-comment ${
                props.choice === '1' ? 'bg-hotlink-purple-7158d7' : 'bg-hotlink-yellow-f6ae2d'
            }  flex flex-col gap-6`}
        >
            <CommentIcon />
            <div className="self-stretch font-poppinsRegular text-lg leading-normal tracking-[0.36px] text-left text-white">
                {props.item.content}
            </div>

            <div className="flex flex-row justify-start items-center gap-6">
                <Avatar
                    className="h-[110px] w-[110px] rounded-1/2 items-stretch"
                    src={props.item ? props.item.avatar : ''}
                    alt="user"
                />
                <div className="flex flex-col justify-center items-start gap-1 ">
                    <span className="font-poppinsMedium text-lg font-medium leading-[1.33] text-white uppercase">
                        {props.item.name}
                    </span>
                    <span className="font-poppinsRegular text-base font-medium leading-[1.38] text-white ">
                        {props.item.description}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SingleComment;
