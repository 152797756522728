import React, { useState, useEffect } from 'react';
import { ChevronDown, XmarkIcon, CloseIcon } from '~/components/Icons';

import { Input, Upload, message, Collapse, Select, DatePicker, InputNumber, notification, Spin, Modal } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { listPack } from '~/api/packApi';
import { listPart } from '~/api/partApi';

import QuestionPart1 from '../AddQuestion/QuestionPart1';
import QuestionPart2 from '../AddQuestion/QuestionPart2';
import QuestionPart34 from '../AddQuestion/QuestionPart34';
import QuestionPart5 from '../AddQuestion/QuestionPart5';
import QuestionPart67 from '../AddQuestion/QuestionPart67';
import { createTopic, addListQuestionToTopic, deleteTopic } from '~/api/topicApi';

import UploadImage from './UploadImage/UploadImage';  
import UploadAudio from './UploadAudio/UploadAudio';   
import UploadExcel from './UploadExcel/UploadExcel';

const { RangePicker } = DatePicker;

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
//Test 
const AddExamModal = (props) => {
    const [api, contextHolder] = notification.useNotification();
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [numberQuestionPart, setNumberQuestionPart] = useState([]);

    const [menuPacks, setMenuPacks] = useState([]);
    const [menuPart, setMenuPart] = useState([]);

    const [typeExam, setTypeExam] = useState('Full');
    const [listPartExam, setListPartExam] = useState([]);

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);
    const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
    const [imageLink, setImageLink] = useState('');
    const [audioLink, setAudioLink] = useState('');
    const [ExcelLink, setExcelLink] = useState('');

    const [resetUpload, setResetUpload] = useState(false);

    const showImageModal = () => setIsImageModalOpen(true);
    const showAudioModal = () => setIsAudioModalOpen(true);
    const showExcelModal = () => setIsExcelModalOpen(true);
    

    const closeModal = () => {
        setResetUpload(true);
        setIsImageModalOpen(false);
        setIsAudioModalOpen(false);
        setIsExcelModalOpen(false);
    };

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    const [contentTopic, setContentTopic] = useState({
        topicName: '',
        topicImage: '',
        topicDescription: '',
        topicPack: '',
        topicType: 'Full',
        workTime: '',
        numberQuestion: '',
        startTime: '',
        endTime: '',
        listPart: [],
        err: {},
    });

    const [listQuestion, setListQuestion] = useState({});

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0].originFileObj;
            if (file) {
                setContentTopic((prevContentTopic) => ({
                    ...prevContentTopic,
                    topicImage: file,
                }));
                const reader = new FileReader();
                reader.onload = (info) => {
                    setImageUrl(info.target.result);
                };
                reader.readAsDataURL(file);
            }
        } else {
            setImageUrl(null);
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const showQuestionPart = (item) => {
        const existingPartIndex = numberQuestionPart.findIndex((itemNumber) => itemNumber.partId === item.value);

        if (existingPartIndex !== -1) {
            const { partId, partName, count } = numberQuestionPart[existingPartIndex];

            switch (partName) {
                case 'PART 1':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart1
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );
                case 'PART 2':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart2
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );
                case 'PART 3':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart34
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );
                case 'PART 4':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart34
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );

                case 'PART 5':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart5
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );

                case 'PART 6':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart67
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );
                case 'PART 7':
                    return (
                        <div className="flex flex-col gap-4 w-full">
                            {Array.from({ length: count }, (_, i) => (
                                <QuestionPart67
                                    key={i}
                                    number={i}
                                    partId={partId}
                                    setListQuestion={(contentQuestion) => {
                                        setListQuestion((prevListQuestion) => {
                                            const updatedPart = [...(prevListQuestion[partId] || [])];
                                            while (updatedPart.length > count) {
                                                updatedPart.pop();
                                            }
                                            updatedPart[i] = contentQuestion;

                                            return {
                                                ...prevListQuestion,
                                                [partId]: updatedPart,
                                            };
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    );
                default:
                    return null;
            }
        }
    };

    const getListPack = async () => {
        try {
            const responsePack = await listPack(axiosPrivate);
            if (responsePack?.status === 'success') {
                const items = responsePack.responseData.map((item, i) => ({
                    value: item.packId,
                    label: item.packName,
                }));
                setMenuPacks(items);
            }

            const responsePart = await listPart();
            if (responsePart?.status === 'success') {
                const items = responsePart.responseData.map((item, i) => ({
                    value: item.partId,
                    label: item.partName.slice(0, 6),
                }));
                setMenuPart(items);
                setListPartExam(items);
                setContentTopic((prevContentTopic) => ({
                    ...prevContentTopic,
                    listPart: items.map((item) => item.value),
                }));

                const newListQuestion = { ...listQuestion };
                items.forEach((item) => {
                    newListQuestion[item.value] = [];
                });
                setListQuestion(newListQuestion);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getListPack();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [contentTopic]);
    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
    }, [errMsg, statusMsg]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (contentTopic.startTime === null || contentTopic.endTime === null) {
                setErrMsg('fail');
                setStatusMsg('Vui lòng nhập thời gian bắt đầu và kết thúc');
                return;
            }
            setIsLoading(true);
            const formData = new FormData();
            formData.append('topicName', contentTopic.topicName);
            formData.append('topicDescription', contentTopic.topicDescription);
            formData.append('topicType', contentTopic.topicType);
            formData.append('topicPack', contentTopic.topicPack);
            formData.append('workTime', contentTopic.workTime);
            formData.append('numberQuestion', contentTopic.numberQuestion);
            formData.append('startTime', contentTopic.startTime);
            formData.append('endTime', contentTopic.endTime);
            if (contentTopic.topicImage !== '') {
                formData.append('topicImage', contentTopic.topicImage);
            }
            formData.append('listPart', contentTopic.listPart);

            const response = await createTopic(axiosPrivate, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response?.violations === 401) {
                setErrMsg('Unauthorized');
                setStatusMsg('Create topic fail');
            } else if (response?.status === 'fail') {
                setErrMsg(response?.message);
                setStatusMsg('Create topic fail');
            } else {
                try {
                    const mergedArray = Object.values(listQuestion).reduce(
                        (accumulator, currentArray) => accumulator.concat(currentArray),
                        [],
                    );

                    const formData = new FormData();

                    mergedArray.forEach((createQuestionDTO, index) => {
                        formData.append(
                            `listQuestion[${index}].questionContent`,
                            createQuestionDTO.questionContent ? createQuestionDTO.questionContent : '',
                        );
                        formData.append(
                            `listQuestion[${index}].questionScore`,
                            createQuestionDTO.questionScore ? createQuestionDTO.questionScore : '',
                        );
                        if (createQuestionDTO.contentImage !== '' && createQuestionDTO.contentImage) {
                            formData.append(`listQuestion[${index}].contentImage`, createQuestionDTO.contentImage);
                        }
                        if (createQuestionDTO.contentAudio !== '' && createQuestionDTO.contentAudio) {
                            formData.append(`listQuestion[${index}].contentAudio`, createQuestionDTO.contentAudio);
                        }

                        formData.append(`listQuestion[${index}].partId`, createQuestionDTO.partId);

                        if (createQuestionDTO.listAnswer) {
                            createQuestionDTO.listAnswer.forEach((listAnswerItem, answerIndex) => {
                                formData.append(
                                    `listQuestion[${index}].listAnswer[${answerIndex}].contentAnswer`,
                                    listAnswerItem.contentAnswer,
                                );
                                formData.append(
                                    `listQuestion[${index}].listAnswer[${answerIndex}].correctAnswer`,
                                    listAnswerItem.correctAnswer,
                                );
                            });
                        }

                        if (createQuestionDTO.listQuestionChild) {
                            createQuestionDTO.listQuestionChild.forEach((listChildItem, questionIndex) => {
                                formData.append(
                                    `listQuestion[${index}].listQuestionChild[${questionIndex}].questionContent`,
                                    listChildItem.questionContent,
                                );
                                formData.append(
                                    `listQuestion[${index}].listQuestionChild[${questionIndex}].questionScore`,
                                    listChildItem.questionScore,
                                );
                                formData.append(
                                    `listQuestion[${index}].listQuestionChild[${questionIndex}].partId`,
                                    listChildItem.partId,
                                );

                                listChildItem.listAnswer.forEach((answerItem, answerChildIndex) => {
                                    formData.append(
                                        `listQuestion[${index}].listQuestionChild[${questionIndex}].listAnswer[${answerChildIndex}].contentAnswer`,
                                        answerItem.contentAnswer,
                                    );
                                    formData.append(
                                        `listQuestion[${index}].listQuestionChild[${questionIndex}].listAnswer[${answerChildIndex}].correctAnswer`,
                                        answerItem.correctAnswer,
                                    );
                                });
                            });
                        }
                    });

                    const responseQuestion = await addListQuestionToTopic(
                        axiosPrivate,
                        response.responseData.topicId,
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        },
                    );

                    if (responseQuestion.status === 'success') {
                        setErrMsg('Successful');
                        setStatusMsg('Create topic successful');
                        props.closeModal();
                    } else {
                        const responseDelete = await deleteTopic(axiosPrivate, response.responseData.topicId);
                        setErrMsg('Fail');
                        setStatusMsg('Create topic fail');
                    }
                } catch (err) {
                    console.log(err);
                    const responseDelete = await deleteTopic(axiosPrivate, response.responseData.topicId);
                    setErrMsg('Create topic Failed');
                    setStatusMsg('Create topic fail');
                }
            }
        } catch (err) {
            setErrMsg('Create topic Failed');
            setStatusMsg('Create topic fail');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="fixed z-[101] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center">
            {contextHolder}
            <div className="w-2/3 max-h-[80%] flex flex-col bg-white pb-8 rounded-xl">
                <header className="flex flex-row justify-between items-center bg-hotlink-blue-309 pt-8 pb-4 px-8 rounded-t-xl">
                    <span className="text-xl font-interSemi text-hotlink-white-f7f3ff after:max-w-full after:w-1/2 after:h-[3px] after:block after:bg-hotlink-red-ff4d4f">
                        Thêm đề thi
                    </span>
                    <button
                        onClick={() => {
                            props.closeModal();
                        }}
                    >
                        <CloseIcon width="30" height="32" className="cursor-pointer" fill="#ffffff" />
                    </button>
                </header>

                <form className="flex flex-col gap-6 px-8 overflow-y-auto pt-4" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-col items-center justify-start gap-4">
                        {/*Insert topic Name */}
                        <div className="flex flex-row items-start justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Tên đề thi:</span>
                            <Input
                                className="font-BeVietNamRegular text-base w-1/2"
                                placeholder="Tên đề thi"
                                onChange={(e) => {
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        topicName: e.target.value,
                                        err: {
                                            ...prevContentTopic.err,
                                            topicName: e === '' ? 'Please enter topic name' : null,
                                        },
                                    }));
                                }}
                            />
                        </div>

                        {/*Insert topic Image */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Ảnh đại diện:</span>
                            <Upload
                                className="!w-1/2"
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>

                        {/*Insert topic Description */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Mô tả:</span>
                            <Input
                                className="font-BeVietNamRegular text-base w-1/2"
                                placeholder="Mô tả đề thi"
                                onChange={(e) => {
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        topicDescription: e.target.value,
                                        err: {
                                            ...prevContentTopic.err,
                                            topicDescription: e === '' ? 'Please enter topic description' : null,
                                        },
                                    }));
                                }}
                            />
                        </div>

                        {/*Insert topic type */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Loại đề thi:</span>
                            <Select
                                suffixIcon={<ChevronDown />}
                                className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                                options={[
                                    {
                                        value: 'Full',
                                        label: 'Full',
                                    },
                                    {
                                        value: 'Part',
                                        label: 'Part',
                                    },
                                    {
                                        value: 'Listening',
                                        label: 'Listening',
                                    },
                                    {
                                        value: 'Reading',
                                        label: 'Reading',
                                    },
                                ]}
                                defaultValue={typeExam}
                                placeholder="Chọn loại đề thi"
                                onChange={(value) => {
                                    setTypeExam(value);
                                    setNumberQuestionPart([]);
                                    if (value !== 'Full') {
                                        setListPartExam([]);
                                    }
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        topicType: value,
                                    }));
                                }}
                            ></Select>
                        </div>

                        {/*Insert topic start time */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Thời gian làm bài:</span>
                            <InputNumber
                                className="w-1/2 font-BeVietNamRegular text-base"
                                placeholder="Thời gian làm bài"
                                onChange={(value) => {
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        workTime: value,
                                        err: {
                                            ...prevContentTopic.err,
                                            workTime: value === '' ? 'Please enter work time' : null,
                                        },
                                    }));
                                }}
                            />
                        </div>

                        {/*Insert count question */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Số lượng câu hỏi:</span>
                            <InputNumber
                                className="w-1/2 font-BeVietNamRegular text-base"
                                placeholder="Số lượng câu hỏi"
                                onChange={(value) => {
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        numberQuestion: value,
                                        err: {
                                            ...prevContentTopic.err,
                                            numberQuestion: value === '' ? 'Please enter work time' : null,
                                        },
                                    }));
                                }}
                            />
                        </div>
                        {/*upload file image, audio, excel*/}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Import đề :</span>

                            <button
                                className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-white-4d27b3 border hover:border-blue-500" 
                                type="button"
                                onClick={showImageModal} 
                            >
                                <span className="text-base font-poppinBold leading-normal text-center font-medium text-black">
                                    Import image
                                </span>
                            </button>

                            <button
                                className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-white-4d27b3 border hover:border-blue-500"
                                type="button"
                                onClick={showAudioModal} 
                            >
                                <span className="text-base font-poppinBold leading-normal text-center font-medium text-black">
                                    Import audio
                                </span>
                            </button>
                            <button
                                className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-white-4d27b3 border hover:border-blue-500"
                                type="button"
                                onClick={showExcelModal} 
                            >
                                <span className="text-base font-poppinBold leading-normal text-center font-medium text-black">
                                    Import excel
                                </span>
                            </button>

                            {/* Modal for upload image*/}
                            <Modal
                                title="Upload Image"
                                open={isImageModalOpen}
                                footer={null} 
                                onCancel={closeModal}
                            >
                                <UploadImage ImageLink={imageLink} setImageLink={setImageLink} resetUpload={resetUpload} closeModal={closeModal} />
                            </Modal>
                            {/* Modal for upload audio */}
                            <Modal
                                title="Upload Audio"
                                open={isAudioModalOpen}
                                footer={null} 
                                onCancel={closeModal}
                            >
                                <UploadAudio AudioLink={audioLink} setAudioLink={setAudioLink} />
                            </Modal>
                             {/* Modal for upload excel */}
                             <Modal
                                title="Upload Excel"
                                open={isExcelModalOpen}
                                footer={null} 
                                onCancel={closeModal}
                            >
                                <UploadExcel AudioLink={ExcelLink} setAudioLink={setExcelLink} />
                            </Modal>
                        </div>



                        {/*Insert packId */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Bộ đề thi:</span>
                            <Select
                                suffixIcon={<ChevronDown />}
                                className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                                options={menuPacks}
                                placeholder="Chọn bộ đề thi"
                                onChange={(value) => {
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        topicPack: value,
                                    }));
                                }}
                            ></Select>
                        </div>

                        {/*Insert startTime and endTime */}
                        <div className="flex flex-row items-center justify-start gap-4 w-full">
                            <span className="font-BeVietNamRegular text-lg font-bold w-1/4">Thời hạn đề thi:</span>
                            <RangePicker
                                showTime={{
                                    format: 'HH:mm',
                                }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={(value) => {
                                    if (value && value.length === 2) {
                                        const [startTime, endTime] = value.map((dateString) =>
                                            format(new Date(dateString), `yyyy-MM-dd'T'HH:mm:ss'`),
                                        );
                                        setContentTopic((prevContentTopic) => ({
                                            ...prevContentTopic,
                                            startTime: startTime,
                                            endTime: endTime,
                                        }));
                                    }
                                }}
                            />
                        </div>

                        <div className="flex justify-center items-center w-full mt-3">
                            <Collapse
                                className="w-full"
                                items={[
                                    {
                                        key: '1',
                                        showArrow: false,
                                        label: (
                                            <button
                                                className="h-12 w-full cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                                                type="button"
                                            >
                                                <span className="text-base font-poppinBold leading-normal text-center font-medium text-white">
                                                    Thêm câu hỏi
                                                </span>
                                            </button>
                                        ),
                                        children: (
                                            <div className="flex flex-col gap-6">
                                                {/* Add part to exam */}
                                                {typeExam !== 'Full' ? (
                                                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                                                        <span className="font-BeVietNamRegular text-lg font-bold w-1/4">
                                                            Chọn Part:
                                                        </span>
                                                        <Select
                                                            className="findExam-select w-1/2 h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                                                            mode="multiple"
                                                            allowClear={{ clearIcon: <XmarkIcon /> }}
                                                            options={menuPart}
                                                            onChange={(value) => {
                                                                const selectedOptions = menuPart.filter((option) =>
                                                                    value.includes(option.value),
                                                                );
                                                                const updatedNumberQuestionPart =
                                                                    numberQuestionPart.filter((item) =>
                                                                        value.includes(item.partId),
                                                                    );

                                                                setListPartExam(selectedOptions);
                                                                setNumberQuestionPart(updatedNumberQuestionPart);
                                                                setContentTopic((prevContentTopic) => ({
                                                                    ...prevContentTopic,
                                                                    listPart: value,
                                                                }));
                                                            }}
                                                            placeholder="Chọn Part"
                                                        />
                                                    </div>
                                                ) : null}

                                                {/* Add count question of part to exam */}
                                                {listPartExam.map((item) => (
                                                    <div
                                                        key={item.value}
                                                        className="flex flex-col items-start justify-start gap-4 w-full"
                                                    >
                                                        <div className="flex flex-row items-start justify-start gap-4 w-full">
                                                            <span className="font-BeVietNamRegular text-lg font-bold w-1/5">
                                                                {item.label}:
                                                            </span>
                                                            <div className="flex flex-row items-center justify-start gap-4 w-full">
                                                                <span className="font-BeVietNamRegular text-sm font-bold w-1/4">
                                                                    Số lượng câu hỏi:
                                                                </span>
                                                                <InputNumber
                                                                    className="font-BeVietNamRegular text-sm w-1/3"
                                                                    placeholder="Số lượng câu hỏi"
                                                                    onChange={(value) => {
                                                                        const existingPartIndex =
                                                                            numberQuestionPart.findIndex(
                                                                                (itemNumber) =>
                                                                                    itemNumber.partId === item.value,
                                                                            );

                                                                        if (existingPartIndex !== -1) {
                                                                            const updatedNumberQuestionPart = [
                                                                                ...numberQuestionPart,
                                                                            ];
                                                                            updatedNumberQuestionPart[
                                                                                existingPartIndex
                                                                            ].count = value;
                                                                            setNumberQuestionPart(
                                                                                updatedNumberQuestionPart,
                                                                            );
                                                                        } else {
                                                                            const updatedNumberQuestionPart = [
                                                                                ...numberQuestionPart,
                                                                                {
                                                                                    partId: item.value,
                                                                                    partName: item.label,
                                                                                    count: value,
                                                                                },
                                                                            ];
                                                                            setNumberQuestionPart(
                                                                                updatedNumberQuestionPart,
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {showQuestionPart(item)}
                                                    </div>
                                                ))}
                                            </div>
                                        ),
                                    },
                                ]}
                            ></Collapse>
                        </div>
                    </div>

                    <div className="flex justify-end items-center">
                        <button
                            className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                            type="submit"
                        >
                            <span className="text-base font-poppinBold leading-normal text-center font-medium text-white">
                                Thêm đề thi
                            </span>
                        </button>
                    </div>
                </form>
            </div>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading..." size="large"></Spin>
                </div>
            )}
        </div>
    );
};

export default AddExamModal;
