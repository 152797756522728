// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-gray {
  padding: 8px 12px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
}

.input-pass .ant-input {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}`, "",{"version":3,"sources":["webpack://./src/components/LoginPage/LoginPage.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;;AAGI;EAAA,YAAA;EAAA,WAAA;EAAA,kBAAA;EAAA;AAAA","sourcesContent":[".border-gray {\n    padding: 8px 12px;\n    border-radius: 2px;\n    border: solid 1px #d9d9d9;\n}\n\n.input-pass .ant-input {\n    @apply w-full h-full bg-hotlink-gray-f4f4f4;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
