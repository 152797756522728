import fileDefault from './images/file-blank-solid-240.png';
import filePdf from './images/pdf-icon.png';
import fileDoc from './images/doc-icon.png';
import fileExcel from './images/excel-icon.png';
import fileDocx from './images/docx-icon.png';

export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    doc: fileDoc,
    docx: fileDocx,
    xlsx: fileExcel
};
