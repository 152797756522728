// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-contact {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}

.border-contact {
  border: solid 1px #d9d9d9;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Contact/Contact.scss"],"names":[],"mappings":"AAAA;EACI,4CAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".shadow-contact {\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);\n}\n\n.border-contact {\n    border: solid 1px #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
