import ShortTest from '../ShortTest/ShortTest';
import './Minitest.scss';
import { useTranslation } from 'react-i18next';

function Minitest() {
    const { t } = useTranslation('common');

    const shortTest = [
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 1] MINI TEST', time: 10, quantity: 6 },
    ];

    return (
        <div
            className="w-full flex justify-center text-center flex-col py-10 bg-[#f7f3ff]"
            data-aos="fade-right"
            data-aos-duration="1000"
        >
            <h1 className="lg:text-[40px] text-3xl font-semibold text-[#309]">{t('mini_test')}</h1>
            <div className="lg:px-20 px-5 flex flex-row flex-wrap justify-between lg:mt-8 mt-4">
                {shortTest.map((test, index) => (
                    <ShortTest key={index} test={test} t={t}/>
                ))}
            </div>
            <div className="mt-8">
                <button className="px-20 py-3 rounded-xs shadow bg-[#4d27b3]">
                    <span className="flex justify-center text-base leading-normal text-center font-medium text-white ">
                        {t('see_more')}
                    </span>
                </button>
            </div>
        </div>
    );
}

export default Minitest;
