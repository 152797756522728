import AudioPlay from '../AudioPlay/AudioPlay';
import ToeicAnswerRadio from '../ToeicAnswerRadio';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';

import { useSelector, useDispatch } from 'react-redux';
import { setListQuestion, setStartPart, setCurrentQuestion } from '~/redux/topicSlice';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';

function ToeicPart() {
    const [contentQuestion, setContentQuestion] = useState();
    const location = useLocation();

    const dispatch = useDispatch();
    const listPart = useSelector((state) => state.topic.listPart);
    const startPart = useSelector((state) => state.topic.startPart);
    const currentPart = useSelector((state) => state.topic.currentPart);
    const listQuestion = useSelector((state) => state.topic.listQuestion);
    const currentQuestion = useSelector((state) => state.topic.currentQuestion);

    const axiosPrivate = useAxiosPrivate();
    const [numberical, setNumberical] = useState(0);
    const controller = new AbortController();

    const topicId = new URLSearchParams(location.search).get('id');

    const getQuestionToPart = async (partId) => {
        try {
            const response = await axiosPrivate.get('/topic/' + topicId + '/listQuestionToPart', {
                params: { partId },
                signal: controller.signal,
            });
            if (response.data.status === 'success') {
                let updatedListQuestion = [...listQuestion];
                let number = numberical;
                response.data.responseData.forEach((questionData) => {
                    const questionExists = updatedListQuestion.some(
                        (item) => item.questionId === questionData.questionId,
                    );
                    if (!questionExists) {
                        questionData.numberical = number + 1;

                        let numberIndex = questionData.questionGroup
                            ? number + questionData.questionGroup.length
                            : number + 1;
                        number = numberIndex;
                        setNumberical(numberIndex);

                        updatedListQuestion.push(questionData);
                    }
                });

                dispatch(setListQuestion(updatedListQuestion));
                dispatch(setCurrentQuestion(response.data.responseData[0].questionId));
            }
        } catch (err) {}
    };

    const showQuestionRadio = () => {
        if (currentQuestion) {
            if (listQuestion.find((item) => item.questionId === currentQuestion)?.questionGroup) {
                return listQuestion
                    .find((item) => item.questionId === currentQuestion)
                    .questionGroup.map((questionToGroup, index) => (
                        <ToeicAnswerRadio
                            key={index}
                            questionRadio={questionToGroup}
                            numberical={
                                listQuestion.find((item) => item.questionId === currentQuestion).numberical + index
                            }
                        />
                    ));
            } else {
                return (
                    <ToeicAnswerRadio
                        questionRadio={listQuestion.find((item) => item.questionId === currentQuestion)}
                        ofGroup={true}
                        numberical={listQuestion.find((item) => item.questionId === currentQuestion).numberical}
                    />
                );
            }
        }
    };

    const showIndexQuestion = () => {
        if (listQuestion.find((item) => item.questionId === currentQuestion)) {
            const question = listQuestion.find((item) => item.questionId === currentQuestion);
            if (question.questionGroup) {
                return `${question.numberical} - ${question.numberical + question.questionGroup.length - 1}`;
            }
            return `${question.numberical}`;
        }
        return '';
    };

    const addToStart = () => {
        const updatedListStart = [...startPart];
        updatedListStart.push(currentPart);
        dispatch(setStartPart(updatedListStart));

        getQuestionToPart(currentPart);
    };

    useEffect(() => {
        if (currentQuestion) {
            setContentQuestion(listQuestion.find((item) => item.questionId === currentQuestion));
        }
    }, [currentPart, currentQuestion]);

    return (
        <div className="lg:px-20 px-10">
            <p className="text-left my-6 text-[22px] font-robotoMedium font-semibold text-hotlink-black-065 capitalize ">
                {listPart && currentPart ? listPart.find((item) => item.partId === currentPart)?.partName : ''}
            </p>
            {startPart.includes(currentPart) ? (
                <div className="w-full flex lg:flex-row flex-col gap-4 justify-between">
                    <div className="lg:w-[43%] w-full flex flex-col justify-start items-start gap-4 rounded-xl bg-[#fff] p-6 text-left text-[#262626] font-medium">
                        <h5 className="font-robotoMedium text-[22px]">Câu {showIndexQuestion()}: </h5>
                        {contentQuestion &&
                        contentQuestion.contentList &&
                        contentQuestion.contentList.some((item) => item['Content Type'] === 'IMAGE') ? (
                            <img
                                src={
                                    contentQuestion.contentList.find((item) => item['Content Type'] === 'IMAGE')[
                                        'Content Data'
                                    ]
                                }
                                alt="test"
                            ></img>
                        ) : (
                            ''
                        )}

                        {contentQuestion &&
                        contentQuestion.contentList &&
                        contentQuestion.contentList.some((item) => item['Content Type'] === 'AUDIO') ? (
                            <AudioPlay
                                audioQuestion={
                                    contentQuestion.contentList.find((item) => item['Content Type'] === 'AUDIO')[
                                        'Content Data'
                                    ]
                                }
                            />
                        ) : (
                            ''
                        )}

                        <div
                            className="font-robotoRegular text-[18px] text-hotlink-black-262626"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(contentQuestion ? contentQuestion.questionContent : ''),
                            }}
                        ></div>
                    </div>
                    <div className="lg:w-[55%] w-full flex flex-col gap-4 rounded-lg bg-white p-6 text-left text-[#262626] font-medium overflow-y-auto">
                        {showQuestionRadio()}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="w-full flex lg:flex-row flex-col gap-4 justify-between">
                        {listPart &&
                        currentPart &&
                        listPart.find((item) => item.partId === currentPart)?.partType === 'LISTENING' ? (
                            <div className="lg:w-[43%] w-full rounded-lg bg-[#fff] p-6 text-left text-[#262626] font-medium">
                                <h5 className="text-xl leading-normal">LISTENING TEST </h5>
                                <p className="mt-4 text-base font-poppins tracking-normal grow-0">
                                    In the Listening test, you will be asked to demonstrate how well you understand
                                    spoken English. The entire Listening test will last approximately 45 minutes. There
                                    are four parts, and directions are given for each part. You must mark your answers
                                    on the separate answer sheet. Do not write your answers in your test book.
                                </p>
                            </div>
                        ) : listPart &&
                          currentPart &&
                          listPart.find((item) => item.partId === currentPart)?.partType === 'READING' ? (
                            <div className="lg:w-[43%] w-full rounded-lg bg-[#fff] p-6 text-left text-[#262626] font-medium">
                                <h5 className="text-xl leading-normal">READING TEST </h5>
                                <p className="mt-4 text-base font-poppins tracking-normal grow-0">
                                    In the Reading test, you will read a variety of texts and answer several different
                                    types of reading comprehension questions. The entire Reading test will last 75
                                    minutes. There are three parts, and directions are given for each part. You are
                                    encouraged to answer as many questions as possible within the time allowed. You must
                                    mark your answers on the separate answer sheet. Do not write your answers in your
                                    test book.
                                </p>
                            </div>
                        ) : null}

                        <div className="lg:w-[55%] w-full rounded-lg bg-[#fff] p-6 text-left text-[#262626] font-medium">
                            <h5 className="text-xl leading-normal">
                                {listPart && currentPart
                                    ? listPart.find((item) => item.partId === currentPart)?.partName
                                    : null}
                            </h5>
                            <p className="mt-4 text-base font-poppins tracking-normal grow-0">
                                {listPart && currentPart
                                    ? listPart.find((item) => item.partId === currentPart)?.partDescription
                                    : null}
                            </p>
                        </div>
                    </div>
                    <button className="mt-10 px-10 py-3 rounded-xs shadow bg-[#4d27b3]" onClick={addToStart}>
                        <span className="flex justify-center text-base leading-normal text-center font-medium text-white ">
                            Start Part
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default ToeicPart;
