import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Input } from 'antd';
import { UploadOutlined, CopyOutlined } from '@ant-design/icons';
import axiosPrivate from '../../../../../../api/axios';
import { uploadImageExam} from '../../../../../../api/fileApi/fileApi';

const UploadImage = ({ ImageLink, setImageLink, resetUpload, closeModal }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]); 
    const [copySuccess, setCopySuccess] = useState('');

    const resetData = () => {
        setImageUrl(null);
        setFileList([]);
        setCopySuccess('');
        if (setImageLink) setImageLink('');
    };

    useEffect(() => {
        if (resetUpload) {
            resetData();
        }
    }, [resetUpload]);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false;
        }

        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
            return false;
        }

        return true;
    };

    const handleChange = async ({ file, fileList }) => {
        setImageUrl(null);
        setCopySuccess('');
        setFileList(fileList);

        if (file.status !== 'uploading') {
            setUploading(true);

            if (beforeUpload(file)) {
                try {
                    const formData = new FormData();
                    formData.append('file', file.originFileObj);

                    const uploadResponse = await uploadImageExam(axiosPrivate, formData);
                    
                    if (uploadResponse.status === 'success') {
                        const imageUrlgetUrl = `https://gateway.dev.meu-solutions.com/englishmaster/api/file/showImage/${uploadResponse.responseData}`;
                        setImageUrl(imageUrlgetUrl);
                        setImageLink(imageUrlgetUrl);  
                    } else {
                        message.error('Upload failed');
                    }
                } catch (error) {
                    message.error('Upload failed');
                } finally {
                    setUploading(false);
                }
            }
        }
    };

    const handleRemove = () => {
        setImageUrl(null);
        setFileList([]);
    };

    const copyToClipboard = () => {
        if (imageUrl) {
            navigator.clipboard.writeText(imageUrl)
                .then(() => setCopySuccess('Link copied!'))
                .catch(() => setCopySuccess('Copy link failed!'));
        }
    };

    return (
        <div className="flex flex-col items-center justify-start gap-4 w-full">
            <Upload
                className="!w-full"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                fileList={fileList}
                onRemove={handleRemove}
            >
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="Uploaded"
                        style={{
                            width: '300px',
                            borderRadius: '8px',
                            marginBottom: '10px',
                        }}
                    />
                ) : (
                    <Button icon={<UploadOutlined />} loading={uploading}>
                        {uploading ? 'Uploading...' : 'Upload'}
                    </Button>
                )}
            </Upload>
            {imageUrl && (
                <div className="flex items-center gap-4">
                    <Input
                        value={imageUrl}
                        readOnly
                        style={{ width: '300px' }}
                    />
                    <Button 
                        icon={<CopyOutlined />} 
                        onClick={copyToClipboard}
                    >
                        Copy link
                    </Button>
                    {copySuccess && <span>{copySuccess}</span>}
                </div>
            )}
        </div>
    );
};

export default UploadImage;
