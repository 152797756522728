import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    listWord: null,
};

const flashCardSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setListWord: (state, action) => {
            state.listWord = action.payload;
        },
    },
});

export const { setListWord } = flashCardSlice.actions;

export default flashCardSlice.reducer;
