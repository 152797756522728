import Banner from '../../components/TestPage/Banner/Banner';
import Information from '../../components/TestPage/Information/Info';
import Fulltest from '../../components/TestPage/Fulltest/Fulltest';
import Minitest from '../../components/TestPage/Minitest/Minitest';
import SkillTest from '../../components/TestPage/SkillTest/SkillTest';
import News from '../../components/TestPage/News/News';

function index() {
    return (
        <div>
            <Banner />
            <Information />
            <Fulltest backgroundColor="#f7f3ff" />
            <Minitest />
            <SkillTest />
            <News title="news_event" />
        </div>
    );
}

export default index;
