import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { clearOption } from '~/redux/optionSlice';
import Loading from '~/components/Loading';

import TableNews from '../../../components/Admin/ManageNews/TableNews';
import SearchNews from '../../../components/Admin/ManageNews/SearchNews';
import AddNews from '../../../components/Admin/ManageNews/AddNews';
import UpdateNews from '../../../components/Admin/ManageNews/UpdateNews';
import DeleteNews from '../../../components/Admin/ManageNews/DeleteNews';

function ManageNewsPage() {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [api, contextHolder] = notification.useNotification();

    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [changeSource, setChangeSource] = useState();

    const [state, setState] = useState({
        totalRecords: 0,
        currentPage: 1,
        sizePage: 10,
        dataSource: [],
    });

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };
    useEffect(() => {
        dispatch(clearOption());
    }, []);

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    const remove = (newsId) => {
        const newList = state.dataSource.filter((item) => item.key !== newsId);
        setState((prevState) => ({
            ...prevState,
            dataSource: newList.map((item, index) => ({
                ...item,
                number: index + 1,
            })),
        }));
    };

    return (
        <div className="flex flex-col justify-start items-start gap-4 min-h-[540px]">
            {contextHolder}

            {openDelete ? (
                <DeleteNews setOpenDelete={(value) => setOpenDelete(value)} dataSource={changeSource} remove={remove} />
            ) : null}
            {openAdd ? <AddNews setOpenAdd={setOpenAdd} setState={setState} dataSource={state.dataSource} /> : null}
            {openEdit ? (
                <UpdateNews
                    setOpenEdit={setOpenEdit}
                    setState={setState}
                    dataSource={state.dataSource}
                    editSource={changeSource}
                />
            ) : null}

            <SearchNews setOpenAdd={setOpenAdd} />
            <TableNews
                setOpenDelete={setOpenDelete}
                setOpenEdit={setOpenEdit}
                setChangeSource={(value) => setChangeSource(value)}
                state={state}
                setState={setState}
            />
            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default ManageNewsPage;
