import './FulltestItem.scss';
import { Link } from 'react-router-dom';

function FulltestItem(props) {
    const { t, topic } = props;

    return (
        <div className="box-shadow m-4 rounded-xl">
            <img className="w-full rounded-t-lg" src={topic.topicImage} alt="" />
            <div div className="bg-white w-full p-6 rounded-b-lg">
                <h5 className="lg:text-lg text-base mb-4">{topic.topicName}</h5>
                <p className="font-medium text-sm mb-4">{`(Thời gian: ${topic.workTime} - Câu hỏi: ${topic.topicDescription})`}</p>
                <Link to={`/test/test-guide?id=${topic.topicId}`}>
                    <button className="text-sm px-[15px] py-4 text-white w-full bg-[#309]">{t('take_a_test')}</button>
                </Link>
            </div>
        </div>
    );
}

export default FulltestItem;
