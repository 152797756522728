function HotNewsItem(props) {
    return (
        <div className="mb-6 px-5">
            <div className="flex lg:flex-row flex-col gap-8">
                <img
                    src={props.hotNewPicture}
                    alt="hot-new"
                    className="lg:w-[36.3%] w-full rounded-2xl shadow-[0_8px_24px_0_rgba(0,0,0,0.1)] border border-solid border-hotlink-white-fafaff"
                />
                <div className="flex flex-col justify-start items-stretch gap-4 font-poppinsMedium text-hotlink-black-085 font-medium">
                    <span className="lg:text-[28px] text-xl">{props.title}</span>
                    <span className="lg:text-base text-xs">{props.day}</span>
                    <span className="lg:text-base text-xs">{props.description}</span>
                </div>
            </div>
        </div>
    );
}

export default HotNewsItem;
