import ProfileTest from '../../../components/Profile/ProfileTest';

function ProfileTestPage() {
    return (
        <div className=" bg-hotlink-purple-f7f3ff ">
            <ProfileTest />
        </div>
    );
}

export default ProfileTestPage;
