export const uploadImage = (axiosPrivate, file, dir = '/', isPrivateFile = false, topicId = '', code = '', config = {}) => {
    const formData = new FormData();
    formData.append('file', file); 

    return axiosPrivate
        .post('/v1/upload/file', formData, {
            params: {
                dir,             
                isPrivateFile,   
                topicId,         
                code             
            },
            ...config,          
            headers: {
                'Content-Type': 'multipart/form-data', 
                ...config.headers,
            }
        })
        .then((response) => response.data) 
        .catch((error) => {
            throw error; 
        });
};
