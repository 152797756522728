import { message, Upload, Button } from 'antd';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';

import { ImageConfig } from '../../../assets/ImageConfig';
import { XmarkIcon } from '../../Icons/Icons';

const beforUploadAudio = (file) => {
    const isAudio = file.type === 'audio/mpeg' || file.type === 'audio/mp3';
    if (!isAudio) {
        message.error('You can only upload MP3 file!');
    }
    return isAudio;
};

const UploadAudio = (props) => {
    const [audioUrl, setAudioUrl] = useState(props.inforFile ? props.inforFile : undefined);
    const [inforFile, setInforFile] = useState();

    const handleChangeAudio = (info) => {
        if (info.fileList.length > 0) {
            setInforFile(info.file);
            const file = info.fileList[0].originFileObj;
            if (file) {
                props.setContent(file);
                const reader = new FileReader();
                reader.onload = (info) => {
                    setAudioUrl(info.target.result);
                };
                reader.readAsDataURL(file);
            }
        } else {
            setAudioUrl(null);
        }
    };
    return (
        <div className="flex flex-col gap-2">
            <Upload
                className="!w-1/3"
                name="avatar"
                showUploadList={false}
                beforeUpload={beforUploadAudio}
                onChange={handleChangeAudio}        
                disabled={props.disabled ? true : false}
            >
                <Button disabled={props.disabled ? true : false} icon={<UploadOutlined />}>
                    Click to Upload
                </Button>
            </Upload>
            {inforFile || audioUrl ? (
                <div className="flex items-center rounded-2xl relative group">
                    <img
                        className="w-[20px] h-[20px] mr-2"
                        src={
                            ImageConfig[
                                inforFile
                                    ? inforFile.name.split('.').pop().toLowerCase()
                                    : audioUrl.split('/').pop().toLowerCase()
                            ] || ImageConfig['default']
                        }
                        alt=".file"
                    />
                    <div className="w-24 flex flex-col justify-between break-words font-BeVietNamRegular text-xs">
                        <p>{inforFile ? inforFile.name : audioUrl.split('/').pop().toLowerCase()}</p>
                        {inforFile ? <p>{(inforFile.size / 1024).toFixed(2)}kB</p> : null}
                    </div>
                    <button
                        type="button"
                        disabled={props.disabled ? true : false}
                        className=" flex items-center justify-center absolute rounded-1/2 right-0 top-1/2 cursor-pointer -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100"
                        onClick={() => {
                            setInforFile(null);
                            setAudioUrl(null);
                            props.setContent('');
                        }}
                    >
                        <XmarkIcon />
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default UploadAudio;
