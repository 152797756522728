// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow {
  box-shadow: 0 -1px 24px 0 rgba(0, 0, 0, 0.2) !important;
}

.search-icon {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 38px;
  foo: bar;
}

.bell-icon {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  width: 48px;
  foo: bar;
}

.avatar-icon {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 88px;
  flex-direction: row;
  foo: bar;
}

.box-shadow {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
}

.font-be-vietnam {
  font-family: var(--font-be-vietnam);
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/ToeicExamPage/ToeicExamHeader/ToeicExamHeader.scss"],"names":[],"mappings":"AAMA;EACI,uDAAA;AALJ;;AACI;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,2BAAA;EAAA,qBAAA;EAAA,sBAAA;EAUA,WAAA;EACA;AAXA;;AAAA;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,2BAAA;EAAA,qBAAA;EAAA,sBAAA;EAgBA,kBAAA;EAAA,WAAA;EACA;AAjBA;;AAAA;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,2BAAA;EAAA,qBAAA;EAAA,sBAAA;EAsBA,WAAA;EAAA,mBAAA;EACA;AAvBA;;AA0BJ;EACI,yCAAA;AANJ;;AASA;EACI,mCAAA;AANJ","sourcesContent":["\n\n@mixin icon_mixin {\n    @apply flex flex-col justify-center items-center px-3 cursor-pointer bg-hotlink-white-ffffff00;\n}\n\n.shadow {\n    box-shadow: 0 -1px 24px 0 rgba(0, 0, 0, 0.2) !important;\n}\n\n// CSS toolbar\n.search-icon {\n    @include icon_mixin();\n    @apply w-[38px];\n    foo: bar;\n}\n\n.bell-icon {\n    @include icon_mixin();\n    @apply w-[48px] relative ;\n    foo: bar;\n}\n\n.avatar-icon {\n    @include icon_mixin();\n    @apply w-[88px] flex-row;\n    foo: bar;\n}\n\n.box-shadow {\n    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);\n}\n\n.font-be-vietnam {\n    font-family: var(--font-be-vietnam);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
