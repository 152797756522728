import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectItem: 'statistic',
    optionAdminList: {},
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setSelectItem: (state, action) => {
            state.selectItem = action.payload;
        },
        clearSelectItem: (state) => {
            state.selectItem = null;
        },
        setOptionAdminList: (state, action) => {
            state.optionAdminList = action.payload;
        },
    },
});

export const { setSelectItem, clearSelectItem, setOptionAdminList } = adminSlice.actions;

export default adminSlice.reducer;
