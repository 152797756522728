import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from '~/components/GlobalStyles';
import { Provider } from 'react-redux';
import store from '~/redux/store';

import { I18nextProvider } from 'react-i18next';

import 'font-awesome/css/font-awesome.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <GlobalStyles>
                <App />
            </GlobalStyles>
        </I18nextProvider>
    </Provider>,
);

reportWebVitals();
