import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { changePass } from '~/api/userApi';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';

function ModalChangePass(props) {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [props.resetPass]);

    const changePassUser = async () => {
        try {
            dispatch(startLoading());
            const response = await changePass(
                axiosPrivate,
                JSON.stringify({
                    oldPass: props.resetPass.oldPass,
                    newPass: props.resetPass.newPass,
                    confirmPass: props.resetPass.confirmPass,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                },
            );

            if (response?.violations === 401) {
                dispatch(setStatusMessage('Change pass fail'));
                dispatch(setErrMessage('Unauthorized'));
                props.closeModal();
            } else if (response?.status === 'fail') {
                dispatch(setStatusMessage('Change pass fail'));
                dispatch(setErrMessage(response?.message));
                props.closeModal();
            } else {
                dispatch(setStatusMessage('Change pass successful'));
                dispatch(setErrMessage('Successful'));
                props.setResetPass((prevPass) => ({
                    ...prevPass,
                    newPass: '',
                    oldPass: '',
                    confirmPass: '',
                }));
                props.closeModal();
            }
        } catch (err) {
            dispatch(setStatusMessage('Change pass fail'));
            dispatch(setErrMessage('Change pass failed'));
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <div className="fixed z-[100] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center">
            <div className=" bg-hotlink-white-f5f5f5 flex flex-col items-center justify-center py-[20px] px-[35px] gap-3 shadow-[5px_5px_10px_rgba(0,0,0,0.123)] rounded-3xl">
                <p className="text-black font-BeVietNamRegular font-bold text-2xl">Thông báo</p>
                <svg className="w-[50px] my-5" viewBox="0 0 448 512">
                    <path
                        fill="rgb(168, 131, 255)"
                        d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"
                    ></path>
                </svg>
                <p className="text-hotlink-black-858585 font-BeVietNamRegular text-base">
                    Bạn có chắc chắn muốn cập nhật mật khẩu
                </p>
                <div className="flex flex-col gap-2">
                    <button
                        className="w-32 p-2 bg-hotlink-purple-a883ff text-white border-none rounded-2xl text-base cursor-pointer font-poppinsRegular font-semibold hover:bg-hotlink-purple-996eff"
                        onClick={() => changePassUser()}
                    >
                        Xác nhận
                    </button>
                    <button
                        className="w-32 p-2 bg-transparent text-hotlink-purple-a883ff border-none rounded-2xl text-base cursor-pointer font-poppinsRegular font-semibold hover:bg-hotlink-purple-efe3ff"
                        onClick={() => props.closeModal()}
                    >
                        Hủy
                    </button>
                </div>
            </div>
        </div>
    );
}
export default ModalChangePass;
