import { useTranslation } from 'react-i18next';
import TestIcon from '../../../assets/svg/Bookmark.svg'
import ToeicTestItem from '../ToeicTestItem/ToeicTestItem';

function ToeicTest() {
    const { t } = useTranslation('common');

    const listenTest = [
        { title: '[TOEIC LISTENING - PART 1] Mô tả hình ảnh', time: 10, quantity: 6 },
        { title: '[TOEIC LISTENING - PART 2] Hỏi - Đáp', time: 10, quantity: 25 },
        { title: '[TOEIC LISTENING - PART 3] Đoạn hội thoại', time: 10, quantity: 39 },
        { title: '[TOEIC LISTENING - PART 4] Bài nói chuyện ngắn (Độc thoại)', time: 10, quantity: 30 },
    ]

    const readingTest = [
        { icon: TestIcon, title: '[TOEIC READING - Part 5]Câu không hoàn chỉnh', time: 10, quantity: 30 },
        { icon: TestIcon, title: '[TOEIC READING - Part 6]Hoàn thành đoạn văn', time: 10, quantity: 16 },
        { icon: TestIcon, title: '[TOEIC READING - Part 7] Đọc hiểu - Đoạn đơn', time: 10, quantity: 29 },
        { icon: TestIcon, title: '[TOEIC READING - Part 8] Đọc hiểu - Nhiều đoạn', time: 10, quantity: 25 },
    ]

    return (
        <div className='bg-[fff2ec] py-10 w-full flex flex-col text-center justify-center'>
            <h1 className="text-40px font-semibold text-[#309]">{t('skill_test')}</h1>
            <span className='text-base mb-10'>{t('skill_select')}</span>
            <div className='text-left px-20'>
                <div className='mb-9'>
                    <h1 className="text-40px font-semibold text-[#309]">1/ {t('listen_part')}</h1>
                    <div className='w-full flex flex-wrap justify-between mt-8'>
                        {listenTest.map((test, index) => (
                            <ToeicTestItem key={index} test={test} t={t} />
                        ))}
                    </div>
                </div>
                <div>
                    <h1 className="text-40px font-semibold text-[#309]">2/ {t('reading_part')}</h1>
                    <div className='w-full flex flex-wrap justify-between mt-8'>
                        {readingTest.map((test, index) => (
                            <ToeicTestItem key={index} test={test} t={t} icon={test.icon} />
                        ))}
                    </div>
                </div>
            </div>
            <div className='mt-8 mb-20'>
                <button className="px-20 py-4 rounded-xs shadow bg-[#4d27b3]">
                    <span className="flex justify-center text-base leading-normal text-center font-medium text-white ">
                        {t('start')}
                    </span>
                </button>
            </div>
        </div>
    )
}

export default ToeicTest