import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    queryParams: {},
};

const listExamSlice = createSlice({
    name: 'listExam',
    initialState,
    reducers: {
        setQueryParams: (state, action) => {
            state.queryParams = action.payload;
        },
        clearQueryParams: (state) => {
            state.queryParams = {};
        },
    },
});

export const { setQueryParams, clearQueryParams } = listExamSlice.actions;

export default listExamSlice.reducer;
