import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { setOptionAdminList } from '~/redux/adminSlice';
import Loading from '~/components/Loading';
import TableAccountUser from '../../../components/Admin/ManageAccount/TableAccountUser';
import SearchAccountUser from '../../../components/Admin/ManageAccount/SearchAccountUser';
import DeleteAccountUser from '../../../components/Admin/ManageAccount/DeleteAccountUser';

function ManageAccountPage() {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [api, contextHolder] = notification.useNotification();

    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [changeSource, setChangeSource] = useState();

    const [state, setState] = useState({
        totalRecords: 0,
        currentPage: 1,
        sizePage: 10,
        dataSource: [],
    });

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };
    useEffect(() => {
        dispatch(setOptionAdminList({}));
    }, []);

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    const removeAccount = (userId) => {
        const newListAccount = state.dataSource.filter((item) => item.key !== userId);
        setState((prevState) => ({
            ...prevState,
            dataSource: newListAccount.map((item, index) => ({
                ...item,
                number: index + 1,
            })),
        }));
    };

    return (
        <div className="flex flex-col justify-start items-start gap-4">
            {contextHolder}

            {isOpenDelete ? (
                <DeleteAccountUser
                    setIsOpenDelete={(value) => setIsOpenDelete(value)}
                    dataSource={changeSource}
                    removeAccount={removeAccount}
                />
            ) : null}

            <SearchAccountUser />
            <TableAccountUser
                setIsOpenDelete={(value) => setIsOpenDelete(value)}
                setChangeSource={(value) => setChangeSource(value)}
                state={state}
                setState={setState}
            />
            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default ManageAccountPage;
