// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-intro {
  border: solid 1px #330099;
}

.shadow-search {
  box-shadow: 0 15px 40px 0 rgba(123, 97, 255, 0.15);
}

.border-search {
  border: solid 1px #7158d7;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Intro/Intro.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;AAAJ;;AAGA;EACI,kDAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ","sourcesContent":["\n.border-intro {\n    border: solid 1px #330099;\n}\n\n.shadow-search {\n    box-shadow: 0 15px 40px 0 rgba(123, 97, 255, 0.15);\n}\n\n.border-search {\n    border: solid 1px #7158d7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
