import { Select } from 'antd';
import { useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchDictionaryIcon, ChevronDown, XmarkIcon } from '~/components/Icons';
import { setOption } from '~/redux/optionSlice';


function SearchFeedback(props) {
    const dispatch = useDispatch();

    const optionAdminList = useSelector((state) => state.option.option);

    const [searchFill, setSearchFill] = useState();
    const [enableFill, setEnableFill] = useState();

    const menuEnable = [
        { value: 'disable', label: 'Chưa duyệt' },
        { value: 'enable', label: 'Đã duyệt' },
    ];

    const searchUnit = () => {
        dispatch(setOption({ ...optionAdminList, search: searchFill }));
    };
    const choiceEnable = (value) => {
        dispatch(setOption({ ...optionAdminList, enable: value }));
    };

    return (
        <div className="w-full flex flex-row justify-between items-end pt-4 h-14 font-poppinsRegular text-sm text-hotlink-black-161616 ">
            <Select
                suffixIcon={<ChevronDown />}
                allowClear={{ clearIcon: <XmarkIcon /> }}
                className="filterPost-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                placeholder="Chọn trạng thái"
                options={menuEnable}
                value={enableFill}
                onChange={(value) => {
                    setEnableFill(value);
                    choiceEnable(value);
                }}
            ></Select>

            <div className="flex flex-row gap-4 w-full h-full justify-end">
                <div className="w-[35%] h-full flex flex-row items-center justify-between rounded-sm border-solid border-[1px] border-hotlink-white-d9d9d9 bg-white">
                    <input
                        className="gap-[10px] py-1 px-3 w-full"
                        placeholder="Nhập từ khóa cần tìm"
                        type="text"
                        value={searchFill}
                        onChange={(e) => {
                            setSearchFill(e.target.value);
                        }}
                    ></input>
                    <button>
                        <SearchDictionaryIcon className="cursor-pointer w-[32px] h-[32px]" />
                    </button>
                </div>

                <button
                    className="w-[15%] h-full px-[15px] py-1 rounded-sm shadow-[0_2px_0px_0px_rgba(0,0,0,0.4)] border-solid border-[1px] border-hotlink-blue-309 bg-hotlink-blue-309"
                    onClick={searchUnit}
                >
                    <span className="text-white">Tìm kiếm</span>
                </button>

                <button
                    className="w-[15%] h-full px-[15px] py-1 rounded-sm shadow-[0_2px_0px_0px_rgba(0,0,0,0.4)] border-solid border-[1px] border-hotlink-blue-309 bg-hotlink-blue-309"
                    onClick={() => props.setOpenAdd(true)}
                >
                    <span className="text-white">Thêm phản hồi</span>
                </button>
            </div>
        </div>
    );
}

export default SearchFeedback;
