export const uploadImageExam = (axiosPrivate, file, config = {}) => {
    return axiosPrivate
        .post(`/file/saveImage`, file, config )
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getImageByUsername = (axiosPrivate, Image, config = {}) => {
    return axiosPrivate 
        .get(`/file/showImage/${Image}`, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const getLinkDownLoad = (axiosPrivate, Image = {}) => {
    return axiosPrivate 
        .get(`/file/${Image}`)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const getImageName = (axiosPrivate = {}) => {
    return axiosPrivate 
        .get(`/file/getImageName`)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

export const removeImage = (axiosPrivate, Image, payload, config) => {
    return axiosPrivate
        .delete(`/file/deleteImage/${Image}`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
export const getAudioByUsername = (axiosPrivate, Audio, config = {}) => {
    return axiosPrivate 
        .get(`/file/showAudio/${Audio}`, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}