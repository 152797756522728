import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { listFlashCardWord } from '~/api/flashCardApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import FlashCardContentItem from './FlashCardContentItem';
import { setListWord } from '~/redux/flashCardSlice';
import AddFlashCardWord from './AddFlashCardWord';

const FlashCardContent = () => {
    const location = useLocation();
    const controller = new AbortController();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openAddWord, setOpenAddWord] = useState(false);

    const listWord = useSelector((state) => state.flashCard.listWord);

    const flashCardId = new URLSearchParams(location.search).get('card');

    const getWordToFlashCard = async (flashCardId) => {
        try {
            const response = await listFlashCardWord(axiosPrivate, flashCardId);
            if (response.status === 'success') {
                dispatch(setListWord(response.responseData));
            }
        } catch (err) {}
    };

    useEffect(() => {
        getWordToFlashCard(flashCardId);

        return () => {
            controller.abort();
        };
    }, [flashCardId]);

    const addWordList = (newWord) => {
        const updatedListWord = {
            ...listWord,
            flashCardWord: [newWord, ...listWord.flashCardWord],
        };

        dispatch(setListWord(updatedListWord));
    };

    const removeWordList = (wordId) => {
        const updatedListWord = {
            ...listWord,
            flashCardWord: listWord.flashCardWord.filter((item) => item.wordId !== wordId),
        };
        dispatch(setListWord(updatedListWord));
    };

    return listWord ? (
        <div className="w-full flex flex-col justify-start gap-8 py-10 px-6 rounded-xl bg-white">
            {openAddWord ? (
                <AddFlashCardWord
                    closeAddWord={() => setOpenAddWord(false)}
                    flashCardId={flashCardId}
                    addWordList={addWordList}
                />
            ) : null}
            <div className="flex lg:flex-row flex-col gap-2 justify-between">
                <div className="flex flex-col gap-2 font-BeVietNamRegular text-sm text-justify text-black">
                    <span className="font-BeVietnambold text-2xl tracking-normal">FlashCard: {listWord.flashCardTitle}</span>
                    <span>List có {listWord.flashCardWord.length} từ</span>
                </div>

                <div className="flex flex-row gap-4">
                    <button
                        className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                        onClick={() => {
                            setOpenAddWord(true);
                        }}
                    >
                        <span className="text-base font-BeVietNamBold leading-normal text-center font-medium text-white">
                            Thêm từ mới
                        </span>
                    </button>
                    {listWord.flashCardWord.length !== 0 ? (
                        <button
                            className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                            onClick={() => navigate(`/flash-card/list/review?card=${flashCardId}`)}
                        >
                            <span className="text-base font-BeVietNamBold leading-normal text-center font-medium text-white">
                                Luyện tập
                            </span>
                        </button>
                    ) : null}
                </div>
            </div>

            {listWord.flashCardWord.map((item) => (
                <FlashCardContentItem word={item} key={item.wordId} removeWordList={removeWordList} />
            ))}
        </div>
    ) : null;
};

export default FlashCardContent;
