import axios from '../axios';

export const deleteQuestion = (axiosPrivate, questionId, payload, config) => {
    return axiosPrivate
        .delete(`/question/${questionId}/deleteQuestion`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const editQuestion = (axiosPrivate, questionId, payload, config) => {
    return axiosPrivate
        .put(`/question/${questionId}/editQuestion`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
