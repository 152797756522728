import FlashCardBreadCrumb from '~/components/FlashCard/FlashCardBreadCrumb';
import FlashCardContent from '~/components/FlashCard/FlashCardContent';

function FlashCardList() {
    return (
        <div className="bg-hotlink-purple-f7f3ff pb-[82px] min-h-screen">
            <div className="flex flex-col gap-[18px] lg:pt-20 pt-10 lg:mx-20 mx-5 font-poppinsRegular ">
                <FlashCardBreadCrumb />
                <FlashCardContent />
            </div>
        </div>
    );
}

export default FlashCardList;
