import Slider from 'react-slick';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RightArrowIcon, LeftArrowIcon } from '~/components/Icons';
import { useTranslation } from 'react-i18next';

import { listNewsUser } from '~/api/newsApi';

import SingleNew from '~/components/Home/SingleNew/SingleNew';


import './News.scss';

const News = (props) => {
    const navigate = useNavigate();
    const slider = useRef(null);
    const controller = new AbortController();
    const { t } = useTranslation('common');

    const [data, setData] = useState([]);

    const getListNews = async () => {
        try {
            const response = await listNewsUser(
                { size: '5' },
                {
                    signal: controller.signal,
                },
            );

            if (response?.status === 'success') {
                const newData = response.responseData.map((item, i) => ({
                    key: item.newsId,
                    hotNewPicture: item.newsImage,
                    title: item.newsTitle,
                    description: item.newsContent,
                    day: item.updateAt.split(' ')[0],
                }));

                setData(newData);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getListNews();

        return () => {
            controller.abort();
        };
    }, []);

    const listNews = data.map((element, index) => {
        return <SingleNew key={index} picture={element.hotNewPicture} title={element.title} address={element.day} />;
    });

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
        ],
    };

    return (
        <div
            className="w-full px-4 lg:px-0  flex flex-col justify-start items-center gap-8 relative py-10"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
        >
            <span className="h-11 lg:text-[40px] text-4xl font-poppinBold font-semibold leading-none text-hotlink-blue-309">
                {t('new_event')}
            </span>
            <div className="lg:w-[1280px] w-[85%]">
                <Slider className="news-slider" ref={slider} {...settings}>
                    {listNews}
                </Slider>
            </div>
            <div className="h-8 lg:w-[1360px] w-[95%] flex justify-between items-start lg:top-[310px] top-[250px] absolute">
                <div className="relative h-full w-full">
                    <button
                        onClick={() => slider?.current?.slickPrev()}
                        className="w-8 h-full self-stretch flex justify-center items-center rounded-1/2 cursor-pointer bg-hotlink-white-e6e6e6 absolute left-0"
                    >
                        <LeftArrowIcon />
                    </button>

                    <button
                        onClick={() => slider?.current?.slickNext()}
                        className="w-8 h-full self-stretch flex justify-center items-center rounded-1/2 cursor-pointer bg-hotlink-white-e6e6e6 absolute right-0"
                    >
                        <RightArrowIcon />
                    </button>
                </div>
            </div>

            <button
                className="w-[238px] h-12 flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                onClick={() => navigate('/news')}
            >
                <span className="h-[25.2px] text-base font-poppinBold leading-normal text-center font-medium text-white ">
                    {t('see_more')}
                </span>
            </button>
        </div>
    );
};

export default News;
