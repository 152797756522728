import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { setListQuestion } from '~/redux/topicSlice';

import Loading from '~/components/Loading';
import ResultExamPart from '~/components/ResultExam/ResultExamPart';
import CommentTopic from '~/components/CommentTopic';

function Result() {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [state, setState] = useState({
        listPart: [],
        currentPart: undefined,
        inforTopic: { name: undefined, workTime: undefined, id: undefined },
    });

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };
    useEffect(() => {
        dispatch(setListQuestion([]));
    }, []);

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    return (
        <div>
            {contextHolder}
            <div className='bg-[#f7f3ff] pb-10'>
                <ResultExamPart state={state} setState={setState} />
                <CommentTopic state={state} />
            </div>

            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default Result;
