import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import Loading from '~/components/Loading';
import ChangeProfile from '../../../components/Profile/ChangeProfile';

function ChangeProfilePage() {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    return (
        <div>
            {contextHolder}
            <ChangeProfile />
            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default ChangeProfilePage;
