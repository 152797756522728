import config from '~/config';

// Pages
import Home from '~/pages/Home';
import Login from '~/pages/Login';
import Test from '~/pages/Test';
import News from '~/pages/News';
import TestInformation from '~/pages/Test/TestInformation';
import ToeicFullTest from '~/pages/Test/ToeicFullTest';
import ToeicFullTestItem from '~/pages/Test/ToeicFullTestItem';
import Dictionary from '~/pages/Dictionary/Dictionary';
import Register from '~/components/RegisterPage/Register';
import ConfirmRegister from '~/components/RegisterPage/ConfirmRegister/ConfirmRegister';
import ExamRepository from '~/pages/ExamRepository';
import FlashCard from '~/pages/FlashCard';
import FlashCardList from '~/pages/FlashCard/FlashCardList';
import FlashCardReview from '~/pages/FlashCard/FlashCardReview';
import ForgotPassword from '~/components/ForgotPasswordPage/ForgotPassword';
import VerifyOTP from '~/components/ForgotPasswordPage/VerifyOTP';
import ChangePassword from '~/components/ForgotPasswordPage/ChangePassword';



import { DefaultLayout, AdminLayout } from '~/layouts';
import ManageExam from '~/pages/Admin/ManageExam';
import ForumPage from '~/pages/ForumPage';
import ProfileTestPage from '~/pages/Profile/ProfileTestPage';
import ChangeProfilePage from '~/pages/Profile/ChangeProfilePage';
import Result from '~/pages/Result';

import ChangePassPage from '~/pages/Profile/ChangePassPage';
import ManageAccountPage from '~/pages/Admin/ManageAccountPage';
import ManageStatisticPage from '~/pages/Admin/ManageStatisticPage';
import ManageNewsPage from '~/pages/Admin/ManageNewsPage';
import ManageFeedbackPage from '~/pages/Admin/ManageFeedbackPage';
import ManageMockTestPage from '~/pages/Admin/ManageMockTestPage';

import ProfileLayout from '~/layouts/ProfileLayout/ProfileLayout';
import ForumLayout from '~/layouts/ForumLayout';
import TestLayout from '~/layouts/TestLayout';

// Public routes
const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.login, component: Login, layout: null },
    { path: config.routes.register, component: Register, layout: null },
    { path: config.routes.confirmRegister, component: ConfirmRegister, layout: null },
    { path: config.routes.test, component: Test },
    { path: config.routes.testInformation, component: TestInformation },
    { path: config.routes.examRepository, component: ExamRepository },
    { path: config.routes.toeicFullTest, component: ToeicFullTest },
    { path: config.routes.toeicFullTestItem, component: ToeicFullTestItem, layout: TestLayout },
    { path: config.routes.dictionary, component: Dictionary },
    { path: config.routes.news, component: News },
    { path: config.routes.forum, component: ForumPage, layout: ForumLayout },
    { path: config.routes.flashCard, component: FlashCard },
    { path: config.routes.profileTest, component: ProfileTestPage, layout: ProfileLayout },
    { path: config.routes.changeProfile, component: ChangeProfilePage, layout: ProfileLayout },
    { path: config.routes.changePass, component: ChangePassPage, layout: ProfileLayout },
    { path: config.routes.detailResult, component: Result },
    { path: config.routes.forgetPassword, component: ForgotPassword, layout: null },
    { path: config.routes.verifyOtp, component: VerifyOTP, layout: null },
    { path: config.routes.changePassword, component: ChangePassword, layout: null },
];

const privateRoutes = [

    { path: config.routes.admin_manage_exam, component: ManageExam, layout: AdminLayout },
    { path: config.routes.admin_manage_statistic, component: ManageStatisticPage, layout: AdminLayout },
    { path: config.routes.admin_manage_user, component: ManageAccountPage, layout: AdminLayout },
    { path: config.routes.admin_manage_news, component: ManageNewsPage, layout: AdminLayout },
    { path: config.routes.admin_manage_feedback, component: ManageFeedbackPage, layout: AdminLayout },
    { path: config.routes.admin_manage_mocktest, component: ManageMockTestPage, layout: AdminLayout },
    { path: config.routes.flashCardList, component: FlashCardList, layout: DefaultLayout },
    { path: config.routes.flashCardReview, component: FlashCardReview, layout: DefaultLayout },
];
export { publicRoutes, privateRoutes };
