// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-40px {
  font-size: 40px;
  font-family: var(--font-be-vietnam);
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/Minitest/Minitest.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mCAAA;AACJ","sourcesContent":[".text-40px {\n    font-size: 40px;\n    font-family: var(--font-be-vietnam);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
