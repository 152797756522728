import Navigation from '~/components/Profile/Navigation';
import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';

function ProfileLayout({ children }) {
    return (
        <div className="overflow-hidden bg-hotlink-purple-f7f3ff ">
            <Header />
            <div className="mt-[112px] lg:px-20 px-5 flex lg:flex-row flex-col lg:gap-10 gap-5 lg:mb-20 mb-5">
                <Navigation />
                <div className="content lg:w-[78%] w-full">{children}</div>
            </div>
            <Footer />
        </div>
    );
}

export default ProfileLayout;
