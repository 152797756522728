const images = {
    logo: require('~/assets/images/MeU.png'),
    noImage: require('~/assets/images/no-image.png'),
    newsImage: require('~/assets/images/news-image.png'),
    introImage: require('~/assets/images/Intro.png'),
    contactUs: require('~/assets/images/contact-us.png'),
    examAvatar: require('~/assets/images/avatar-exam.jpg'),
    zalo: require('~/assets/images/AvatarZalo.png'),
    facebook: require('~/assets/images/avatarFb.png'),
    google: require('~/assets/images/avatarGG.png'),
    test_banner: require('~/assets/images/test-banner.png'),
    avatar_flashCard: require('~/assets/images/image.png'),
};

export default images;
