import React, { useEffect } from 'react';
import { Table, Space, Switch, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EditIcon, TrashIcon } from '~/components/Icons';
import { setOptionAdminList } from '~/redux/adminSlice';

import Avatar from '~/components/Avatar';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';

import { listFeedbackAdmin, enableFeedback } from '~/api/feedbackApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

const TableFeedback = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const controller = new AbortController();

    const optionAdminList = useSelector((state) => state.option.option);

    const columns = [
        {
            title: 'STT',
            dataIndex: 'number',
            align: 'right',
        },
        {
            title: 'Ảnh',
            dataIndex: 'avatar',
            align: 'center',
            render: (_, record) => {
                return (
                    <div className="flex items-center justify-center">
                        <Avatar className="h-10 w-10" src={record.avatar || ''} alt="user" />
                    </div>
                );
            },
        },
        {
            title: 'Tên người đăng',
            dataIndex: 'name',
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'create_at',
            align: 'right',
        },
        {
            title: 'Hiển thị',
            dataIndex: 'enable',
            align: 'center',
            render: (display, record) => (
                <Switch
                    className="bg-hotlink-gray-bfbfbf"
                    defaultChecked={display}
                    onChange={(checked) => handleSwitchChange(checked, record)}
                ></Switch>
            ),
        },
        {
            title: 'Thao tác',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Popover content={<div>Chỉnh sửa</div>}>
                        <button
                            className="flex flex-row gap-3 justify-center items-center"
                            onClick={() => {
                                props.setChangeSource(record);
                                props.setOpenEdit(true);
                            }}
                        >
                            <EditIcon className="cursor-pointer" />
                        </button>
                    </Popover>
                    <Popover content={<div>Xóa</div>}>
                        <button
                            className="flex flex-row gap-3 justify-center items-center"
                            onClick={() => {
                                props.setChangeSource(record);
                                props.setOpenDelete(true);
                            }}
                        >
                            <TrashIcon className="cursor-pointer" />
                        </button>
                    </Popover>
                </Space>
            ),
        },
    ];

    const handleSwitchChange = async (checked, record) => {
        try {
            dispatch(startLoading());
            const response = await enableFeedback(
                axiosPrivate,
                record.key,
                { enable: checked },
                {
                    signal: controller.signal,
                },
            );
            if (response?.status === 'success') {
                dispatch(setStatusMessage('Success'));
                dispatch(setErrMessage(response.message));
            } else {
                dispatch(setStatusMessage('Fail'));
                dispatch(setErrMessage(response.message));
            }
        } catch (error) {
            dispatch(setStatusMessage('Fail'));
            dispatch(setErrMessage(error));
        } finally {
            dispatch(stopLoading());
        }
    };

    const getListFeedback = async (page, pageSize) => {
        try {
            dispatch(startLoading());

            const setNewOptionAdmin = { ...optionAdminList, size: props.state.sizePage };
            const response = await listFeedbackAdmin(axiosPrivate, setNewOptionAdmin, {
                signal: controller.signal,
            });

            if (response?.status === 'success') {
                props.setState((prevState) => ({ ...prevState, totalRecords: response.responseData.totalRecords }));
                const newData = response.responseData.listFeedback.map((item, i) => ({
                    key: item.feedbackId,
                    number: (page - 1) * pageSize + i + 1,
                    name: item.name,
                    description: item.description,
                    content: item.content,
                    avatar: item.avatar,
                    enable: item.enable,
                    create_at: item.createAt,
                }));

                props.setState((prevState) => ({ ...prevState, dataSource: newData }));
            }
        } catch (error) {
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (optionAdminList.page === undefined) {
            props.setState((prevState) => ({ ...prevState, currentPage: 1 }));
            getListFeedback(1, props.state.sizePage);
        } else {
            getListFeedback(optionAdminList.page + 1, props.state.sizePage);
        }

        return () => {};
    }, [optionAdminList, props.updateTable]);

    const handlePageChange = (page, pageSize) => {
        props.setState((prevState) => ({ ...prevState, sizePage: pageSize }));
        props.setState((prevState) => ({ ...prevState, currentPage: page }));
        dispatch(setOptionAdminList({ ...optionAdminList, page: page - 1 }));
    };

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [props.state.dataSource]);

    return (
        <div className="w-full">
            <Table
                className="format-table"
                columns={columns}
                dataSource={props.state.dataSource}
                pagination={{
                    defaultPageSize: 10,
                    current: props.state.currentPage,
                    showSizeChanger: true,
                    position: ['bottomRight'],
                    pageSizeOptions: ['5', '10', '20'],
                    total: props.state.totalRecords,
                    locale: { items_per_page: '' },
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
};
export default TableFeedback;
