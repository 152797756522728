import React, { useState } from 'react';

const FlashCardReviewItem = (props) => {
    const [isChoice, setIsChoice] = useState(false);
    return (
        <div
            className={`relative !h-[93%] cursor-pointer m-2 rounded-xl border-solid border-[1px] shadow-[0_4px_40px_0_rgba(0,0,0,0.15)] border-hotlink-purple-c6b9ff bg-white transition-transform duration-1000 transform-preserve ${
                isChoice ? 'rotate-y-180' : null
            }`}
        >
            <div
                className="cursor-pointer absolute left-0 top-0 right-0 bottom-0 h-full flex justify-center items-center backface-hidden"
                onClick={() => {
                    setIsChoice(!isChoice);
                }}
            >
                <span>{props.word.word}</span>
            </div>

            <div
                className="cursor-pointer absolute left-0 top-0 right-0 bottom-0 h-full flex justify-center items-center backface-hidden rotate-y-180 bg-hotlink-purple-f7f3ff text-hotlink-black-000000d9"
                onClick={() => {
                    setIsChoice(!isChoice);
                }}
            >
                <span>{props.word.define}</span>
            </div>
        </div>
    );
};

export default FlashCardReviewItem;
