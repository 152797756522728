import axios from '../axios';

export const listNewsAdmin = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get(`/news/listNewsAdmin`, { params: payload, ...config })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listNewsUser = async (payload, config) => {
    try {
        const response = await axios.get(`/news/listNewsUser`, { params: payload, ...config });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createNews = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .post(`/news/createNews`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const enableNews = (axiosPrivate, newsId, payload, config) => {
    return axiosPrivate
        .patch(
            `/news/${newsId}/enableNews`,
            {},
            {
                params: payload,
                ...config,
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const updateNews = (axiosPrivate, newsId, payload, config) => {
    return axiosPrivate
        .patch(`/news/${newsId}/updateNews`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deleteNews = (axiosPrivate, newsId, payload, config) => {
    return axiosPrivate
        .delete(`/news/${newsId}/deleteNews`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
