import { configureStore } from '@reduxjs/toolkit';
import topicReducer from './topicSlice';
import userReducer from './userSlice';
import listExamReducer from './listExamSlice';
import adminReducer from './adminSlice';
import flashCardReducer from './flashCardSlice';
import loadingReducer from './loadingSlice';
import errMessageReducer from './errMessageSlice';
import optionReducer from './optionSlice';

const store = configureStore({
    reducer: {
        topic: topicReducer,
        user: userReducer,
        listExam: listExamReducer,
        admin: adminReducer,
        flashCard: flashCardReducer,
        loading: loadingReducer,
        errMessage: errMessageReducer,
        option: optionReducer,
    },
});

export default store;
