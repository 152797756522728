import { useLocation, useNavigate } from 'react-router-dom';

import { PostProfileIcon, EditProfileIcon, ChangePassProfileIcon } from '../../Icons';

function Navigation() {
    const location = useLocation();
    const navigate = useNavigate();

    const currentUrl = `${location.pathname}`;
    const parts = currentUrl.split('/');

    const nonEmptyParts = parts.filter((part) => part !== '');

    const desiredPart = nonEmptyParts[nonEmptyParts.length - 1];

    return (
        <div className="lg:w-1/4 w-full flex flex-col gap-5 lg:mt-16 mt-6">
            <div className="w-full lg:h-[400px] bg-white p-4 flex flex-col font-BeVietNamRegular font-bold text-lg rounded-xl shadow-[4px_4px_30px_0_rgba(0,0,0,.15)] overflow-y-auto">
                <button
                    className={`py-4 rounded-xl ${desiredPart === 'profile' ? ` bg-hotlink-white-d7cceb ` : ` `}`}
                    onClick={() => navigate('/profile')}
                >
                    <div className="flex flex-row gap-4 px-4 items-center justify-start ">
                        <PostProfileIcon />
                        <span
                            className={`${
                                desiredPart === 'profile' ? `text-hotlink-blue-309 ` : `text-hotlink-black-262626 `
                            }`}
                        >
                            Kết quả luyện thi
                        </span>
                    </div>
                </button>
                <button
                    className={`py-4 rounded-xl ${
                        desiredPart === 'change-profile' ? ` bg-hotlink-white-d7cceb ` : ` `
                    }`}
                    onClick={() => navigate('/profile/change-profile')}
                >
                    <div className="flex flex-row gap-4 px-4 items-center justify-start">
                        <EditProfileIcon />
                        <span
                            className={`${
                                desiredPart === 'change-profile'
                                    ? `text-hotlink-blue-309 `
                                    : `text-hotlink-black-262626 `
                            }`}
                        >
                            Chỉnh sửa thông tin
                        </span>
                    </div>
                </button>

                <button
                    className={`py-4 rounded-xl ${desiredPart === 'change-pass' ? ` bg-hotlink-white-d7cceb ` : ` `}`}
                    onClick={() => navigate('/profile/change-pass')}
                >
                    <div className="flex flex-row gap-4 px-4 items-center justify-start">
                        <ChangePassProfileIcon />
                        <span
                            className={`${
                                desiredPart === 'change-pass' ? `text-hotlink-blue-309 ` : `text-hotlink-black-262626 `
                            }`}
                        >
                            Đổi mật khẩu
                        </span>
                    </div>
                </button>
            </div>
        </div>
    );
}
export default Navigation;
