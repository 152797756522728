// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-layout .ant-menu-submenu-arrow {
  display: none;
}

.admin-layout .ant-menu-item {
  height: auto;
  border-radius: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1;
  display: block !important;
}

.admin-layout .ant-menu-submenu .ant-menu-submenu-title {
  height: auto;
  border-radius: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1;
}

.admin-layout .ant-menu-sub {
  font-family: Be Vietnam Pro, sans-serif;
  font-weight: 400;
}

.admin-layout .ant-menu-item-selected {
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(51 0 153 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(215 204 235 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(51 0 153 / var(--tw-text-opacity));
}

.admin-layout .ant-menu-submenu-selected .ant-menu-submenu-title {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}`, "",{"version":3,"sources":["webpack://./src/layouts/AdminLayout/AdminLayout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAGI;EAAA,YAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA,cAAA;EACA;AADA;;AAKA;EAAA,YAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,uCAAA;EAAA;AAAA;;AAIA;EAAA,sBAAA;EAAA,sBAAA;EAAA,sDAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".admin-layout .ant-menu-submenu-arrow {\n    display: none;\n}\n\n.admin-layout .ant-menu-item {\n    @apply py-4 rounded-none leading-none h-auto;\n    display: block !important;\n}\n\n.admin-layout .ant-menu-submenu .ant-menu-submenu-title {\n    @apply py-4 rounded-none leading-none h-auto;\n}\n\n.admin-layout .ant-menu-sub {\n    @apply font-BeVietNamRegular font-normal;\n}\n\n.admin-layout .ant-menu-item-selected {\n    @apply bg-hotlink-white-d7cceb text-hotlink-blue-309 border-l-2 border-hotlink-blue-309;\n}\n\n.admin-layout .ant-menu-submenu-selected .ant-menu-submenu-title {\n    @apply text-hotlink-black-333333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
