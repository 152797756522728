// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-title::after {
    position: absolute;
    bottom: -2%;
    display: block;
    height: 2px;
    width: calc(100% - 40px);
    --tw-bg-opacity: 1;
    background-color: rgb(246 174 45 / var(--tw-bg-opacity));
    opacity: 0;
    --tw-content: "";
    content: var(--tw-content)
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Navbar/Navbar.scss"],"names":[],"mappings":"AACI;IAAA,kBAAA;IAAA,WAAA;IAAA,cAAA;IAAA,WAAA;IAAA,wBAAA;IAAA,kBAAA;IAAA,wDAAA;IAAA,UAAA;IAAA,gBAAA;IAAA;AAAA","sourcesContent":[".navbar-title::after {\n    @apply block absolute bottom-[-2%] w-[calc(100%_-_40px)] h-[2px] bg-hotlink-yellow-f6ae2d content-[''] opacity-0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
