// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-32px {
  font-size: 32px;
  font-family: var(--font-be-vietnam);
}

.box-shadow {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/SkillTest/SkillTest.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mCAAA;AACJ;;AAEA;EACI,4CAAA;AACJ","sourcesContent":[".text-32px {\n    font-size: 32px;\n    font-family: var(--font-be-vietnam);\n}\n\n.box-shadow {\n    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
