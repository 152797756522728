import News from '~/components/Home/News';
import Intro from '~/components/Home/Intro';
import Contact from '~/components/Home/Contact';
import Comment from '~/components/Home/Comment';

function Home() {
    return (
        <div>
            <Intro />
            <News />
            <Comment />
            <Contact page="home" />
        </div>
    );
}

export default Home;
