// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg {
  background-color: rgba(230, 230, 230, 0.6);
}

.active {
  background-color: #d0d0ff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.font-poppins {
  font-family: var(--font-be-vietnam);
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/ToeicExamPage/ToeicExam/ToeicExam.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;AACJ;;AACA;EACI,yBAAA;EACA,2CAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,mCAAA;AAGJ","sourcesContent":[".bg {\n    background-color: rgba(230, 230, 230, 0.6);\n}\n.active {\n    background-color: #d0d0ff;\n    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n}\n.font-poppins {\n    font-family: var(--font-be-vietnam);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
