import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Language = ({ isAdminPage }) => {
    const [currentLanguage, setCurrentLanguage] = useState('vn');
    const { i18n } = useTranslation('common');

    const handleLanguageChange = (language) => {
        setCurrentLanguage(language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            <span
                onClick={() => handleLanguageChange('en')}
                className={`px-[5px] cursor-pointer ${
                    isAdminPage
                        ? currentLanguage === 'en'
                            ? 'text-hotlink-yellow-f6ae2d'
                            : 'text-hotlink-white-ced3dc'
                        : currentLanguage === 'en'
                        ? 'lg:text-black text-hotlink-yellow-f6ae2d'
                        : 'text-hotlink-white-ced3dc'
                }`}
            >
                EN
            </span>
            <span className={!isAdminPage ? 'lg:text-black text-hotlink-yellow-f6ae2d' : 'text-hotlink-yellow-f6ae2d'}>
                |
            </span>
            <span
                onClick={() => handleLanguageChange('vn')}
                className={`px-[5px] cursor-pointer ${
                    isAdminPage
                        ? currentLanguage === 'vn'
                            ? 'text-hotlink-yellow-f6ae2d'
                            : 'text-hotlink-white-ced3dc'
                        : currentLanguage === 'vn'
                        ? 'lg:text-black text-hotlink-yellow-f6ae2d'
                        : 'text-hotlink-white-ced3dc'
                }`}
            >
                VN
            </span>
        </div>
    );
};

export default Language;
