export const getResultMockTestByPartAndMockTest = async (axiosPrivate, partId, mockTestId, config) => {
    try {
        const response = await axiosPrivate.get('/getResultMockTestByPartAndMockTest',
            {
                params: {
                    partId: partId,
                    mockTestId: mockTestId,
                },
                ...config,
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const getResultMock = (axiosPrivate, mockTestId, config) => {
    return axiosPrivate
        .get(`/mockTest/getMockTestById?id=${mockTestId}`, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
