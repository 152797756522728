import axios from '../axios';

export const inforTopic = (axiosPrivate, topicId, config) => {
    return axiosPrivate
        .get('/topic/' + topicId + '/inforTopic', null, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

//Admin
export const enableTopic = (topicId, config, axiosPrivate) => {
    return axiosPrivate
        .patch('/topic/' + topicId + '/enableTopic', null, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const deleteTopic = (axiosPrivate, topicId) => {
    return axiosPrivate
        .delete('/topic/' + topicId + '/delete')
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listTopic = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/topic/listTopic', { params: payload, ...config })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listTopicUser = async (payload, config) => {
    try {
        const response = await axios.get('/topic/listTopic', { params: payload, ...config });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createTopic = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .post('/topic/create', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const updateTopic = (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .put(`/topic/${topicId}/updateTopic`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addQuestionToTopic = (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addQuestion`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addListQuestionToTopic = (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addListQuestion`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listComment = (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .get('/topic/' + topicId + '/listComment', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listPartToTopic = async (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .get('/topic/' + topicId + '/listPart', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listQuestionToPart = async (axiosPrivate, topicId, payload, config) => {
    return axiosPrivate
        .get('/topic/' + topicId + '/listQuestionToPart', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
