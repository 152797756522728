import './AdminLayout.scss';
import HeaderAdmin from '~/layouts/components/HeaderAdmin';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Layout, Menu } from 'antd';
import { StatisticIcon, HeaderIcon, ManagerIcon, BankQuestionIcon } from '~/components/Icons';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const { Content, Sider } = Layout;
function getItem(label, key, icon, link, children, type) {
    return {
        key: uuidv4(),
        icon,
        link,
        children,
        label,
        type,
    };
}

function AdminLayout({ children }) {
    const location = useLocation();
    const navigate = useNavigate();

    const { pathname } = location;

    const listDashboard = [
        getItem('Thống kê', uuidv4(), <StatisticIcon />, '/admin/manager-statistic'),
        // getItem('Header', uuidv4(), <HeaderIcon />, '/admin/header'),
        // getItem('Footer', uuidv4(), <HeaderIcon />, '/admin/footer'),
        // getItem('Quản lý tin tức', uuidv4(), <ManagerIcon />, '/admin/manager-news'),
        getItem('Quản lý khóa học', uuidv4(), <ManagerIcon />, '/admin/manager', [
            // getItem('Khóa học', uuidv4(), null, '/admin/manager/manager-course'),
            getItem('Đề Thi', uuidv4(), null, '/admin/manager/manager-exam'),
            // getItem('Ngân hàng câu hỏi', uuidv4(), null, '/admin/manager/manager-exam-repository'),
            // getItem('Phân quyền quản lý', uuidv4(), null, '/admin/manager/manager-role'),
            // getItem('Cấu hình module', uuidv4(), null, '/admin/manager/manager-module'),
        ]),
        // getItem('Ngân hàng câu hỏi', uuidv4(), <BankQuestionIcon />, '/admin/manager-exam-repository-2'),
        getItem('Quản lý thành viên', uuidv4(), <ManagerIcon />, '/admin/manager-user'),
        getItem('Quản lý tin tức', uuidv4(), <ManagerIcon />, '/admin/manager-news'),
        getItem('Quản lý phản hồi', uuidv4(), <ManagerIcon />, '/admin/manager-feedback'),
        getItem('Kết quả thi', uuidv4(), <ManagerIcon />, '/admin/manager-mocktest')
    ];

    const findItemDashboard = () => {
        const selectedParentItem = listDashboard.find((menuItem) =>
            menuItem.children
                ? menuItem.children.find((itemChild) => pathname.includes(itemChild.link))
                : pathname === menuItem.link,
        );

        if (selectedParentItem.children) {
            return selectedParentItem.children.find((menuItem) => pathname.includes(menuItem.link)).key;
        } else return selectedParentItem.key;
    };

    //Insert BreadCrumb

    const findBreadCrumbItems = () => {
        const listBread = [];
        const selectedParentItem = listDashboard.find((menuItem) =>
            menuItem.children
                ? menuItem.children.find((itemChild) => pathname.includes(itemChild.link))
                : pathname === menuItem.link,
        );
        listBread.push(selectedParentItem);
        if (selectedParentItem.children) {
            listBread.push(selectedParentItem.children.find((menuItem) => pathname.includes(menuItem.link)));
        }
        return listBread;
    };

    const [breadcrumbList, setBreadcrumbList] = useState(findBreadCrumbItems());

    useEffect(() => {
        setBreadcrumbList(findBreadCrumbItems());
    }, [location]);

    const handleMenuClick = (item) => {
        const selectedParentItem = listDashboard.find((menuItem) =>
            menuItem.children
                ? menuItem.children.find((itemChild) => itemChild.key === item.key)
                : menuItem.key === item.key,
        );

        if (selectedParentItem.children) {
            navigate(selectedParentItem.children.find((menuItem) => menuItem.key === item.key).link);
        } else navigate(selectedParentItem.link);
    };

    return (
        <div className="overflow-hidden">
            <HeaderAdmin />
            <Layout>
                <Sider width={256} style={{}}>
                    <Menu
                        mode="inline"
                        defaultOpenKeys={[
                            listDashboard.find((menuItem) =>
                                menuItem.children
                                    ? menuItem.children.find((itemChild) => pathname.includes(itemChild.link))
                                    : pathname === menuItem.link,
                            ).key,
                        ]}
                        selectedKeys={[findItemDashboard()]}
                        className="h-full admin-layout font-poppinBold font-semibold text-sm text-hotlink-black-333333"
                        items={listDashboard}
                        onClick={handleMenuClick}
                    />
                </Sider>
                <Layout className="relative flex flex-col justify-start items-start gap-4 py-4 pr-20 pl-6 font-poppinsRegular bg-hotlink-white-f8fffc">
                    <div className="flex flex-col gap-2 text-black">
                        <Breadcrumb
                            items={breadcrumbList.map((item, index) => ({
                                key: uuidv4(),
                                title: item.label,
                                className: index === breadcrumbList.length - 1 ? '!text-black' : '',
                            }))}
                        ></Breadcrumb>
                        <span className="text-[28px]">{breadcrumbList[0].label}</span>
                        <span className="text-xs">Tạo, chỉnh sửa, quản lý đề thi</span>
                    </div>

                    <Content className="w-full">{children}</Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default AdminLayout;
