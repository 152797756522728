// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.communications {

    display: flex;

    flex-grow: 0;

    flex-direction: column;

    align-items: flex-start;

    justify-content: flex-start;

    gap: 1.5rem;

    font-family: Be Vietnam Pro, sans-serif;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.description {

    display: flex;

    flex-grow: 0;

    flex-direction: column;

    align-items: flex-start;

    justify-content: flex-start;

    gap: 1.5rem;

    font-family: Be Vietnam Pro, sans-serif;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));

    max-width: 20rem
}

.customer-care {

    display: flex;

    flex-grow: 0;

    flex-direction: column;

    align-items: flex-start;

    justify-content: flex-start;

    gap: 1.5rem;

    font-family: Be Vietnam Pro, sans-serif;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));

    max-width: 20rem
}

.payments {

    display: flex;

    flex-grow: 0;

    flex-direction: column;

    align-items: flex-start;

    justify-content: flex-start;

    gap: 1.5rem;

    font-family: Be Vietnam Pro, sans-serif;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/layouts/components/Footer/Footer.scss"],"names":[],"mappings":"AACI;;IAAA,aAAA;;IAAA,YAAA;;IAAA,sBAAA;;IAAA,uBAAA;;IAAA,2BAAA;;IAAA,WAAA;;IAAA,uCAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAAA;;IAAA,aAAA;;IAAA,YAAA;;IAAA,sBAAA;;IAAA,uBAAA;;IAAA,2BAAA;;IAAA,WAAA;;IAAA,uCAAA;;IAAA,oBAAA;;IAAA,gDAAA;;IASA;AATA;;AAAA;;IAAA,aAAA;;IAAA,YAAA;;IAAA,sBAAA;;IAAA,uBAAA;;IAAA,2BAAA;;IAAA,WAAA;;IAAA,uCAAA;;IAAA,oBAAA;;IAAA,gDAAA;;IAcA;AAdA;;AAAA;;IAAA,aAAA;;IAAA,YAAA;;IAAA,sBAAA;;IAAA,uBAAA;;IAAA,2BAAA;;IAAA,WAAA;;IAAA,uCAAA;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":["@mixin column_mixin {\n    @apply grow-0 flex flex-col justify-start items-start gap-6 text-white font-BeVietNamBold;\n}\n\n.communications {\n    @include column_mixin();\n}\n\n.description {\n    @include column_mixin();\n    @apply max-w-xs;\n}\n\n.customer-care {\n    @include column_mixin();\n    @apply max-w-xs;\n}\n\n.payments {\n    @include column_mixin();\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
