import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'
import common_vn from './translations/vn/common.json';
import common_en from './translations/en/common.json';


i18n.use(initReactI18next).init({
    fallbackLng: 'vn',
    interpolation: {
      escapeValue: false,
    },
    resources: {
        vn: {
            common: common_vn,
        },
        en: {
            common: common_en,
        },
    },
});

export default i18n;