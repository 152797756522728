import Result from '../../Result/Result';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from '~/api/axios';
const CONFIRM_REGISTER_URL = '/register/confirm';

function ConfirmRegister() {
    const location = useLocation();

    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState();

    const confirmToken = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        const getSuccess = async () => {
            try {
                const response = await axios.get(CONFIRM_REGISTER_URL + '?token=' + confirmToken);
                if (response?.data?.violations === 401) {
                    setErrMsg('Unauthorized');
                    setStatusMsg('Xác nhận Đăng ký thất bại');
                } else if (response?.data?.status === 'fail') {
                    setErrMsg('Xác nhận Đăng ký thất bại');
                    setStatusMsg('Xác nhận Đăng ký thất bại');
                } else {
                    setErrMsg('Đăng ký thành công');
                    setStatusMsg('Đăng ký thành công')
                }
            } catch (err) {
                setErrMsg('Xác nhận Đăng ký thất bại');
                setStatusMsg('Xác nhận Đăng ký thất bại');
            }
        };

        getSuccess();
    }, [confirmToken]);

    return (
        <div className="w-full h-screen bg-white flex">
            {statusMsg && errMsg ? (
                errMsg === 'Đăng ký thành công' ? (
                    <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center">
                        <Result status="success" page="registerConfirm" />
                    </div>
                ) : (
                    <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center">
                        <Result status="error" page="registerConfirm" message={errMsg} />
                    </div>
                )
            ) : null}
        </div>
    );
}

export default ConfirmRegister;
