import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { clearOption } from '~/redux/optionSlice';
import Loading from '~/components/Loading';

import TableMockTest from '../../../components/Admin/ManageMockTest/TableMockTest';

function ManageMockTestPage() {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [api, contextHolder] = notification.useNotification();

    const [state, setState] = useState({
        totalRecords: 0,
        currentPage: 1,
        sizePage: 10,
        dataSource: [],
    });

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };
    useEffect(() => {
        dispatch(clearOption());
    }, []);

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    return (
        <div className="flex flex-col justify-start items-start gap-4 min-h-[540px]">
            {contextHolder}
            <TableMockTest state={state} setState={setState} />
            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default ManageMockTestPage;
