import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { animateScroll as scroll } from 'react-scroll';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { listPost } from '~/api/postApi';

import ShowPostItem from './ShowPostItem';

function ShowPost(props) {

    const isMounted = useRef(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [infiniteScrollKey, setInfiniteScrollKey] = useState(0);
    const [noneData, setNoneData] = useState(false);
    const controller = new AbortController();

    const optionListPost = useSelector((state) => state.option.option);

    const getListPost = async (page) => {
        try {
            const setNewOptionPost = { ...optionListPost, page: page };

            const response = await listPost(setNewOptionPost, {
                signal: controller.signal,
            });

            if (response.status === 'success') {
                if (response.responseData.length > 0) {
                    if (page === 0) {
                        props.setListPostUser(response.responseData);
                        scroll.scrollToTop();
                    } else {
                        props.setListPostUser((prevList) => [...prevList, ...response.responseData]);
                    }
                    setNoneData(false);
                    setHasMoreData(true);

                    setCurrentPage(page + 1);
                } else {
                    if (page === 0) {
                        setNoneData(true);
                    }
                    setHasMoreData(false);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    };

    useEffect(() => {
        if (isMounted.current) {
            getListPost(currentPage);
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (Object.keys(optionListPost).length > 0) {
            scroll.scrollToTop();
            getListPost(0);
            setHasMoreData(true);
            setCurrentPage(0);
            setInfiniteScrollKey((prevKey) => prevKey + 1);
        }
    }, []);

    return (
        <div className={`flex flex-col gap-3  min-h-[550px]`}>
            {props.listPostUser ? (
                <InfiniteScroll
                    key={infiniteScrollKey}
                    dataLength={props.listPostUser.length}
                    next={() => {
                        getListPost(currentPage);
                    }}
                    hasMore={hasMoreData}
                    loader={
                        <Skeleton
                            className="h-screen"
                            avatar
                            paragraph={{
                                rows: 4,
                            }}
                        />
                    }
                >
                    {props.listPostUser ? (
                        !noneData ? (
                            props.listPostUser.map((post) => (
                                <ShowPostItem
                                    key={post.postId}
                                    post={post}
                                    listPost={props.listPostUser}
                                    setListPost={props.setListPostUser}
                                />
                            ))
                        ) : (
                            <div className="flex justify-center items-center font-BeVietNamRegular text-hotlink-gray-7B7E86">
                                Không có bài viết
                            </div>
                        )
                    ) : null}
                </InfiniteScroll>
            ) : null}
        </div>
    );
}
export default ShowPost;
