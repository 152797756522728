import axios from '../axios';

export const getAllUser = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/admin/getAllUser', { params: payload, ...config })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const enableUser = (axiosPrivate, userId, payload, config) => {
    return axiosPrivate
        .patch(
            `/admin/${userId}/enableUser`,
            {},
            {
                params: payload,
                ...config,
            },
        )
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const deleteUser = (axiosPrivate, userId, payload, config) => {
    return axiosPrivate
        .delete(`/admin/${userId}/deleteUser`)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const countMockTestOfTopic = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/admin/countMockTestOfTopic', { params: payload, ...config })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
