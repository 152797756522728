import Footer from '~/layouts/components/Footer';

function TestLayout({ children }) {
    return (
        <div className="overflow-hidden">
            <div className="lg:mt-28 mt-24">
                <div className="content">{children}</div>
            </div>
            <Footer />
        </div>
    );
}

export default TestLayout;
