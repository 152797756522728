// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-new {
  border: solid 1px #4d27b3;
}

.news-slider .slick-slide > div {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.news-slider .slick-list {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.news-slider-page .slick-track {
  display: flex !important;
}
.news-slider-page .slick-track .slick-slide {
  display: flex !important;
  height: auto !important;
}
.news-slider-page .slick-track .slick-slide > div {
  display: flex !important;
}

.news-slider-page .slick-slide > div {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.news-slider-page .slick-list {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/News/News.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAGI;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,qBAAA;EAAA;AAAA;;AAIA;EAAA;AAAA;AAGI;EAAA,wBAAA;EAAA;AAAA;AAGI;EAAA;AAAA;;AAKR;EAAA,mBAAA;EAAA;AAAA;;AAGA;EAAA,qBAAA;EAAA;AAAA","sourcesContent":[".border-new {\n    border: solid 1px #4d27b3;\n}\n\n.news-slider .slick-slide > div {\n    @apply mx-3;\n}\n\n.news-slider .slick-list {\n    @apply -mx-3;\n}\n\n.news-slider-page .slick-track {\n    @apply flex #{!important};\n\n    .slick-slide {\n        @apply flex h-auto #{!important};\n\n        > div {\n            @apply flex #{!important};\n        }\n    }\n}\n.news-slider-page .slick-slide > div {\n    @apply mx-6;\n}\n.news-slider-page .slick-list {\n    @apply -mx-3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
