import Banner from "../../components/TestPage/Banner/Banner";
import Fulltest from "../../components/TestPage/Fulltest/Fulltest";
import ToeicTest from '../../components/TestPage/ToeicTest/ToeicTest';

function ToeicFullTest() {
    return (
        <div>
            <Banner />
            <ToeicTest />
            <Fulltest backgroundColor='#ffeef0' />
        </div>
    )
}

export default ToeicFullTest