import axios from '../axios';

export const listPost = async (payload, config) => {
    try {
        const response = await axios.get('/post/listPost', {
            params: payload,
            ...config,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createPost = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .post('/post/createPost', payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getAllCommentToPost = async (postId, payload, config) => {
    try {
        const response = await axios.get(`/post/${postId}/getAllCommentToPost`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updatePost = (axiosPrivate, postId, payload, config) => {
    return axiosPrivate
        .patch(`/post/${postId}/updatePost`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deletePost = (axiosPrivate, postId, payload, config) => {
    return axiosPrivate
        .delete(`/post/${postId}/deletePost`, payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
