import axios from '../axios';

export const listFeedbackAdmin = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get(`/feedback/listFeedbackAdmin`, { params: payload, ...config })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listFeedbackUser = async (payload, config) => {
    try {
        const response = await axios.get(`/feedback/listFeedbackUser`, { params: payload, ...config });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createFeedback = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .post(`/feedback/createFeedback`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const enableFeedback = (axiosPrivate, feedbackId, payload, config) => {
    return axiosPrivate
        .patch(
            `/feedback/${feedbackId}/enableFeedback`,
            {},
            {
                params: payload,
                ...config,
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const updateFeedback = (axiosPrivate, feedbackId, payload, config) => {
    return axiosPrivate
        .patch(`/feedback/${feedbackId}/updateFeedback`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deleteFeedback = (axiosPrivate, feedbackId, payload, config) => {
    return axiosPrivate
        .delete(`/feedback/${feedbackId}/deleteFeedback`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
