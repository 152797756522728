import { Table, Switch, Space, Tag, Popover } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllUser, enableUser } from '~/api/adminApi';
import { setOptionAdminList } from '~/redux/adminSlice';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { TrashIcon } from '../../../Icons';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import Avatar from '../../../Avatar';

function TableAccountUser(props) {
    const controller = new AbortController();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();

    const optionAdminList = useSelector((state) => state.admin.optionAdminList);

    const columns = [
        {
            title: 'STT',
            dataIndex: 'number',
            align: 'right',
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            align: 'center',
            render: (_, record) => {
                return <Avatar className="h-7 w-7" src={record.avatar || ''} alt="user" />;
            },
        },
        {
            title: 'Email tài khoản',
            dataIndex: 'email',
        },
        {
            title: 'Tên người dùng',
            dataIndex: 'userName',
        },
        {
            title: 'Hoạt động',
            dataIndex: 'enable',
            align: 'center',
            render: (display, record) => {
                return (
                    <Switch
                        className="bg-hotlink-gray-bfbfbf"
                        defaultChecked={display}
                        onChange={(checked) => handleSwitchChange(checked, record)}
                    />
                );
            },
        },

        {
            title: 'Thao tác',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Popover content={<div>Xóa tài khoản</div>}>
                            <button
                                className="flex flex-row gap-3 justify-center items-center"
                                onClick={() => {
                                    props.setChangeSource(record);
                                    props.setIsOpenDelete(true);
                                }}
                            >
                                <TrashIcon className="cursor-pointer" />
                            </button>
                        </Popover>
                    </Space>
                );
            },
        },
    ];

    const expandedRowRender = (record) => {
        const columnChilds = [
            {
                title: 'Số điện thoại',
                dataIndex: 'phone',
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'create_at',
            },
        ];

        return <Table className="format-table-child" columns={columnChilds} dataSource={[record]} pagination={false} />;
    };

    const handleSwitchChange = async (checked, record) => {
        try {
            dispatch(startLoading());
            const response = await enableUser(
                axiosPrivate,
                record.key,
                { enable: checked },
                {
                    signal: controller.signal,
                },
            );
            if (response?.status === 'success') {
                dispatch(setStatusMessage('Success'));
                dispatch(setErrMessage(response.message));
            } else {
                dispatch(setStatusMessage('Fail'));
                dispatch(setErrMessage(response.message));
            }
        } catch (error) {
            dispatch(setStatusMessage('Fail'));
            dispatch(setErrMessage(error));
        } finally {
            dispatch(stopLoading());
        }
    };

    const getListUser = async (page, pageSize) => {
        try {
            dispatch(startLoading());

            const setNewOptionAdmin = { ...optionAdminList, size: props.state.sizePage };
            const response = await getAllUser(axiosPrivate, setNewOptionAdmin, {
                signal: controller.signal,
            });

            if (response?.status === 'success') {
                props.setState((prevState) => ({ ...prevState, totalRecords: response.responseData.totalRecords }));
                const newData = response.responseData.listUser.map((item, i) => ({
                    key: item.userId,
                    number: (page - 1) * pageSize + i + 1,
                    email: item.email,
                    userName: item.userName,
                    avatar: item.avatar,
                    phone: item.phone,
                    address: item.address,
                    enable: item.enable,
                    create_at: item.createAt,
                }));

                props.setState((prevState) => ({ ...prevState, dataSource: newData }));
            }
        } catch (error) {
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (optionAdminList.page === undefined) {
            props.setState((prevState) => ({ ...prevState, currentPage: 1 }));
            getListUser(1, props.state.sizePage);
        } else {
            getListUser(optionAdminList.page + 1, props.state.sizePage);
        }

        return () => {};
    }, [optionAdminList]);

    const handlePageChange = (page, pageSize) => {
        props.setState((prevState) => ({ ...prevState, sizePage: pageSize }));
        props.setState((prevState) => ({ ...prevState, currentPage: page }));
        dispatch(setOptionAdminList({ ...optionAdminList, page: page - 1 }));
    };

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [props.state.dataSource]);

    return (
        <div className="w-full">
            <Table
                className="format-table"
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => expandedRowRender(record),
                }}
                dataSource={props.state.dataSource}
                pagination={{
                    defaultPageSize: 10,
                    current: props.state.currentPage,
                    showSizeChanger: true,
                    position: ['bottomRight'],
                    pageSizeOptions: ['5', '10', '20'],
                    total: props.state.totalRecords,
                    locale: { items_per_page: '' },
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
}

export default TableAccountUser;
