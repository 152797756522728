import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';

const useRefreshToken = () => {
    const navigate = useNavigate();

    const refresh = async () => {
        if (localStorage.getItem('refreshToken') !== null) {
            const response = await axios.post('/refreshToken', {
                requestRefresh: localStorage.getItem('refreshToken'),
            });
            if (response.data.responseData) {
                localStorage.setItem('accessToken', response.data.responseData.accessToken);
                return response.data.responseData.accessToken;
            } else {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    };
    return refresh;
};

export default useRefreshToken;
