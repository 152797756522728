import { InputNumber, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import AnswerQuestion from '../AnswerQuestion';

const QuestionChildPart = (props) => {
    const [contentQuestion, setContentQuestion] = useState({
        questionId: props.questionEdit?.questionId || '',
        questionContent: props.questionEdit ? props.questionEdit.questionContent : '',
        questionScore: props.questionEdit ? props.questionEdit.questionScore : 1,
        partId: props.partId,
        listAnswer: props.questionEdit?.listAnswer
            ? props.questionEdit.listAnswer.map((answer) => ({
                  idAnswer: answer.answerId,
                  contentAnswer: answer.answerContent,
                  correctAnswer: answer.answerId === props.questionEdit.answerCorrect,
              }))
            : [
                  { contentAnswer: 'A', correctAnswer: true },
                  { contentAnswer: 'B', correctAnswer: false },
                  { contentAnswer: 'C', correctAnswer: false },
                  { contentAnswer: 'D', correctAnswer: false },
              ],
        err: {},
    });

    useEffect(() => {
        props.setChildQuestion(contentQuestion);
    }, [contentQuestion]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-center justify-start gap-2 w-2/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Câu hỏi phụ :</span>
                    <Input
                        className="font-BeVietNamRegular text-sm w-full"
                        placeholder="Nội dung câu hỏi"
                        disabled={props.disabled}
                        defaultValue={contentQuestion.questionContent}
                        onChange={(e) => {
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                questionContent: e.target.value,
                            }));
                        }}
                    ></Input>
                </div>
                <div className="flex flex-row items-center justify-end gap-2 w-1/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Số điểm</span>
                    <InputNumber
                        className="w-1/2 font-BeVietNamRegular text-sm"
                        placeholder="Số điểm"
                        disabled={props.disabled}
                        defaultValue={contentQuestion.questionScore ?? 1}
                        onChange={(value) => {
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                questionScore: value,
                            }));
                        }}
                    />
                </div>
            </div>
            <AnswerQuestion
                disabled={props.disabled}
                answerEdit={props.questionEdit || null}
                setAnswerQuestion={(answerQuestion) => {
                    setContentQuestion((prevContentQuestion) => ({
                        ...prevContentQuestion,
                        listAnswer: answerQuestion,
                    }));
                }}
            />
        </div>
    );
};

export default QuestionChildPart;
