import Header from '~/layouts/components/Header';
import Navigation from '~/components/ForumLayout/Navigation';

function ForumLayout({ children }) {
    return (
        <div className="overflow-hidden bg-hotlink-purple-f7f3ff ">
            <Header />
            <div className="mt-[112px] lg:px-20 px-5 flex flex-row gap-10">
                <Navigation />
                <div className="content max-w-[1000px] lg:w-[80%] lg:ml-[32%] w-full">{children}</div>
            </div>
        </div>
    );
}

export default ForumLayout;
