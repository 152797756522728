import InformationUser from './InformationUser';

function Navigation() {
    return (
        <div className="hidden w-1/4 lg:flex flex-col gap-5 fixed mt-3 top-[180px]">
            <InformationUser />
        </div>
    );
}
export default Navigation;
