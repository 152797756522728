import { RightArrowIcon, LeftArrowIcon, ChevronRight } from '~/components/Icons';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import CompetitionsItem from './CompetitionsItem';

import { listNewsUser } from '~/api/newsApi';

function Competitions() {
    const slider = useRef(null);

    const controller = new AbortController();

    const [data, setData] = useState([]);

    const getListNews = async () => {
        try {
            const response = await listNewsUser(
                { size: '5' },
                {
                    signal: controller.signal,
                },
            );

            if (response?.status === 'success') {
                const newData = response.responseData.map((item, i) => ({
                    key: item.newsId,
                    picture: item.newsImage,
                    title: item.newsTitle,
                    description: item.newsContent,
                    day: item.updateAt.split(' ')[0],
                }));

                setData(newData);
            }
        } catch (error) {
        } finally {
        }
    };

    useEffect(() => {
        getListNews();

        return () => {
            controller.abort();
        };
    }, []);

    const listCompetitions = data.map((element, index) => {
        return (
            <CompetitionsItem
                key={index}
                picture={element.picture}
                title={element.title}
                day={element.day}
                description={element.description}
            />
        );
    });

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
        ],
    };

    return (
        <div className="w-full flex flex-col justify-center items-center lg:gap-8 gap-6 bg-hotlink-purple-f7f3ff lg:px-20 px-6 py-10 relative">
            <span className="lg:text-[40px] text-3xl font-poppinBold font-semibold text-hotlink-blue-309">
                Các cuộc thi
            </span>
            <div className="w-full">
                <Slider className="news-slider-page" ref={slider} {...settings}>
                    {listCompetitions}
                </Slider>
            </div>

            <button className="flex flex-row mt-[-32px] justify-center items-center gap-3 py-2 px-4 border-solid border border-hotlink-blue-4d27b3 rounded-xl bg-hotlink-blue-4d27b3">
                <span className="font-poppinsMedium text-white text-base leading-normal">Xem tất cả</span>
                <ChevronRight />
            </button>

            <div className="flex justify-between items-start w-full absolute lg:px-16 px-5">
                <div className="h-8 relative w-full">
                    <button
                        onClick={() => slider?.current?.slickPrev()}
                        className="w-8 h-full flex justify-center items-center cursor-pointer bg-hotlink-white-e6e6e6 rounded-1/2 absolute left-0"
                    >
                        <LeftArrowIcon />
                    </button>

                    <button
                        onClick={() => slider?.current?.slickNext()}
                        className="w-8 h-full flex justify-center items-center cursor-pointer bg-hotlink-white-e6e6e6 rounded-1/2 absolute right-0"
                    >
                        <RightArrowIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Competitions;
