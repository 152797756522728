import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    option: {},
};

const optionSlice = createSlice({
    name: 'listExam',
    initialState,
    reducers: {
        setOption: (state, action) => {
            state.option = action.payload;
        },
        clearOption: (state) => {
            state.option = {};
        },
    },
});

export const { setOption, clearOption } = optionSlice.actions;

export default optionSlice.reducer;
