import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import images from '~/assets/images';
import LoginImage from '~/assets/svg/Group64.svg';
import ShowPasswordImg from '~/assets/svg/ShowPassword.svg';
import Result from '../../Result/Result';

import { notification, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import axios from '~/api/axios';
import { validPassword, validEmail } from '~/utils/regex.js';
const REGISTER_URL = '/register';

function Register() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassWord, setShowPassWord] = useState(false);
    const [showPassWordAgain, setShowPassWordAgain] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const showPass = () => {
        setShowPassWord((prev) => !prev);
    };

    const showPassAgain = () => {
        setShowPassWordAgain((prev) => !prev);
    };

    const [user, setUser] = useState({ name: '', email: '', password: '', passwordAgain: '', err: {} });

    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [user]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
    }, [errMsg, statusMsg]);

    const validate = () => {
        if (!validEmail.test(user.email)) {
            setStatusMsg('fail');
            setUser((prevUser) => ({
                ...prevUser,
                err: {
                    ...prevUser.err,
                    email: `${t('register.email_format_wrong')}`,
                },
            }));
            return false;
        }
        if (!validPassword.test(user.password)) {
            setStatusMsg('fail');
            setUser((prevUser) => ({
                ...prevUser,
                err: {
                    ...prevUser.err,
                    password: `${t('register.password_format_wrong')}`,
                },
            }));
            return false;
        }
        if (user.password !== user.passwordAgain) {
            setStatusMsg('fail');
            setUser((prevUser) => ({
                ...prevUser,
                err: {
                    ...prevUser.err,
                    passwordAgain: `${t('register.password_again_wrong')}`,
                },
            }));
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                setIsLoading(true);
                const response = await axios.post(
                    REGISTER_URL,
                    JSON.stringify({
                        email: user.email,
                        password: user.password,
                        confirmPassword: user.passwordAgain,
                        name: user.name,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                    },
                );

                if (response?.data?.violations === 401) {
                    setErrMsg('Unauthorized');
                    setStatusMsg('Đăng ký thất bại');
                } else if (response?.data?.status === 'fail') {
                    setErrMsg('Email này đã tồn tại!');
                    setStatusMsg('Đăng ký thất bại');
                } else {
                    setErrMsg('Đăng ký thành công');
                    setStatusMsg('Thông báo');
                }
            } catch (err) {
                setErrMsg('Đăng ký thất bại');
                setStatusMsg('Thông báo');
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="w-full h-screen bg-white flex relative">
            {contextHolder}
            <div className="w-[50%] tablet:w-full mobile:w-full flex flex-col justify-between">
                <h1 className="flex justify-center items-center tablet:items-start tablet:mt-20 mobile:items-start mobile:mt-10 text-center flex-[1] text-[50px] leading-[44px] font-semibold not-italic tracking-normal text-[#309]">
                    {t('register.welcome')}
                </h1>
                <img src={LoginImage} alt="" className="shrink-0 object-contain" />
            </div>
            <div className="w-[50%] h-auto flex flex-col justify-center items-center tablet:w-full mobile:w-full tablet:absolute mobile:absolute tablet:top-0 mobile:top-0 tablet:bottom-0 mobile:bottom-0">
                <div className="w-[80%] flex flex-col items-center rounded-[40px] gap-6 bg-white shadow-2xl">
                    <h1 className="font-BeVietnamRegular pt-6 text-[30px] leading-[44px] font-semibold not-italic tracking-normal text-left text-[#309]">
                        {t('register.sign_up')}
                    </h1>
                    <div className="w-[80%] flex items-center">
                        <p className="mr-2 grow-0 font-BeVietnamRegular text-[14px] font-normal leading-[1.57] text-left text-[#333]">
                            {t('register.do_account')}
                        </p>
                        <div
                            className="grow-0 font-BeVietnamRegular text-[14px] font-normal leading-[1.57] text-left text-[#309] cursor-pointer"
                            onClick={() => navigate('/login')}
                        >
                            {t('login_now')}
                        </div>
                    </div>
                    <div className="w-[80%]">
                        <form className="flex flex-col font-BeVietnamRegular text-base" onSubmit={handleSubmit}>
                            <div className=" flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('register.name')}</label>
                                <input
                                    className=" border-gray items-center"
                                    placeholder={t('register.input_name')}
                                    onChange={(e) =>
                                        setUser((prevUser) => ({
                                            ...prevUser,
                                            name: e.target.value,
                                            err: {
                                                ...prevUser.err,
                                                name: e.target.value === '' ? `${t('register.name_required')}` : '',
                                            },
                                        }))
                                    }
                                    value={user.name}
                                    type="text"
                                />
                                {user.err.name && <div className="text-red-500 text-sm mt-1">{user.err.name}</div>}
                            </div>

                            <div className=" flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('register.email')}</label>
                                <input
                                    className=" border-gray items-center"
                                    placeholder={t('register.input_email')}
                                    onChange={(e) =>
                                        setUser((prevUser) => ({
                                            ...prevUser,
                                            email: e.target.value,
                                            err: {
                                                ...prevUser.err,
                                                email: e.target.value === '' ? `${t('register.email_required')}` : '',
                                            },
                                        }))
                                    }
                                    value={user.email}
                                    type="text"
                                />

                                {user.err.email && <div className="text-red-500 text-sm mt-1">{user.err.email}</div>}
                            </div>

                            <div className="flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('login.password')}</label>
                                <div className="border-gray flex justify-between items-center">
                                    <input
                                        type={showPassWord ? 'text' : 'password'}
                                        className="w-[95%]"
                                        placeholder={t('login.input_password')}
                                        onChange={(e) =>
                                            setUser((prevUser) => ({
                                                ...prevUser,
                                                password: e.target.value,
                                                err: {
                                                    ...prevUser.err,
                                                    password:
                                                        e.target.value === ''
                                                            ? `${t('register.password_required')}`
                                                            : '',
                                                },
                                            }))
                                        }
                                        value={user.password}
                                    />
                                    <img className="cursor-pointer" onClick={showPass} src={ShowPasswordImg} alt="" />
                                </div>
                                {user.err.password && (
                                    <div className="text-red-500 text-sm mt-1">{user.err.password}</div>
                                )}
                            </div>

                            <div className="flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('register.input_password_again')}</label>
                                <div className="border-gray flex justify-between items-center">
                                    <input
                                        type={showPassWordAgain ? 'text' : 'password'}
                                        className="w-[95%]"
                                        placeholder={t('register.input_password_again')}
                                        onChange={(e) =>
                                            setUser((prevUser) => ({
                                                ...prevUser,
                                                passwordAgain: e.target.value,
                                                err: {
                                                    ...prevUser.err,
                                                    passwordAgain:
                                                        e.target.value === ''
                                                            ? `${t('register.confirm_password_required')}`
                                                            : '',
                                                },
                                            }))
                                        }
                                        value={user.passwordAgain}
                                    />
                                    <img
                                        className="cursor-pointer"
                                        onClick={showPassAgain}
                                        src={ShowPasswordImg}
                                        alt=""
                                    />
                                </div>
                                {user.err.passwordAgain && (
                                    <div className="text-red-500 text-sm mt-1">{user.err.passwordAgain}</div>
                                )}
                            </div>

                            <button className="my-2 px-4 py-3 bg-[#309] text-base text-white mb-4">
                                {t('register_now')}
                            </button>
                        </form>
                    </div>
                    {/* <div className="w-[80%] flex flex-col justify-center items-center">
                        <p className="text-sm font-medium">{t('login.or')}</p>
                        <div className="w-[40%] mobile:w-[60%] pt-6 pb-8 flex justify-between items-center">
                            <img src={images.google} alt="social" />
                            <img src={images.facebook} alt="social" />
                            <img src={images.zalo} alt="social" />
                        </div>
                    </div> */}
                </div>
            </div>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading" size="large" />
                </div>
            )}
            {errMsg === 'Đăng ký thành công' ? (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Result status="success" page="register" />
                </div>
            ) : (
                ''
            )}{' '}
            :
        </div>
    );
}

export default Register;
