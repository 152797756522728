import React, { useState, useEffect } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosPrivate from '../../../../../../api/axios';
import { uploadImageExam } from '../../../../../../api/fileApi/fileApi'; 

const UploadExcel = ({ resetUpload }) => {
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileBlob, setFileBlob] = useState(null);

    const resetData = () => {
        setFileList([]);
        setFileBlob(null);
    };

    useEffect(() => {
        if (resetUpload) {
            resetData();
        }
    }, [resetUpload]);

    const beforeUpload = (file) => {
        const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (!isExcel) {
            message.error('You can only upload Excel files!');
            return false;
        }

        return true;
    };

    const handleChange = async ({ file, fileList }) => {
        setFileList(fileList);

        if (file.status !== 'uploading') {
            setUploading(true);

            if (beforeUpload(file)) {
                try {
                    const formData = new FormData();
                    formData.append('file', file.originFileObj);

                    const uploadResponse = await uploadImageExam(axiosPrivate, formData);

                    const blob = new Blob([file.originFileObj], { type: file.type });
                    setFileBlob(blob);

                    message.success('Upload successful! Click the button to open the file.');

                } catch (error) {
                    message.error('Upload failed');
                } finally {
                    setUploading(false);
                }
            }
        }
    };

    const openFile = () => {
        if (fileBlob) {
            const url = window.URL.createObjectURL(fileBlob);
            window.open(url);
        }
    };

    return (
        <div className="flex flex-col items-center justify-start gap-4 w-full">
            <Upload
                className="!w-full"
                listType="text"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                fileList={fileList}
            >
                <Button icon={<UploadOutlined />} loading={uploading}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
            </Upload>
            {fileBlob && (
                <Button onClick={openFile} type="default" className="mt-4">
                    Open Uploaded File
                </Button>
            )}
        </div>
    );
};

export default UploadExcel;
