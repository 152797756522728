import axios from '../axios';

export const logout = async (payload, config) => {
    try {
        const response = await axios.post('/logout', payload, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const validate = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/information', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listExamResultsUser = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/listExamResultsUser', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const changeProfile = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .patch(`/changeProfile`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const changePass = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .patch(`/changePass`, payload, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
