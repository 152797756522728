import { useState } from 'react';
import ToeicExam from '../../components/TestPage/ToeicExamPage/ToeicExam';
import ToeicExamHeader from '../../components/TestPage/ToeicExamPage/ToeicExamHeader';

function ToeicFullTestItem() {
    const [infor, setInfor] = useState();
    return (
        <div>
            <ToeicExamHeader setInfor={setInfor} />
            <ToeicExam infor={infor} />
        </div>
    );
}

export default ToeicFullTestItem;
