// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide-content {
  font-size: 22px;
  font-weight: 500px;
}
.guide-content li {
  margin-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/TestPage/Information/TestGuide/TestGuide.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AAAI;EACI,iBAAA;AAER","sourcesContent":[".guide-content {\n    font-size: 22px;\n    font-weight: 500px;\n    li {\n        margin-left: 40px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
