import axios from '../axios';

export const listPart = (axiosPrivate, mockTestId) => {
    return axiosPrivate
        .get('/mockTest/' + mockTestId + '/listPart')
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listQuestionToPart = (axiosPrivate, mockTestId, payload, config) => {
    return axiosPrivate
        .get('/mockTest/' + mockTestId + '/listQuestionToPart', payload, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const listMockTest = (axiosPrivate, payload, config) => {
    return axiosPrivate
        .get('/mockTest/listMockTestAdmin', { params: payload, ...config })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
