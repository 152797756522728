import LoginPage from '~/components/LoginPage';

function Login() {
    return (
        <div>
            <LoginPage />
        </div>
    );
}

export default Login;
