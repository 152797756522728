import axios from '../axios';

//Admin
export const listPart = async () => {
    try {
        const response = await axios
            .get('/part/listPart');
        return response.data;
    } catch (error) {
        throw error;
    }
};

