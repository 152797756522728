import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useState, useEffect } from 'react';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { setOptionAdminList } from '~/redux/adminSlice';
import Loading from '~/components/Loading';
import TopicStatistic from '../../../components/Admin/ManageStatistic/TopicStatistic';
// import DeleteLeaderPost from '../../../components/ManageLeader/ManagePostLeader/DeleteLeaderPost';

// import LeaderStatistic from '../../../components/ManageLeader/ManageStatisticLeader/LeaderStatistic';

function ManageStatisticPage() {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [changeSource, setChangeSource] = useState();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [state, setState] = useState({
        totalRecords: 0,
        currentPage: 1,
        sizePage: 10,
        dataSource: [],
    });

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        dispatch(setOptionAdminList({}));
    }, []);

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    return (
        <div className="flex flex-col justify-start items-start gap-4">
            {contextHolder}
            {/* <LeaderStatistic /> */}
            <TopicStatistic />
            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default ManageStatisticPage;
