import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import images from '~/assets/images';
import LoginImage from '~/assets/svg/Group64.svg';
import ShowPasswordImg from '~/assets/svg/ShowPassword.svg';
import Result from '../../Result/Result';

import { notification, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '~/api/axios';
import { validPassword } from '~/utils/regex.js';
const CHANGE_PASS_URL = '/changePassword';

function ChangePassword() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();
    const otpCode = location.state?.otp;
    const [isLoading, setIsLoading] = useState(false);
    const [showPassWord, setShowPassWord] = useState(false);
    const [showPassWordAgain, setShowPassWordAgain] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const showPass = () => {
        setShowPassWord((prev) => !prev);
    };

    const showPassAgain = () => {
        setShowPassWordAgain((prev) => !prev);
    };

    const [user, setUser] = useState({
        otp: '',
        password: '',
        passwordAgain: '',
        err: {},
    });

    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [user]);

    useEffect(() => {
        setUser((prevUser) => ({
            ...prevUser,
            otp: otpCode,
        }));
    }, [otpCode]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
    }, [errMsg, statusMsg]);

    const validate = () => {
        if (!validPassword.test(user.password)) {
            setStatusMsg('fail');
            setUser((prevUser) => ({
                ...prevUser,
                err: {
                    ...prevUser.err,
                    password: `${t('register.password_format_wrong')}`,
                },
            }));
            return false;
        }
        if (user.password !== user.passwordAgain) {
            setStatusMsg('fail');
            setUser((prevUser) => ({
                ...prevUser,
                err: {
                    ...prevUser.err,
                    passwordAgain: `${t('register.password_again_wrong')}`,
                },
            }));
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                setIsLoading(true);
                const response = await axios.post(
                    CHANGE_PASS_URL,
                    {
                        code: user.otp,
                        newPass: user.password,
                        confirmPass: user.passwordAgain,
                    },
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                    },
                );

                if (response?.data?.violations === 401) {
                    setErrMsg('Unauthorized');
                    setStatusMsg('Thay đổi mật khẩu thất bại');
                } else if (response?.data?.status === 'fail') {
                    setErrMsg('Thay đổi mật khẩu thất bại');
                    setStatusMsg('Thay đổi mật khẩu thất bại');
                } else {
                    setErrMsg('Thay đổi mật khẩu thành công');
                    setStatusMsg('Thông báo');
                }
            } catch (err) {
                setErrMsg('Thay đổi mật khẩu thất bại');
                setStatusMsg('Thông báo');
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="w-full h-screen bg-white flex relative">
            {contextHolder}
            <div className="w-[50%] tablet:w-full mobile:w-full flex flex-col justify-between">
                <h1 className="flex justify-center items-center tablet:items-start tablet:mt-20 mobile:items-start mobile:mt-10 text-center flex-[1] text-[50px] leading-[44px] font-semibold not-italic tracking-normal text-[#309]">
                    {t('register.welcome')}
                </h1>
                <img src={LoginImage} alt="" className="shrink-0 object-contain" />
            </div>
            <div className="w-[50%] h-auto flex flex-col justify-center items-center tablet:w-full mobile:w-full tablet:absolute mobile:absolute tablet:top-0 mobile:top-0 tablet:bottom-0 mobile:bottom-0">
                <div className="w-[80%] flex flex-col items-center rounded-[40px] gap-6 bg-white shadow-2xl">
                    <h1 className="font-poppinsRegular pt-6 text-[30px] leading-[44px] font-semibold not-italic tracking-normal text-left text-[#309]">
                        Thiết lập mật khẩu mới
                    </h1>
                    <div className="w-[80%]">
                        <form className="flex flex-col font-poppinsRegular text-base" onSubmit={handleSubmit}>
                            <div className="flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('login.password')}</label>
                                <div className="border-gray flex justify-between items-center">
                                    <input
                                        type={showPassWord ? 'text' : 'password'}
                                        className="w-[95%]"
                                        placeholder="Nhập mật khẩu của bạn"
                                        onChange={(e) =>
                                            setUser((prevUser) => ({
                                                ...prevUser,
                                                password: e.target.value,
                                                err: {
                                                    ...prevUser.err,
                                                    password:
                                                        e.target.value === ''
                                                            ? `${t('register.password_required')}`
                                                            : '',
                                                },
                                            }))
                                        }
                                        value={user.password}
                                    />
                                    <img className="cursor-pointer" onClick={showPass} src={ShowPasswordImg} alt="" />
                                </div>
                                {user.err.password && (
                                    <div className="text-red-500 text-sm mt-1">{user.err.password}</div>
                                )}
                            </div>

                            <div className="flex flex-col mb-3">
                                <label className="pb-2 text-sm">{t('register.input_password_again')}</label>
                                <div className="border-gray flex justify-between items-center">
                                    <input
                                        type={showPassWordAgain ? 'text' : 'password'}
                                        className="w-[95%]"
                                        placeholder="Nhập lại mật khẩu của bạn"
                                        onChange={(e) =>
                                            setUser((prevUser) => ({
                                                ...prevUser,
                                                passwordAgain: e.target.value,
                                                err: {
                                                    ...prevUser.err,
                                                    passwordAgain:
                                                        e.target.value === ''
                                                            ? `${t('register.password_required')}`
                                                            : '',
                                                },
                                            }))
                                        }
                                        value={user.passwordAgain}
                                    />
                                    <img
                                        className="cursor-pointer"
                                        onClick={showPassAgain}
                                        src={ShowPasswordImg}
                                        alt=""
                                    />
                                </div>
                                {user.err.passwordAgain && (
                                    <div className="text-red-500 text-sm mt-1">{user.err.passwordAgain}</div>
                                )}
                            </div>

                            <button className="my-2 px-4 py-3 bg-[#309] text-base text-white mb-4">
                                Hoàn thành
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading" size="large" />
                </div>
            )}
            {errMsg === 'Thay đổi mật khẩu thành công' ? (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Result status="success" page="changePassword" />
                </div>
            ) : null}
        </div>
    );
}

export default ChangePassword;
